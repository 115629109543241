import styled from "styled-components";
import Icon from '../../assets/icon.png'
const S = {
    carousel: styled.div`
    display: flex;
    overflow: hidden;
    height: 100%;
    `,
    carouselPrev: styled.div`
    position: absolute;
    cursor: pointer;
    z-index: 1;
    height: 100%;
    width: 100px;
    left: 0;
    @media (min-width: 768px) {
        &::before {
          position: relative;
          content: url(${Icon});
          transform: scale(.1) rotate(270deg);
          top: 50%;
          left: 20px;
          filter: brightness(10);
          display: inline-block;
          width: 10px;
          height: 10px;
        // transform: rotate(270deg);
        }
    }
    `,
    carouselNext: styled.div`
    position: absolute;
    cursor: pointer;
    z-index: 1;
    height: 100%;
    width: 100px;
    right: 0;
    @media (min-width: 768px) {
        &::before {
            position: relative;
            content: url(${Icon});
          transform: scale(.1) rotate(90deg);
            top: 46%;
            right: -70px;
            filter: brightness(10);
            display: inline-block;
            width: 10px;
          height: 10px;
           
        }
    }
    `,
    carouselItem: styled.div`
    position: relative;
    display: inline-block;
    flex: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
  
    transition: flex 1s ease;
    &::before{
        background:linear-gradient(0deg, #0003 0%, #0009 100%);
    }
    img {
        position: absolute;
        width: 100vw;
        // min-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); 
        filter: brightness(0.6);
        
      }
      
      &.current {
        flex: 1;
      }
    `,
    carouselHeading: styled.div`
    display: inline-block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px 140px;
    transition:0.5;
    float:left;
  
    h2, h3 {
      padding: 0 20px;
      color: #ECF0F1;
      font-size:2em;
      text-align:left;
      width:50%;
    }
    &.show{
        opacity:0;
        transition: opacity 0.5s;
    }
    `,
}

export default S