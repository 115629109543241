
import { Link } from 'react-router-dom';
import S from './style'
// import Image1 from '../../assets/sertification/tse.svg'
import Certification from '../../components/certification';
import { ReactComponent as Tse } from '../../assets/sertification/tsechoose.svg'
import { ReactComponent as Ce } from '../../assets/sertification/ce.svg'
import Iso from '../../assets/sertification/isopng.png'
import TranslateStatic from '../../locales/TranslateStatic';
import { LocaleContext } from '../../locale-context';
export function Iletisim() {
  return (
    <S.Wrapper>
      <S.Top>
        <S.Header>
          <TranslateStatic string="contactHeader" />
        </S.Header>
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <TranslateStatic string="contactHeader" />
        </S.Span>
      </S.Top>
      <S.Sertifika>
        <Certification />
      </S.Sertifika>
      <S.Section>
        <h2><TranslateStatic string="contactFormHeader" /></h2>
        <LocaleContext.Consumer>
          {(value) => {
            if (value === "en") {
              return <form action="">
                <input placeholder="Name" type="text" name="isim" />
                <input placeholder="Surname" type="text" name="soyisim" />
                <input placeholder="E-Mail" type="email" name="email" />
                <input placeholder="Phone" type="phone" name="phone" />
                <textarea placeholder="Description" name="description" />
                <input type="submit" value="Submit" />
              </form>
            } else if (value === "tr") {
              return <form action="">
                <input placeholder="İsim" type="text" name="isim" />
                <input placeholder="Soyisim" type="text" name="soyisim" />
                <input placeholder="E-Posta" type="email" name="email" />
                <input placeholder="Telefon" type="phone" name="phone" />
                <textarea placeholder="Açıklama" name="description" />
                <input type="submit" value="Gönder" />
              </form>
            }
          }}
        </LocaleContext.Consumer>
      
      </S.Section>
    </S.Wrapper>
  );
}
