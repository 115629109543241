import styled from "styled-components";

const S = {
    Wrapper: styled.section`
    padding: 2em;
    display:grid;
    overflow:hidden;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 25vh;
    grid-column-gap:2em;
    @media (max-width:648px){
        grid-template-columns: 1fr;
        grid-template-rows: none;
    }
    a{
        // margin:20px;
        // padding:20px;
        border-radius:10px;
            color:white;
            overflow:hidden;
            text-decoration: none;
            &:visited{
                color:white;
            }
        }
    .box{
        background:white;
        /* border:1px solid black; */
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        // justify-items: start;
        // align-content: center;
        // align-items: center;
        text-align:center;
        padding:20px;
        @media (max-width:648px){
           padding: 40px;
        }
        
        &:hover{
            background-color:#D41031;
            img{
                opacity: 0.4;
            }
        }
        img{
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height:100%;
            z-index:1;
            object-fit: cover;
        }
        h2,p{
            z-index:2;
            color:white;
            text-align:left;
            text-shadow: -2px 0px 7px rgba(0,0,0,0.91);
        }
        p{
            z-index:2;
            color:white;
            text-shadow: -2px 0px 7px rgba(0,0,0,0.91);
            font-size:12px;
            font-weight: 500;
            // padding:20px;
            text-align:left;
        }
        h2{
            font-size: 24px;
            text-align:left;
            // margin:0;
        }
    }
`,
    Baslik: styled.h1`
    text-align:left;
    margin: 40px;
    font-size:40px;
    color: #D41031;
    `
}

export default S