import React from "react";
import S from './style';
import Fire from '../../assets/fireExtin.png';
import FireV2 from '../../assets/fireExtinV2.png';
import { ScrollRotate } from 'react-scroll-rotate';
export default function () {
    return <S.Wrapper>
        <S.Kare>
            <S.Abss>

                <ScrollRotate method={"perc"} from={40} to={1}>


                    <S.Object src={FireV2} />
                </ScrollRotate>
            </S.Abss>

        </S.Kare>
        <S.Sag>
            {/* <h1>4 YIL GARANTİLİ</h1> */}
            <h2>
                CE 0036 Belgeli - ISO 9001:2000 Belgeli
            </h2>
            <h2>TS 862-3 EN 3-7 Belgeli</h2>
            <h3>Manometreli </h3>
            <h3>İthal Valfli </h3>
        </S.Sag>
    </S.Wrapper>
}