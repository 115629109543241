import S from "../pages/Product/style";

const data = [
    {
        category: {
            id: 5,
            name: 'YANGIN SÖNDÜRME CİHAZLARI',
            imgsrc: [process.env.PUBLIC_URL + '/images/products/kuru_kimyevi_tozlu_yangin_söndürme_cihazlari/image-225.png'],
            content: 'Kuru Kimyevi Tozlu, Karbondioksitli, Köpüklü, Sulu yangın söndürme cihazları.',
            link: '/category/kuru_kimyevi_tozlu_yangin_söndürme_cihazlari/5/subcategories',
            subcategories: [
                {
                    id: 0,
                    name: 'KURU KİMYEVİ TOZLU YANGIN SÖNDÜRME CİHAZLARI',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/images/products/kuru_kimyevi_tozlu_yangin_söndürme_cihazlari/ANA RESòM KKT .jpg',
                        process.env.PUBLIC_URL + '/images/products/kuru_kimyevi_tozlu_yangin_söndürme_cihazlari/1 kg kkt .jpg',
                        process.env.PUBLIC_URL + '/images/products/kuru_kimyevi_tozlu_yangin_söndürme_cihazlari/2 kg kkt .jpg',
                        process.env.PUBLIC_URL + '/images/products/kuru_kimyevi_tozlu_yangin_söndürme_cihazlari/6 KG KKT  .jpg',
                        process.env.PUBLIC_URL + '/images/products/kuru_kimyevi_tozlu_yangin_söndürme_cihazlari/12 KG KKT .jpg',
                        process.env.PUBLIC_URL + '/images/products/kuru_kimyevi_tozlu_yangin_söndürme_cihazlari/50 KG KKT .jpg',
                    ],
                    content: '',
                    link: '/product/5/0/kuru_kimyevi_tozlu_yangin_söndürme_cihazlari',
                    detail: <div className="detail">
                        <h2></h2>
                        <figure>
                            <figcaption>TEKNİK VERİLER </figcaption>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Ürün Kodu</th>
                                        <th>Kapasite</th>
                                        <th>Dolu Ağırlık</th>
                                        <th>Çalışma Basıncı</th>
                                        <th>Boşalma Süresi</th>
                                        <th>Püskürtme Uzaklığı</th>
                                        <th>Sıcaklık Aralığı</th>
                                        <th>Yükseklik</th>
                                        <th>Çap</th>
                                        <th><img src="https://dogantanyangin.com/images/products/a_tablo.jpg" /></th>
                                        <th><img src="https://dogantanyangin.com/images/products/b-tablo.jpg" /></th>
                                        <th><img src="https://dogantanyangin.com/images/products/c-tablo (1).jpg" /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>YSC KT 001</td>
                                        <td>1 kg</td>
                                        <td>2,3 kg</td>
                                        <td>17 BAR</td>
                                        <td>8 sn.</td>
                                        <td>4 m</td>
                                        <td>-20 / + 60 C °</td>
                                        <td>310 mm</td>
                                        <td>88 mm</td>
                                        <td>5 A</td>
                                        <td>21 B</td>
                                        <td>•</td>
                                    </tr>
                                    <tr>
                                        <td>YSC KT 002</td>
                                        <td>2 kg</td>
                                        <td>3,9 kg</td>
                                        <td>17 BAR</td>
                                        <td>10 sn.</td>
                                        <td>4 m</td>
                                        <td>-20 / + 60 C °</td>
                                        <td>380 mm</td>
                                        <td>110 mm</td>
                                        <td>8 A</td>
                                        <td>34 B</td>
                                        <td>•</td>
                                    </tr>
                                    <tr>
                                        <td>YSC KT 003</td>
                                        <td>3 kg</td>
                                        <td>4,9 kg</td>
                                        <td>17 BAR</td>
                                        <td>12 sn.</td>
                                        <td>6 m</td>
                                        <td>-20 / + 60 C °</td>
                                        <td>450 mm</td>
                                        <td>120 mm</td>
                                        <td>12 A</td>
                                        <td>60 B</td>
                                        <td>•</td>
                                    </tr>
                                    <tr>
                                        <td>YSC KT 004</td>
                                        <td>4 kg</td>
                                        <td>7,6 kg</td>
                                        <td>17 BAR</td>
                                        <td>12 sn.</td>
                                        <td>6 m</td>
                                        <td>-20 / + 60 C °</td>
                                        <td>492 mm</td>
                                        <td>150 mm</td>
                                        <td>13 A</td>
                                        <td>70 B</td>
                                        <td>•</td>
                                    </tr>
                                    <tr>
                                        <td>YSC KT 006</td>
                                        <td>6 kg</td>
                                        <td>10,1 kg</td>
                                        <td>17 BAR</td>
                                        <td>14 sn.</td>
                                        <td>6 m</td>
                                        <td>-20 / + 60 C °</td>
                                        <td>600 mm</td>
                                        <td>150 mm</td>
                                        <td>27 A</td>
                                        <td>113 B</td>
                                        <td>•</td>
                                    </tr>
                                    <tr>
                                        <td>YSC KT 009</td>
                                        <td>9 kg</td>
                                        <td>14 kg</td>
                                        <td>17 BAR</td>
                                        <td>15 sn.</td>
                                        <td>7 m</td>
                                        <td>-20 / + 60 C °</td>
                                        <td>550 mm</td>
                                        <td>190 mm</td>
                                        <td>34 A</td>
                                        <td>144 B</td>
                                        <td>•</td>
                                    </tr>
                                    <tr>
                                        <td>YSC KT 012</td>
                                        <td>12 kg</td>
                                        <td>18.1 kg</td>
                                        <td>17 BAR</td>
                                        <td>18 sn.</td>
                                        <td>8 m</td>
                                        <td>-20 / + 60 C °</td>
                                        <td>682 mm</td>
                                        <td>190 mm</td>
                                        <td>43 A</td>
                                        <td>183 B</td>
                                        <td>•</td>
                                    </tr>
                                    <tr>
                                        <td>YSC KT 050</td>
                                        <td>50 kg</td>
                                        <td>52 kg</td>
                                        <td>17 BAR</td>
                                        <td>22 sn.</td>
                                        <td>10 m</td>
                                        <td>-20 / + 60 C °</td>
                                        <td>930 mm</td>
                                        <td>230 mm</td>
                                        <td>55 A</td>
                                        <td>210 B</td>
                                        <td>•</td>
                                    </tr>
                                </tbody>
                            </table>
                        </figure>
                        <figure>
                            <h2>ÖZELLİKLER</h2>
                            <ul>
                                <li>Gövde, sıvama metodu ile imal edilmiş olup, elektrostatik toz boyalıdır.</li>
                                <li>Cihazların gövdesine 25 Bar Hidrostatik basınç testi uygulanmaktadır.</li>
                                <li>Cihazların kontrollü çalışmasını sağlayan tetik aksamı CE belgelidir.</li>
                                <li>Cihazlarımızda filtreli manometre kullanılmaktadır.</li>
                                <li>Cihazlarımızda aktif söndürme maddesi %40 ve %90 olan ABC kuru kimyevi toz kullanılmaktadır.</li>
                                <li>Cihazlarımızın özel askı tertibatı bulunmaktadır.</li>
                                <li>Cihazlarımız TSE 862-3 EN 3-7 ve ISO 9001-2008 belgelidir.</li>
                            </ul>
                            {/* <figcaption>A Sınıfı Yangınlar</figcaption>
                            <ul>
                                <li>Ahşap malzemeler, Otlar çalı-çırpı, Kağıtlar, Giysiler, Tüm Kumaşlar, Perde-ev tekstil, Büro-ev
                                    mobilyaları Kauçuk selüloz, Plastikler</li>
                            </ul>
                            <figcaption>B Sınıfı Yangınlar</figcaption>
                            <ul>
                                <li>Benzin, Benzol, Mazot, Vernik, Boyalar, Tiner, Aseton, Parafin, Asfalt, Tutkal, Yemek Yağları,
                                    Endüstriyel Yağlar </li>
                            </ul>
                            <figcaption>C Sınıfı Yangınlar</figcaption>
                            <ul>
                                <li>Doğalgaz, LPG, Metan, Hidrojen, Propan, Asetilen, Elektrik kabloları, Elektrik trofaları, Güç kaynakları
                                    Bilgi İşlem üniteleri, Elektronik ev eşyaları, Elektrikli ev eşyaları
                                </li>
                            </ul> */}
                        </figure>

                    </div>
                },
                {
                    id: 1,
                    name: 'KÖPÜKLÜ YANGIN SÖNDÜRME CİHAZLARI',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/images/products/kopuklu_yangin_sondurme_cihazlari/1img.jpg',
                        process.env.PUBLIC_URL + '/images/products/kopuklu_yangin_sondurme_cihazlari/6kgimg.jpg',
                        process.env.PUBLIC_URL + '/images/products/kopuklu_yangin_sondurme_cihazlari/12kgimg.jpg',
                        process.env.PUBLIC_URL + '/images/products/kopuklu_yangin_sondurme_cihazlari/50kgimg.jpg',
                    ],
                    content: '',
                    link: '/product/5/1/kopuklu_yangin_söndürme_cihazlari',
                    detail: <div className="detail">
                        <h2>Özellikler</h2>
                        <figure>
                            <figcaption>TEKNİK VERİLER </figcaption>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Ürün Kodu</th>
                                        <th>Kapasite</th>
                                        <th>Dolu Ağırlık</th>
                                        <th>Çalışma Basıncı</th>
                                        <th>Boşalma Süresi</th>
                                        <th>Söndürücü Madde</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>YSC 1-23-02-000055</td>
                                        <td>2 Kg</td>
                                        <td>3.3 Kg</td>
                                        <td>15 BAR</td>
                                        <td>9 sn</td>
                                        <td>%3 Sentetik</td>

                                    </tr>
                                    <tr>
                                        <td>YSC 1-23-02-000056</td>
                                        <td>4 Kg</td>
                                        <td>7.5 Kg</td>
                                        <td>15 BAR</td>
                                        <td>12 sn</td>
                                        <td>%3 Sentetik</td>

                                    </tr>
                                    <tr>
                                        <td>YSC 1-23-02-000058</td>
                                        <td>6 Kg</td>
                                        <td>9.1 Kg</td>
                                        <td>15 BAR</td>
                                        <td>14 sn</td>
                                        <td>%3 Sentetik</td>

                                    </tr>
                                    <tr>
                                        <td>YSC 1-23-02-000057</td>
                                        <td>9 Kg</td>
                                        <td>12.5 Kg</td>
                                        <td>15 BAR</td>
                                        <td>16 sn</td>
                                        <td>%3 Sentetik</td>

                                    </tr>
                                    <tr>
                                        <td>YSC 1-23-02-000059</td>
                                        <td>25 Kg</td>
                                        <td>46 Kg</td>
                                        <td>15 BAR</td>
                                        <td>35 sn</td>
                                        <td>%3 Sentetik</td>

                                    </tr>
                                    <tr>
                                        <td>YSC 4-23-02-000020</td>
                                        <td>50 Kg</td>
                                        <td>81 Kg</td>
                                        <td>15 BAR</td>
                                        <td>70 sn</td>
                                        <td>%3 Sentetik</td>

                                    </tr>
                                </tbody>
                            </table>
                        </figure>
                        <figure>
                            <p>
                                Köpüklü yangın söndürücüler, A (katı) ve B (sıvı) sınıfı yangın risklerinin bulunduğu ortamlar için uygundur. Köpükler, yanan maddenin yüzeyini kaplar, oksijenle bağlantıyı keser aynı zamanda yanma ısısını düşürerek soğutucu etki yapar. Ortama zarar vermeden kolayca temizlenebilir.
                            </p>
                            <p>
                                Köpük gözenekli maddelerin içine nüfuz eder ve köpükteki su bileşeninin buharlaşması vasıtasıyla yangını soğutur. Benzin gibi yanan sıvıların üzerine bir köpük halısı oluşturan köpük söndürücüler; ısının etkisi altında döşemelik kumaş ve halılardaki liflerin sıvılaşabildiği alanlar ve alev alabilir sıvılar için özellikle uygundur. Elektrikli ekipmanların sıvılar yüzünden ciddi bir şekilde hasar görmesine rağmen köpüklü söndürücülerin elektrikli cihazlarla kullanımı uygundur.
                            </p>
                        </figure>
                    </div>
                },
                {
                    id: 2,
                    name: 'KARBONDİOKSİT(CO2) GAZLI YANGIN SÖNDÜRME CİHAZLARI',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/images/products/karbondioksit(co2)_gazli_yangin_sondurme_cihazlari/5 KG CO2 .jpg',
                        // process.env.PUBLIC_URL + '/images/products/karbondioksit(co2)_gazli_yangin_sondurme_cihazlari/image-227.png',
                        // process.env.PUBLIC_URL + '/images/products/karbondioksit(co2)_gazli_yangin_sondurme_cihazlari/image-229.png',
                    ],
                    content: '',
                    link: '/product/5/2/karbondioksit(co2)_gazli_yangin_sondurme_cihazlari',
                    detail: <div className="detail">
                        <figure>
                            <h2>ÖZELLİKLER</h2>
                            <ul>
                                <li>Gövde, çelik çekme metodu ile imal edilmiş olup, elektrostatik toz boyalıdır.</li>
                                <li>Cihazların gövdesine 250 Bar Hidrostatik basınç testi uygulanmaktadır.</li>
                                <li>Cihazların kontrollü çalışmasını sağlayan vana CE belgelidir.</li>
                                <li>Cihazlarımızın özel askı tertibatı bulunmaktadır.</li>
                                <li>Cihazlarımız TSE 862-3 EN 3-7 ve ISO 9001-2008 belgelidir.</li>
                            </ul>
                            <p>
                                Karbondioksit söndürücüler, yanıcı sıvı yangınlarını, elektrik donanım yangınlarını karbondioksit gazı kullanılarak söndürür ve kar tanecikleri halinde karbondioksit buzlanma sağlar. Kirliliğe, zehirli atıklara ve donanımlara zarar vermeden yangını söndürmeyi sağlar.
                                Karbondioksit söndürücüler, laboratuarlarda, bilgisayar odalarında, boyahanelerde, transformatör ve anahtarlama ve benzeri bölgelerin kullanımı için uygundurlar. Yağ, yakıt alkol solvent cila ve diğer yanıcı malzemeler kullanılan tekstil makinelerinde, lokomotif dizel motorlarda, uçak ve gemilerin korunması için de kullanılabilir.
                            </p>
                            <figcaption>TEKNİK VERİLER </figcaption>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Ürün Kodu</th>
                                        <th>Kapasite</th>
                                        <th>Dolu Ağırlık</th>
                                        <th>Çalışma Basıncı</th>
                                        <th>Boşalma Süresi</th>
                                        <th>Püskürtme Uzaklığı</th>
                                        <th>Sıcaklık Aralığı</th>
                                        <th>Yükseklik</th>
                                        <th>Çap</th>
                                        <th><img src="https://dogantanyangin.com/images/products/a_tablo.jpg" /></th>
                                        <th><img src="https://dogantanyangin.com/images/products/b-tablo.jpg" /></th>
                                        <th><img src="https://dogantanyangin.com/images/products/c-tablo (1).jpg" /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>YSC CO 005</td>
                                        <td>5 Kg</td>
                                        <td>9.2 Kg</td>
                                        <td>17 BAR</td>
                                        <td>14 sn</td>
                                        <td>5 m</td>
                                        <td>-20 / + 60 C °</td>
                                        <td>720 mm</td>
                                        <td>108 mm</td>
                                        <td>•</td>
                                        <td>21 B</td>
                                        <td>•</td>
                                    </tr>
                                    <tr>
                                        <td>YSC CO 010</td>
                                        <td>10 Kg</td>
                                        <td>14.5 Kg</td>
                                        <td>17 BAR</td>
                                        <td>25 sn</td>
                                        <td>15 m</td>
                                        <td>-20 / + 60 C °</td>
                                        <td>1010 mm</td>
                                        <td>139.7 mm</td>
                                        <td>•</td>
                                        <td>55 B</td>
                                        <td>•</td>
                                    </tr>
                                </tbody>
                            </table>
                        </figure>

                    </div>
                },
                {
                    id: 3,
                    name: 'OTOMATİK YANGIN SÖNDÜRME CİHAZLARI',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/images/products/kuru_kimyevi_tozlu_yangin_söndürme_cihazlari/image-223.png',
                    ],
                    content: '',
                    link: '/product/5/3/otomatik_yangin_sondurme_cihazlari',
                    detail: <div className="detail">
                        <h2>Özellikleri</h2>
                        <figure>
                            <p>
                                HFC Gazlı, HFC 227 EA Gazlı, Kuru Kimyevi Micro Söndürme sistemi 1 kg 2 metre hortumlu
                            </p>
                                Çalisma prensibi; azot ile 12 bar basinç altinda olan polimerden üretilmis olan boru detektörün (Tubing) isi karsisinda yaklasik 100 Cº’de delinmesi sonucunda azot gazinin açiga çikmasi ve ayni anda silindir üzerindeki vanayi açmasi ile baslamaktadir. Ayrica, yangin alarm sistemine ihtiyaç duyulmaz. Direkt sistemlerde, nozul ve boru sistemine ihtiyaç duyulmamaktadir. Tek bir sistemle 8.5 m³ hacme kadar olan bölümler korunmaktadir.

                                Polimerden üretilmis boru detektörün isi karsisinda delinmesi ile açiga çikan azot ayni zamanda silindir üzerinde bulunan vananin açilmasini da saglamaktadir. Vananin açilmasi ile beraber, söndürme ajani silindiri terkeder ve boru detektörün delinen noktasindan çikar ve detektörün delinmesine neden olan yangin kaynagina dogru püskürür. Tüm hacme yayilir ve yaklasik 3-5 saniye içinde yangini söndürür.

                                Düsük maliyetlidir. Tüm odayi söndürmek yerine sadece yangin kaynagi olabilecek kabinin söndürülmesi nedeni ile ucuz bir çözümdür. Yanginin , dogruca kaynaginda söndürülmesi nedeni ile çok verimlidir. Montaji çok kisa süre içinde ve kolayca yapilir.

                                <p>
                                Bu sistem, Temiz Gazlı Söndürme Sistemlerinin uygulanmasının zor ve maliyetli olduğu kontrol panelleri, makine elektrik kutuları, CNC ve EDM Makineleri içerisinde yaygın olarak uygulanmaktadır. Hidrolik hesap gerektirmeden(Pre-engineered) uygulanabilmesi büyük avantaj sağlamaktadır. Tubing Sistemi ile hem algılama hem de söndürme tek bir tesisat ile yapılabilmektedir. Ayrıca tubing sisteminin esnekliği sayesinde dar ve ulaşılması zor alanlarda montaj kolaylığı sağlamaktadır.

                                Kullanım alanları - Elektrik panoları, Küçük bilgisayar sistemleri.
                            </p>
                        </figure>
                        {/* <figure>
                            <figcaption>TEKNİK VERİLER </figcaption>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Ürün Kodu</th>
                                        <th>Kapasite</th>
                                        <th>Dolu Ağırlığı</th>
                                        <th>Söndürücü Madde</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>DT_019_YSC</td>
                                        <td>6 kg</td>
                                        <td>9,4 kg</td>
                                        <td>Kuru Kimyevi Tozlu</td>
                                    </tr>
                                    <tr>
                                        <td>DT_020_YSC</td>
                                        <td>12 kg</td>
                                        <td>13,8 kg</td>
                                        <td>Kuru Kimyevi Tozlu</td>
                                    </tr>
                                </tbody>
                            </table>
                        </figure> */}
                    </div >
                },
{
    id: 4,
        name: 'BİOVERSAL YANGIN SÖNDÜRÜCÜLER',
            imgsrc: [
                process.env.PUBLIC_URL + '/images/products/bioversal_yangin_sonduruculer/bioversal-yangin-tupu-t-1.jpg',
            ],
                content: '',
                    link: '/product/5/4/bioversal_yangin_sonduruculer',
                        detail: <div className="detail">
                            <h2>Özellikler</h2>
                            <figure>
                                <p>
                                    BIOVERSAL QF&nbsp;Uluslararası kabul görmüş çevre dostu bir yangın söndürücü olup , tamamen bitkisel maddelerden üretilmiştir.&nbsp;&nbsp;Yangınlarda yüksek soğutucu etkisinden dolayı insan yaşamını korumakta&nbsp; , yapı tasarımının stabilitesini bozmamakta ve dumanın çoğalmasını azaltmaktadır.
                                </p>
                                <p>

                                    Ayrıca yanan maddenin tekrar tutuşamaz hale gelmesini sağlar. BIOVERSAL QFkullanılırken malzemelerin üzerinde tahrip etkisi yapmaz , insanlara ve çevreye zararı yoktur.
                                </p>
                                <p>

                                    İşi bilenler tarafından kullanılan&nbsp;BIOVERSAL QF&nbsp;bitkilerin, hayvanların ve mikroorganizmaların yaşam alanını korur, etrafa zarar verecek madde taşımadığı için su kalitesinin korumasını da yapmış olur. Suyun doğal dengesi ve biyolojik ayrıştırma ünitelerindeki denge&nbsp;BIOVERSAL QF&nbsp;kullanımıyla etkilenmez.
                                </p>
                                <p>

                                    BIOVERSAL – Ürünleri AKARYAKIT yangınlarını söndürmede  mucize etkisiyle yeni nesil yangın söndürme üründür. Çok etkili söndürme gücü yanında , söndürülen yer tekrar tutuşmaz , köpük halısı oluşturduğu gibi , sadece yüzeyde yanan akaryakıtın kimyasal  yapısını bozar , parçalar ve etrafını sararak kapsüle eder.Yangının ısısını üzerine alarak , hidrokarbon moleküllerini CO2 ve H2O ya çevirerek yanıcılık özelliğini ortadan kaldırır.
                                </p>
                                <p>

                                    Yağ ve Yakıtı temizleme özelliği sayesinde , Tank temizliklerinde , Gas Free yapmada , kaynak öncesi çevreyi emniyete almada %1 oranında Bioversal QF , su ile seyretilerek kullanılırsa tank temizliği yapılır, gas free yapar , yangın ve  patlama riskini en aza indirir.
                                </p>
                                <p>

                                    BIOVERSAL QF özellikle çevreyi, yangından korumada çevre dostu bir malzeme olup örneğin patlama ve yangın tehlikesinde (çevre dostu köpük halısı) ve kaynak çalışmalarında en emniyetli koruyucu ürünüdür.
                                </p>
                                {/* <figcaption>A Sınıfı Yangınlar</figcaption>
                            <ul>
                                <li>Ahşap malzemeler, Otlar çalı-çırpı, Kağıtlar, Giysiler, Tüm Kumaşlar, Perde-ev tekstil, Büro-ev
                                    mobilyaları Kauçuk selüloz, Plastikler</li>
                            </ul>
                            <figcaption>B Sınıfı Yangınlar</figcaption>
                            <ul>
                                <li>Benzin, Benzol, Mazot, Vernik, Boyalar, Tiner, Aseton, Parafin, Asfalt, Tutkal, Yemek Yağları,
                                    Endüstriyel Yağlar </li>
                            </ul>
                            <figcaption>C Sınıfı Yangınlar</figcaption>
                            <ul>
                                <li>Doğalgaz, LPG, Metan, Hidrojen, Propan, Asetilen, Elektrik kabloları, Elektrik trofaları, Güç kaynakları
                                    Bilgi İşlem üniteleri, Elektronik ev eşyaları, Elektrikli ev eşyaları
                                </li>
                            </ul> */}
                            </figure>
                            {/* <figure>
                            <figcaption>TEKNİK VERİLER </figcaption>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Ürün Kodu</th>
                                        <th>Kapasite</th>
                                        <th>Dolu Ağırlığı</th>
                                        <th>Söndürücü Madde</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>DT_019_YSC</td>
                                        <td>6 kg</td>
                                        <td>9,4 kg</td>
                                        <td>Kuru Kimyevi Tozlu</td>
                                    </tr>
                                    <tr>
                                        <td>DT_020_YSC</td>
                                        <td>12 kg</td>
                                        <td>13,8 kg</td>
                                        <td>Kuru Kimyevi Tozlu</td>
                                    </tr>
                                </tbody>
                            </table>
                        </figure> */}
                        </div>
},
            ]
        }
    },
{
    category: {
        id: 6,
            name: 'YANGIN ALARM SİSTEMLERİ',
                imgsrc: [process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/hq.jpeg'],
                    content: 'Adresli ve konvansiyonel yangın algılama sistemleri.',
                        link: '/category/adresli_yangin_alarm_sistemleri/6/subcategories',
                            subcategories: [
                                {
                                    id: 0,
                                    name: 'ADRESLİ YANGIN ALARM SİSTEMLERİ',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-230.png',
                                        process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-231.png',
                                        process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-232.png',
                                        process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/0210f707d.jfif',
                                        process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-234.png',
                                        process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-235.png',
                                        process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-236.png',
                                        process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/2b43a2a8d.jfif',
                                        process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-238.png',

                                    ],
                                    content: '',
                                    link: '/product/6/0/adresli_yangin_alarm_sistemleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            {/* <figcaption>A Sınıfı Yangınlar</figcaption> */}
                                            <p>Günümüzde tüm teknolojik aletlerde olduğu gibi yaşam alanlarımız da; kamu binaları, oteller, hastaneler akıllı hale geliyor. Küresel anlamda kalitesini ispatlamış markalarla çalışan DOĞAN TAN YANGIN , yüksek hizmet niteliğiyle tüm dikkatleri üzerinde toplamayı başarıyor.
                                                Gelişmiş cihazlar ile birlikte adreslenebilir yangın alarm sistemleri sunan firmamız, binalarda can ve mal güvenliğini koruyor. Santralden yangına dayanıklı kablo olarak çıkan her bir yangın çevrimi, dedektör, buton, siren gibi elemanlar üzerinden geçerek yine aynı santralde son buluyor.Çevrime dahil olan dedektör ve butonlar yangın ihbarı için kullanılıyor. Dedektörler duman ve ısıyı algılarken butonlar manuel kullanım için tasarlanıyor
                                            </p>
                                            <p>Beam dedektör modelleri depo, hangar gibi yüksek tavanlara sahip alanlarda alıcı verici çifti olarak kullanılıyor. Vericiden çıkan kızılötesi ışınlar alıcı olarak anılan yansıtıcı parçadan vericiye tekrar dönüş yapıyor. Duman oluşan yerlerde geri dönemeyen ışınlar yangının habercisi oluyor. Kanal dedektörleri ise klima santrallerinde ve havalandırma kanallarında tercih ediliyor.
                                                Bir diğer adı loop olan yangın çevrimlerine deprem anında sarsıntıyı algılayan sismik röle modelleri dâhil edilebiliyor. Bazen de mutfak, yemekhane, laboratuvar gibi mahaller için alarm sisteminde LPG dedektörü veya doğal gaz dedektörü tercih ediliyor. Karbonmonoksit dedektörü ise kapalı otopark alanlarında yoğun egzoz gazının tespitinde kullanılıyor. Birbirleriyle aynı çevrimde uyumlu çalışan tüm cihazlar tam koruma hedefliyor.
                                            </p>
                                            <h4>Akıllı Santraller ile Nokta Atışı Yer Tespiti</h4>
                                            <p>Adresli yangın alarm sistemlerinde bulunan her bir ihbar cihazına sayısal bir adres veriliyor. Bu adres, cihazların eşsiz kimlik numarası olarak kullanılıyor. Montaj bittikten sonra yangın santraline bilgileri girilen cihazların hangi oda veya mahalde oldukları da işleniyor. Santral, yangın anında dedektör veya butondan gelen sinyal sayesinde olay yerinin bilgisini anında gösteriyor. Böylece operatör ihbar gelen yere daha hızlı ve doğru bir şekilde müdahale edebiliyor.
                                                Zaman kayıplarının önüne geçen adresli yangın alarm sistemi; özellikle öğrenci yurdu, kamu binası, alışveriş merkezi, hastane ya da otel gibi çok odalı binalarda tercih ediliyor. Yüksek nüfus kapasiteli alanlarda yangın senaryosu da uygulanabiliyor. Bu kapsamda ihbar gelen mahal yakınlarında, asansörlerin belirlenen kata getirilip kapılarının açtırılması, yürüyen merdivenlerin durdurulması, yangın perdelerinin düşürülmesi gibi otomatik aksiyonlar hayata geçirilebiliyor.
                                                DOĞAN TAN YANGIN olarak, Bosch, Honeywell,  Gloobal GFE ,Eleks,teknim,Mavigard, Finder  ve Dünyaca kabul görmüş markalar ile çözümler sunuyoruz
                                            </p>
                                            {/* <ul>
                                <li>Ahşap malzemeler, Otlar çalı-çırpı, Kağıtlar, Giysiler, Tüm Kumaşlar, Perde-ev tekstil, Büro-ev
                                    mobilyaları Kauçuk selüloz, Plastikler</li>
                            </ul>
                            <figcaption>B Sınıfı Yangınlar</figcaption>
                            <ul>
                                <li>Benzin, Benzol, Mazot, Vernik, Boyalar, Tiner, Aseton, Parafin, Asfalt, Tutkal, Yemek Yağları,
                                    Endüstriyel Yağlar </li>
                            </ul>
                            <figcaption>C Sınıfı Yangınlar</figcaption>
                            <ul>
                                <li>Doğalgaz, LPG, Metan, Hidrojen, Propan, Asetilen, Elektrik kabloları, Elektrik trofaları, Güç kaynakları
                                    Bilgi İşlem üniteleri, Elektronik ev eşyaları, Elektrikli ev eşyaları
                                </li>
                            </ul> */}
                                        </figure>
                                        <figure>
                                            <figcaption>Yangın Alarm Sisteminde Bulunan Ekipmanlar</figcaption>
                                            <S.Items>
                                                <S.InItem to="/product/6/2/adresli_yangin_alarm_sistemleri">
                                                    <img src={process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-231.png'} alt="" />
                                                    <span>Optik Duman Dedektörü</span>
                                                </S.InItem>
                                                <S.InItem to="/product/6/3/adresli_yangin_alarm_sistemleri">
                                                    <img src={process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-232.png'} alt="" />
                                                    <span>Standart Dedektör Soketi</span>
                                                </S.InItem>
                                                <S.InItem to="/product/6/4/adresli_yangin_alarm_sistemleri">
                                                    <img src={process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-233.png'} alt="" />
                                                    <span>Yangın İhbar Butonu</span>
                                                </S.InItem>
                                                <S.InItem to="/product/6/5/adresli_yangin_alarm_sistemleri">
                                                    <img src={process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-234.png'} alt="" />
                                                    <span>Elektronik Siren 24v</span>
                                                </S.InItem>
                                                <S.InItem to="/product/6/6/adresli_yangin_alarm_sistemleri">
                                                    <img src={process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-235.png'} alt="" />
                                                    <span>Flaşörlü Elektronik Siren 24v</span>
                                                </S.InItem>
                                                <S.InItem to="/product/6/0/adresli_yangin_alarm_sistemleri">
                                                    <img src={process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-236.png'} alt="" />
                                                    <span>Akü</span>
                                                </S.InItem>
                                                <S.InItem to="/product/6/8/adresli_yangin_alarm_sistemleri">
                                                    <img src={process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-237.png'} alt="" />
                                                    <span>LPG & Doğalgaz Dedektörü</span>
                                                </S.InItem>
                                                <S.InItem to="/product/6/0/adresli_yangin_alarm_sistemleri">
                                                    <img src={process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-238.png'} alt="" />
                                                    <span>Deprem Sensörü</span>
                                                </S.InItem>
                                            </S.Items>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 1,
                                    name: 'KONVANSİYONEL YANGIN ALARM SİSTEMLERİ',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/konvansiyonel_yangin_alarm_sistemleri/image-239.png',
                                        process.env.PUBLIC_URL + '/images/products/konvansiyonel_yangin_alarm_sistemleri/image-240.png',
                                        process.env.PUBLIC_URL + '/images/products/konvansiyonel_yangin_alarm_sistemleri/image-241.png',
                                        process.env.PUBLIC_URL + '/images/products/konvansiyonel_yangin_alarm_sistemleri/image-242.png',
                                        process.env.PUBLIC_URL + '/images/products/konvansiyonel_yangin_alarm_sistemleri/image-243.png',
                                        process.env.PUBLIC_URL + '/images/products/konvansiyonel_yangin_alarm_sistemleri/image-244.png',
                                        process.env.PUBLIC_URL + '/images/products/konvansiyonel_yangin_alarm_sistemleri/image-245.png',
                                        process.env.PUBLIC_URL + '/images/products/konvansiyonel_yangin_alarm_sistemleri/image-246.png',
                                        process.env.PUBLIC_URL + '/images/products/konvansiyonel_yangin_alarm_sistemleri/image-247.png',
                                    ],
                                    content: '',
                                    link: '/product/6/1/adresli_yangin_alarm_sistemleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            {/* <figcaption>A Sınıfı Yangınlar</figcaption> */}
                                            <p>Konvansiyonel Yangın Alarm Sistemi özellikle küçük ve orta ölçekli işletmelerde veya yaşam alanlarında tercih edilir ve ekonomik çözümleri ile hayati bir koruma sağlar.
                                                Yangın anında verdiği ikaz yöntemleriyle can kayıplarının önüne geçen sistem modeli, değerli eşya ve evrakların kül olmasını da engeller. Yangını bölgesel olarak tespit eden sistem, tahliye ve söndürme işlemleri için zaman kazandırır.
                                                Yangın anında olay yerinin hızlı bir şekilde boşaltılmasını sağlayan otomatik ikaz sistemleri aynı zamanda kurtarma işlemlerinin başlamasını hızlandırır. Maddi ve manevi kayıpları en aza indirmeyi hedefleyen yangın alarm sistemleri, kamuya açık alanlarda rahatlıkla kullanılır.
                                            </p>
                                            <p>Bölge anlamına gelen Zone, konvansiyonel yangın alarm sistemleri mantığının temelini oluşturur. Santralden çıkan yangına dayanıklı kabloya seri bir şekilde özel yangın cihazları bağlanır. Hat sonunda ise genellikle sonlandırma direnci takılır. Yangın santralinden sonlandırma direncine kadar giden kablo ve elemanlar 1 bölgeyi oluşturur. Bölgeye ait hattaki yangın elemanlarından herhangi birinden ihbar geldiğinde, yangın santralinde o bölgeye işaret edilir. Böylece yangın bölgesel anlamda tespit edilmiş olur.
                                                Yangın Sisteminde Kullanılan Elemanlar
                                                Konvansiyonel yangın ikaz sistemlerinde, duman algılayan optik dedektör modelleri kullanılır. Bunun dışında yüksek ısıyı algılayan sıcaklık dedektörleri veya hem ısıyı hem de dumanı algılayabilen multi dedektör çeşitleri de bulunur. Isı, duman veya multi dedektörler projeye bağlı olmak üzere tavanda veya tavan arasında kullanılabilir. Kanal dedektörleri ise klima santraline bağlanan havalandırma kanallarında denetleme yapar.
                                                Bir diğer adı Beam olan ışın dedektörleri normal dedektörlerin çalışamayacağı yüksek tavanlı alanlarda tercih edilir. Kızılötesi ışın gönderen dedektörler karşısındaki reflektörden yansıyan ışını tekrar alır. Yangın anında oluşan duman ortadan geçen ışını engellediğinde Beam dedektörü santrale ihbar sinyali gönderir. Böylece fabrika,hangar gibi büyük depolarda olası yangın tehlikeleri erkenden fark edilebilir.
                                                Zone bünyesindeki butonlara manuel olarak basıldığında santrale ihbar gönderir. Daha çok kapalı otoparklarda kullanılan karbonmonoksit dedektörleri aşırı egzoz gazı için tercih edilir. LPG dedektörü veya doğal gaz dedektörü ise yakıt olarak gaz kullanılan mutfaklarda hayat kurtarır. İhbar işlemlerinin ardından ikaz aşamasına geçilir. Alarm sistemi için başrol yangın flaşörü ve siren modelleri için ayrılmıştır. Sesli ve/veya ışıklı alarm veren bu cihazlar tahliyeyi hızlandırır.
                                                DOĞAN TAN YANGIN  olarak, Bosch, Honeywell,  Gloobal GFE ,Eleks,Teknim,Mavigard ,Finder ve Dünyaca kabul görmüş markalar ile çözümler sunuyoruz.

                                            </p>
                                            {/* <ul>
                                <li>Ahşap malzemeler, Otlar çalı-çırpı, Kağıtlar, Giysiler, Tüm Kumaşlar, Perde-ev tekstil, Büro-ev
                                    mobilyaları Kauçuk selüloz, Plastikler</li>
                            </ul>
                            <figcaption>B Sınıfı Yangınlar</figcaption>
                            <ul>
                                <li>Benzin, Benzol, Mazot, Vernik, Boyalar, Tiner, Aseton, Parafin, Asfalt, Tutkal, Yemek Yağları,
                                    Endüstriyel Yağlar </li>
                            </ul>
                            <figcaption>C Sınıfı Yangınlar</figcaption>
                            <ul>
                                <li>Doğalgaz, LPG, Metan, Hidrojen, Propan, Asetilen, Elektrik kabloları, Elektrik trofaları, Güç kaynakları
                                    Bilgi İşlem üniteleri, Elektronik ev eşyaları, Elektrikli ev eşyaları
                                </li>
                            </ul> */}
                                        </figure>
                                        <figure>
                                            <figcaption>Yangın Alarm Sisteminde Bulunan Ekipmanlar</figcaption>
                                            <S.Items>
                                                <S.InItem to="/product/6/2/adresli_yangin_alarm_sistemleri">
                                                    <img src={process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-231.png'} alt="" />
                                                    <span>Optik Duman Dedektörü</span>
                                                </S.InItem>
                                                <S.InItem to="/product/6/3/adresli_yangin_alarm_sistemleri">
                                                    <img src={process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-232.png'} alt="" />
                                                    <span>Standart Dedektör Soketi</span>
                                                </S.InItem>
                                                <S.InItem to="/product/6/4/adresli_yangin_alarm_sistemleri">
                                                    <img src={process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-233.png'} alt="" />
                                                    <span>Yangın İhbar Butonu</span>
                                                </S.InItem>
                                                <S.InItem to="/product/6/5/adresli_yangin_alarm_sistemleri">
                                                    <img src={process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-234.png'} alt="" />
                                                    <span>Elektronik Siren 24v</span>
                                                </S.InItem>
                                                <S.InItem to="/product/6/6/adresli_yangin_alarm_sistemleri">
                                                    <img src={process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-235.png'} alt="" />
                                                    <span>Flaşörlü Elektronik Siren 24v</span>
                                                </S.InItem>
                                                <S.InItem to="/product/6/0/adresli_yangin_alarm_sistemleri">
                                                    <img src={process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-236.png'} alt="" />
                                                    <span>Akü</span>
                                                </S.InItem>
                                                <S.InItem to="/product/6/8/adresli_yangin_alarm_sistemleri">
                                                    <img src={process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-237.png'} alt="" />
                                                    <span>LPG & Doğalgaz Dedektörü</span>
                                                </S.InItem>
                                                <S.InItem to="/product/6/0/adresli_yangin_alarm_sistemleri">
                                                    <img src={process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-238.png'} alt="" />
                                                    <span>Deprem Sensörü</span>
                                                </S.InItem>
                                            </S.Items>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 51,
                                    name: 'KARBONMONOKSİT YANGIN ALARM SİSTEMLERİ',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/karbonmonoksit_yangin_alarm_sistemleri/1.jpg',
                                        process.env.PUBLIC_URL + '/images/products/karbonmonoksit_yangin_alarm_sistemleri/2.jpg',
                                    ],
                                    content: '',
                                    link: '/product/6/51/karbonmonoksit_yangin_alarm_sistemleri',
                                    detail: <div className="detail">
                                        {/* <h2>Teknik Detaylar</h2> */}

                                        <figure>
                                            <figcaption>Yangın Alarm Sisteminde Bulunan Ekipmanlar</figcaption>
                                            <S.Items>
                                                <S.InItem to="/product/6/30/karbonmonoksit_yangin_alarm_sistemleri">
                                                    <img src={process.env.PUBLIC_URL + '/images/products/karbonmonoksit_yangin_alarm_sistemleri/1.jpg'} alt="" />
                                                    <span>KMD 300 Serisi Karbonmonoksit Dedektörü</span>
                                                </S.InItem>
                                                <S.InItem to="/product/6/31/karbonmonoksit_yangin_alarm_sistemleri">
                                                    <img src={process.env.PUBLIC_URL + '/images/products/karbonmonoksit_yangin_alarm_sistemleri/2.jpg'} alt="" />
                                                    <span>Karbonmonoksit Paneli</span>
                                                </S.InItem>

                                            </S.Items>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 30,
                                    name: 'KMD 300 Serisi Karbonmonoksit Dedektörü',
                                    hide: true,
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/karbonmonoksit_yangin_alarm_sistemleri/1.jpg',
                                        // process.env.PUBLIC_URL + '/images/products/karbonmonoksit_yangin_alarm_sistemleri/2.jpg',
                                    ],
                                    content: '',
                                    link: '/product/6/30/karbonmonoksit_yangin_alarm_sistemleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <p>KMD300 detektör KM300 Serisi CO algılama sistemleri için bir karbon monoksit algılayıcısı olarak tasarlanmıştır. KMD300 bir 3-kablolu veriyolu (bus) sistemi üzerinden görselleştirme için karbon monoksit gazını kontrol paneline sunan akıllı bir cihazdır. </p>
                                            <ul>
                                                <li>UNE 23300:1984, CE, WEEE, RoHS standartları</li>
                                                <li>Elektrokimyasal CO sensörü ile yüksek gaz tanımlama hassasiyeti</li>
                                                <li>1 ppm çözünürlüğe kadar, %5′ten düşük ölçüm hatası</li>
                                                <li>Kısa tepkime süresi</li>
                                                <li>Kalibrasyon gerektirmez</li>
                                                <li>Düşük akım harcama</li>
                                                <li>Durum gösterge LED’i</li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 31,
                                    name: 'KMD 300 Serisi Karbonmonoksit Paneli',
                                    hide: true,
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/karbonmonoksit_yangin_alarm_sistemleri/2.jpg',
                                        // process.env.PUBLIC_URL + '/images/products/karbonmonoksit_yangin_alarm_sistemleri/2.jpg',
                                    ],
                                    content: '',
                                    link: '/product/6/30/karbonmonoksit_yangin_alarm_sistemleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <p>Kapalı otopark alanları için karbonmonoksit algılama ve egzoz fanlarının otomatik kontrolü amacıyla dizayn edilmiştir. CO Algılama Kontrol Panelleri Karbonmonoksit (CO) konsantrasyonu oluşan otopark alanlarında tamamıyla CO algılama için dizayn edilmiştir.</p>
                                            <p>KM300 serisi kontrol panelleri ayrı ayrı kontrol edilebilen ve süpervize 1, 2, 3 veya 4 bölgeli olarak temin edilebilmektedir.</p>
                                            <p>KM300 CO Kontrol Paneli büyük ve küçük ölçekli projelerde kullanılabilir. Her bir bölge 4500 m²'lik  alanı  kontrol edebildiği gibi, 4 bölgeli panel kullanılması halinde 18000 m²'ye kadar CO algılama yapılabilmektedir.</p>
                                            <p>Her bir bölge için3 damarlı 1.5 mm² kablo üzerine en fazla 15 adet CO dedektörü bağlanabilmektedir. Ayrıca her bir bölge için 2 adet havalandırma/egzost rölesi ile 1 adet genel alarm rölesi, ayrı ayrı programlanabilir CO seviyelerine göre tanımlanabilmektedir.</p>
                                            <p>Panel ile uyumlu saha cihazı olarak mikroişlemci tabanlı KMD300 dedektörleri kullanılmaktadır. CO algılama aralığı 0 - 300 ppm arasındadır. Dedektör üzerinde çakan yeşil ışık normal çalışma durumunu gösterir. Sürekli yanan kırmızı ışık ise 50 ppm üzerindeki CO yoğunluğunda görsel olarak haber verir. yanıp-sönen kırmızı ışık hata durumunu belirtmektedirTüm CO panellerinin ve CO dedektörünün CE, WEEE ve RoHS uyumlulukluk  sertifikaları mevcuttur.</p>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 2,
                                    name: 'OPTİK DUMAN DEDEKTÖRÜ',
                                    hide: true,
                                    imgsrc: [

                                        process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-231.png',


                                    ],
                                    content: '',
                                    link: '/product/6/2/adresli_yangin_alarm_sistemleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            {/* <figcaption>A Sınıfı Yangınlar</figcaption> */}
                                            <p>Yangın Algılama Dedektörleri Nova-AOD Akıllı Adresli Optik Duman Dedektörü yeni tasarımı ile beraber yüksek teknolojik özellikleri barındırmaktadır. Dahili izolatör mevcuttur.

                                                Duman Dedektörü Seviyeleri: Kirlilik, Ön Alarm (Fabrika ayarlarında kapalı),  Yüksek, Normal, Düşük hassasiyet, Gece/gündüz.</p>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 3,
                                    name: 'STANDART DEDEKTÖR SOKETİ',
                                    hide: true,
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-232.png',
                                    ],
                                    content: '',
                                    link: '/product/6/3/adresli_yangin_alarm_sistemleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            {/* <figcaption>A Sınıfı Yangınlar</figcaption> */}
                                            <ul>
                                                <li>Ağırlık: 40 gr.</li>
                                                <li>Ölçüler: Q105 mm, h 100 mm (+%0,5)</li>
                                                <li>Renk: Beyaz</li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 4,
                                    name: 'YANGIN İHBAR BUTONU',
                                    hide: true,
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-233.png',
                                    ],
                                    content: '',
                                    link: '/product/6/4/adresli_yangin_alarm_sistemleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            {/* <figcaption>A Sınıfı Yangınlar</figcaption> */}
                                            <p>
                                                Akıllı Adresli Yangın Algılama Butonu Nova-AMCP kolay montaj ve kurulumu ile Optimum bir kullanım sağlamaktadır. Yangın Algılama sistemlerinde alarmı manuel olarak harekete geçirerek bir yangın durumunda ihbar vermek için imkan tanıyan cihazlardır. Özel Anahtarı ile Resetlenebilen modeldir. Şık ve kullanım kolaylığı sağlayan tasarım. Gömme tip kullanılabilme Özelliği Vardır.  Dahili izolatör mevcuttur.
                                            </p>
                                            <p>
                                                Sarı ve Yeşil Renkleri Mevcuttur.
                                            </p>
                                            <p>
                                                Opsiyonel şefaf kapak seçeneği mevcuttur.
                                            </p>
                                            <p>
                                                /EN54-11 ve EN54-17 Standartlarına Sahiptir.
                                            </p>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 5,
                                    name: 'ELEKTRONİK SİREN 24V',
                                    hide: true,
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-234.png',
                                    ],
                                    content: '',
                                    link: '/product/6/5/adresli_yangin_alarm_sistemleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            {/* <figcaption>A Sınıfı Yangınlar</figcaption> */}
                                            <p>
                                                Adresli Siren alarm durumunda sesli ikaz vermek için geliştirilmiştir. Estetik görünüm yüksek ses ve düşük akım tüketimi ile adresli sisteme entegre kullanılmaktadır.
                                            </p>
                                            <p>
                                                8 ton 32 melodi desteği ile 1 m mesafeden maksimum 105 dB - m (A) ses vermektedir. Dahili izolatör mevcuttur.
                                            </p>
                                            <p>
                                                EN54-11 ve EN54-17 Standartlarına Sahiptir.
                                            </p>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 6,
                                    name: 'FLAŞÖRLÜ ELEKTRONİK SİREN 24V',
                                    hide: true,
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-235.png',
                                    ],
                                    content: '',
                                    link: '/product/6/6/adresli_yangin_alarm_sistemleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            {/* <figcaption>A Sınıfı Yangınlar</figcaption> */}
                                            <p>
                                                Adresli Flaşörlü Siren alarm durumunda hem sesli hemde görsel ikaz vermek için geliştirilmiştir. Estetik görünüm yüksek ses ve düşük akım tüketimi ile adresli sisteme entegre kullanılmaktadır. Yeni nesil flaşör teknolojisi ile maksimum ışık seçeneğin mevcuttur. 8 ton 32 melodi desteği ile 1 m mesafeden maksimum 105 dB-m (A) ses vermektedir. Dahili izolatör mevcuttur.
                                            </p>
                                            <p>
                                                EN54-11 ve EN54-17 Standartlarına Sahiptir.
                                            </p>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 8,
                                    name: 'LPG & DOĞALGAZ DEDEKTÖRÜ',
                                    hide: true,
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/adresli_yangin_alarm_sistemleri/image-237.png',
                                    ],
                                    content: '',
                                    link: '/product/6/8/adresli_yangin_alarm_sistemleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            {/* <figcaption>A Sınıfı Yangınlar</figcaption> */}
                                            <p>
                                                Adresli gaz dedektörü, LPG ve doğal gaz dedektörü  röle çıkışlı, dahili buzzerlı, ses ve ışık uyarılı, test butonlu, modern dizayn, duvar  montajı ev ve ofisler için uyumlu duvar montaj aparatı dahil.
                                            </p>
                                            <p>
                                                12/24 VDC seçeneği mevcuttur. Harici 24V besleme gerektirir.
                                            </p>
                                            <p>
                                                EN50194-1, EN54-17standartlarına ve GAZMER onayına sahiptir.
                                            </p>
                                        </figure>

                                    </div>
                                },
                            ]
    }
},
{
    category: {
        id: 8,
            name: 'DAVLUMBAZ SÖNDÜRME SİSTEMLERİ',
                imgsrc: [
                    process.env.PUBLIC_URL + '/images/products/otomatik_davlumbaz_sondurme_sistemleri/2.jpg',

                ],
                    content: 'UL 300  Standardına göre tasarlanmış ve NFPA 96 ,NFPA 17 A ’a uygun otomatik davlumbaz söndürme sistemleri.',
                        link: '/category/otomatik_davlumbaz_sondurme_sistemleri/8/subcategories',
                            subcategories: [
                                {
                                    id: 0,
                                    name: 'OTOMATİK DAVLUMBAZ SÖNDÜRME SİSTEMİ',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/otomatik_davlumbaz_sondurme_sistemleri/image-252.png',
                                        process.env.PUBLIC_URL + '/images/products/otomatik_davlumbaz_sondurme_sistemleri/2.jpg',
                                    ]
                                    ,
                                    content: '',
                                    link: '/product/8/0/otomatik_davlumbaz_sondurme_sistemleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>BİNALARIN YANGINDAN KORUNMASI HAKKINDA YÖNETMELİK 09.09.2009 / 27344</figcaption>
                                            <p>MADDE 57- (1) Konutlar hariç olmak üzere, alışveriş merkezleri, yüksek binalar içinde bulunan mutfaklar ve yemek fabrikaları ile bir anda 100'den fazla kişiye hizmet veren mutfakların davlumbazlarına otomatik söndürme sistemi yapılması ve ocaklarda kullanılan gazın özelliklerine göre gaz algılama, gaz kesme ve uyarı tesisatının kurulması şarttır.</p>
                                        </figure>
                                        <figure>
                                            <figcaption>Yardımcı Elemanlar</figcaption>
                                            <ul>
                                                <li>MANUEL BOŞALTMA KOLU : Sistemin manuel olarak boşaltılmasını sağlar. </li>
                                                <li>GAZ KESME VANASI : Yangın halinde mahale daha fazla yakıt girişini engeller.</li>
                                                <li>ELEKTRİK KONTAĞI : Alarm sistemine sinyal vermek veya mutfaktaki elektrikli cihazları kapatmak için kullanılır.Davlumbaz Söndürme Sistemleri ONAYLI: Davlumbaz söndürme sistemi tasarım,montaj ve malzeme özelliklerine dair kriterlerin belirlenmesi için TSE standartlarında yer almıyor ise öncelikle Avrupa birliği standartları ya da UL300 standartları dikkate alınmalıdır.</li>
                                                <p>Davlumbaz Söndürme Sistemleri UL300 standardına göre tasarlanmış ve NFPA 96, NFPA 17A'ya uygun olmalıdır.</p>
                                                <li>ESTETİK: Davlumbaz  söndürme sisteminin davlumbaz içerisinde kalan ekipmanları krom kaplı, pirinç ya da paslanmaz malzemelerden imal edilir ve şık görünümlüdür.Davlumbaz söndürme  sıvı söndürücü düşük PH seviyesi ile mutfak ekipmanlarına zarar vermez ve kolaylıkla temizlenebilir.</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <p>Her yıl dünyanın çeşitli yerlerinde çıkan küçük mutfak yangınları, gerekli önlemler alınmadığı için tüm yapıyı tehdit eder hale gelmektedir .Bu nedenle küçük bir mutfak yangını alışveriş merkezi, otel v.b. binaların tümüne zarar verdiğinden milyonlarca dolar maddi kayba aynı zamanda da iş ve hizmet kaybına sebep olmaktadır. Son derece basit ve ekonomik bir şekilde önlem almak, hasarı önlemede büyük oranda başarı sağlamaktadır.</p>
                                            <p><b>OTOMATİK:</b> Boşaltma mekanizması algılama hattının devreye girmesi sonucu kızgın yağ yangınları için özel olarak geliştirilmiş düşük PH seviyesine sahip potasyum karbonat bazlı sıvı söndürücünün boşaltma hattından hızlı bir şekilde boşalmasını sağlar.</p>
                                            <p><b>HIZLI:</b> Davlumbaz içine yerleştirilen eriyebilen metal detektörler sayesinde yangını çok hızlı bir şekilde algılar.</p>
                                            <p><b>ETKİLİ SÖNDÜRME:</b> Söndürücü sıvı nozullar vasıtası ile boşalarak yangının hızlı ve etkili bir şekilde söndürülmesini sağlar.Sıvı söndürücü kızgın yağ yangınları üzerinde köpük şeklinde bir battaniye oluşturarak alevlerin oksijen ile temasını keser.</p>
                                        </figure>

                                    </div>
                                },
                            ]
    }
},
{
    category: {
        id: 7,
            name: 'GAZLI SÖNDÜRME SİSTEMLERİ',
                imgsrc: [process.env.PUBLIC_URL + '/images/products/fm_200_fe227_(HFC227ea)_gazli_sondurme_sistemi/image-248.png'],
                    content: 'FM 200 ,CO2 gazlı ve pano içi otomatik söndürme sistemleri.',
                        link: '/category/gazli_sondurme_sistemi/7/subcategories',
                            subcategories: [
                                {
                                    id: 0,
                                    name: 'FM 200 - FE227 (HFC227ea) GAZLI SÖNDÜRME SİSTEMİ',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/fm_200_fe227_(HFC227ea)_gazli_sondurme_sistemi/image-248.png'],
                                    content: '',
                                    link: '/product/7/0/adresli_yangin_alarm_sistemleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>FM200 Gazli Sistem Bilesenleri</figcaption>
                                            <p>FM 200 kimyasal ajani ile baslica dört bilesen ve bu bilesenlerin aksesuarlarindan olusmaktadir.</p>
                                            <ul>
                                                <li><b>FM-200 Bilesenleri :</b> FM-200  gazinin depolandigi silindir, silindir koruyucusu ve FM 200 tahliye Nozülünden olusur.</li>
                                                <li><b>Kontrol Paneli :</b> Sistemin beynidir, algilama sistemini ve ekipmanlarini görüntülemek, izlemek ve sistemi aktive etmek için kullanilir.</li>
                                                <li><b>Algilama ve Uyari Elemanlari :</b> Sistemdeki algilama dedektörleri ve aksesuarlari, sesli ve görsel uyari veren sirenler, sistemin göz ve kulak islevini yerine getiren dis ekipmanlaridir.</li>
                                                <li><b>Tamamlayici Ekipmanlar :</b> Uyari levhalari, hortumlar, baglanti fittingleri, basinç manometreleri veya solenoid valf ve silindir valfini çalistirmak için gerekli   aktuatörden olusmaktadir.</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Sistem Dizaynı Ve Çalışma Şekli</figcaption>
                                            <p>FM 200,  gazi ile korunacak hacim için NFPA 12 ve TS ISO 14520 standartlarında ve kurallarına göre  gereken gaz miktari hesaplanir, Gaz miktarina uygun FM 200 tüpü seçilir, Çapraz zonlu yangin algilama sitemi ile teçhiz edilen odada gereken noktalara FM-200 bosaltma nozullari yerlestirilir,Duman dedektörlerinin aktive olmasi ile birlikte panelden FM-200 sistemi solenoid vanasina akim gönderilir, Tüm FM 200 gazi en fazla 10 saniye içinde korunan hacme bosaltilir.</p>
                                            <p>Sistemin çalismasi otomatik veya manuel olarak yapilabilir.

                                                Gazlı sabit söndürme sistemleri, tesisin nitelik ve ihtiyaçlarına bağlı olarak, uyulması zorunlu olan TS ISO 14520 “Gazlı Söndürme Sistemleri – Fiziksel
                                                Özellikler ve Sistem Tasarımı” ve TS EN 15004 “Sabit Yangınla Mücadele Sistemleri – Gaz Püskürten Sistemler – Tasarım, montaj ve Bakım” standartlarına uygun olarak tasarlanmaktadır.
                                            </p>
                                        </figure>
                                        <figure>
                                            <figcaption>Kullanildigi Yerler:</figcaption>
                                            <ul>
                                                <li>Bilgisayar, server odaları</li>
                                                <li>Telefon ve telekomünikasyon odaları</li>
                                                <li>Elektrik dagitim odalari / merkezleri</li>
                                                <li>Degerli malzeme depolari</li>
                                                <li>Laboratuarlar</li>
                                                <li>Banka kasalari, Arşivler, Kütüphaneler</li>
                                                <li>Müzeler ve sanat galerileri</li>
                                                <li>Türbinkabinleri v.b.  yerlerde FM 200 gazı güvenle kullanılabilir.</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            {/* <figcaption>A Sınıfı Yangınlar</figcaption> */}
                                            <p>FM-200, Halon 1301 gazinin kullaniminin yasaklanmasi sonrasinda piyasaya sürülen ve en yaygin kullanimi olan Halon alternatifi bir gazdir.
                                                Kimyasal ismi Heptafluoropropane dir. FM 200 gazi yanginı  kimyasal reaksiyonlari kirma ve isi enerjisini absorve etme özelligi ile yanginlari söndürmektedir.
                                                Şayet asagidaki durumlardan en az biri varsa, FM 200 gazli söndürme sistemleri tavsiye edilir:
                                                Korunacak alanda bulunan malzemenin degeri ya da is devamliligi açisindan önemi, saniyeler içinde harekete geçecek bir yangin sistemi gerektiriyorsa.
                                                Sprinkler sistemi, korunacak alanda bulunan malzemeye zarar verecekse.
                                                Korunacak alanda insan bulunmaktaysa.
                                                Yangin sonrasi temizligi elimine edilmek isteniyorsa.
                                                Kolay dizayn ve kuruluma ihtiyaç varsa.
                                                FM200 Gazli Sistem Özellikleri
                                                Renksizdir, kokusuzdur ve yalıtkan bir söndürme ajanıdır.
                                                Ozon yok etme kaysayısı sıfırdır, Atmosferik ömrü 31-42 yıldır.
                                                Fiziksel olarak söndürme yapar.
                                                Gaz boşalması sonrasi artik birakmaz, temizliğe ihtiyaç yoktur, Gaz atmosfere yayılır.
                                                Insan bulunan mekanlarda güvenle kullanilabilir.
                                                En yaygin kullanilan Halon 1301 alternatifidir.
                                                Ekonomiktir.
                                                Az yer kaplar.
                                                Bosalma süresi 10 saniyedir.

                                            </p>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 1,
                                    name: 'PANO İÇİ GAZLI OTOMATİK SÖNDÜRME SİSTEMLERİ',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/pano_ici_gazli_otomatik_sondurme_sistemleri/image-250.png'],
                                    content: '',
                                    link: '/product/7/1/pano_ici_gazli_otomatik_sondurme_sistemleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            <figcaption>Kullanildigi Yerler:</figcaption>
                                            <ul>
                                                <li>Elektrik Panoları</li>
                                                <li>UPS Kabinleri</li>
                                                <li>Telefon Santralleri Kabinleri</li>
                                                <li>Zırhlı Araç Motor Bölmeleri</li>
                                                <li>Hassas Elektronik Cihazlar</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Pano İçi Söndürme Sistemi Nedir ? </figcaption>
                                            <p>Kendi kendine harekete geçen yangını başladığı yerde bitiren esnek kırmızı algılama hortumu bulunan otomatik bir sistemdir.  Algılama hortumunu en iyi şekilde çalışmak üzere, polimer karışımı maddelerden yapılmıştır.Yangın alanını sayılı yerden değil, algılama hortumunun geçtiği her yerden koruma altına alır. Bu sistem tek başına çalışılabilmesi yanında bir alarm sistemi ile veya bir sistem kapatma şalteri ile de çalışabilir. Bu özellikler tüm riskleri ortadan kaldırır.</p>
                                            <figcaption>Neden bu kadar çok etkilidir ? </figcaption>
                                            <p>Çünkü algılama hortumu, korunacak yerin her tarafından geçtiği için çıkan yangını merkez yeri, yani ilk çıktığı yerden algılayıp söndürür. Algılama hortumu nem, pislik ve düzensiz hava akımından etkilenmez. Her koşulda algılama işini yapar. Basınçlandırılmış algılama hortumu, korunan alanın içine yerleştirilir. Yangın anında, yangın kaynağının bulunduğu taraftaki hortum küçük yangını hissedince erir ve patlar. Patlayan yerden söndürme maddesi tahliye olur ve yangını daha küçük alev halinde iken söndürür. İsteğe bağlı olarak adapte edilen alarm sistemi de devreye girer. Sistem çok çeşitli boyutlarda ve uzunlukta kurulabilir.</p>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 2,
                                    name: 'Co2 GAZLI SÖNDÜRME SİSTEMLERİ',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/co2_gazli_sondurme_sistemleri/image-251.png'],
                                    content: '',
                                    link: '/product/7/2/co2_gazli_sondurme_sistemleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>Özellikler</figcaption>
                                            <ul>
                                                <li>Ozona zarar vermez.</li>
                                                <li>Fiziksel olarak söndürme yapar.</li>
                                                <li>Lokal söndürme amaçli kullanilir</li>
                                                <li>Insan bulunan mekanlarda kullanilmaz</li>
                                                <li>Yeniden doldurma maliyeti düsük, yangin yaygin olarak bulunabilir</li>
                                                <li>Bosalma süresi 60-120 saniyedir. ( lokal sistemler için 30 saniye)</li>
                                                <li>Düsük basinçli sistemler: Düsük basinçli karbondioksit sistemleri, yüksek miktarda söndürücüye ihtiyaç duyulan mekanlarda toplam hacim koruma uygulamalarinda kullanilir</li>
                                                <li>Yüksek basinçli sistemler: Yüksek basinçli karbon dioksit sistemleri, hem toplam hacim koruma, hem de lokal koruma uygulamalarinda kullanilmaktadir.</li>

                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Kullanıldığı Yerler</figcaption>
                                            <ul>
                                                <li>Güç aktarim odalari</li>
                                                <li>Jeneratör güç setleri</li>
                                                <li>Motor odalari</li>
                                                <li>Kayit odalari</li>
                                                <li>Kablo ve tesisat galerileri</li>
                                                <li>Elektrik dagitim odalari/merkezleri</li>
                                                <li>Bilgisayar odalari</li>
                                                <li>Yanici sivi depolama alanlari</li>
                                                <li>Boyama kabinleri</li>
                                                <li>Arsivler</li>
                                                <li>Basim Evleri</li>
                                                <li>Endüstriyel Firinlar</li>
                                                <li>Genis Ticari Yemek Pisirme Alanlari</li>
                                                <li>Ve daha birçok end. uygulamalar</li>

                                            </ul>
                                            <p>Karbondioksit (CO2) Gazli Söndürme Sisteminin Kullanilamayacagi Malzemeler
                                                Selüloz nitrat gibi oksijen ihtiva eden kimyasallar, sodium, potasyum, magnezyum, titanyum ve zirkonyum gibi reaktif metaller, metal hidratlar.
                                            </p>
                                        </figure>
                                        <figure>
                                            {/* <figcaption>Kullanildigi Yerler:</figcaption> */}
                                            <p>Gazlı söndürme sistemlerinde kullanılan en eski ve en yaygın gazdır. Lokal uygulamalarda kullanılan tek gazdır ve özellikle kimyasalların bulunduğu mahallerde en etkin çözüm olarak kullanılmaktadır. Soğutma etkisinden dolayı narin elektrikli ve elektronik cihazların bulunduğu mahallerde, ayrıca boğma etkisinden dolayı insanlı mahallarde kullanılması sakıncalıdır. CO2 gazı, söndürme yapılan ortamdaki bağıl oksijen yoğunlığunu, yangının sürmesi için gereken oksijen yoğunluğu seviyesinin altına düşürerek yangınları söndürmektedir.</p>
                                            <p>Karbondioksit; renksiz, kokusuz, elektrik iletkenligi olmayan, yangin söndürmek için uygun bir inert gazdir.</p>
                                            <p>Karbondioksit hava içerisindeki oksijeni ve/veya gaz fazindaki bir yakitin konsantrasyonunu yanmanin duracagi noktaya kadar düsürerek, yanmayi sogutarak söndürür.</p>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 3,
                                    name: 'AEROSOL YANGIN SÖNDÜRME SİSTEMLERİ',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/urn1.jpg'],
                                    content: '',
                                    link: '/product/7/3/aerosol_yangin_sondurme_sistemleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        {/* <figure>
                            <figcaption>Özellikler</figcaption>
                            <ul>
                                <li>Ozona zarar vermez.</li>
                                <li>Fiziksel olarak söndürme yapar.</li>
                                <li>Lokal söndürme amaçli kullanilir</li>
                                <li>Insan bulunan mekanlarda kullanilmaz</li>
                                <li>Yeniden doldurma maliyeti düsük, yangin yaygin olarak bulunabilir</li>
                                <li>Bosalma süresi 60-120 saniyedir. ( lokal sistemler için 30 saniye)</li>
                                <li>Düsük basinçli sistemler: Düsük basinçli karbondioksit sistemleri, yüksek miktarda söndürücüye ihtiyaç duyulan mekanlarda toplam hacim koruma uygulamalarinda kullanilir</li>
                                <li>Yüksek basinçli sistemler: Yüksek basinçli karbon dioksit sistemleri, hem toplam hacim koruma, hem de lokal koruma uygulamalarinda kullanilmaktadir.</li>

                            </ul>
                        </figure>
                        <figure>
                            <figcaption>Kullanıldığı Yerler</figcaption>
                            <ul>
                                <li>Güç aktarim odalari</li>
                                <li>Jeneratör güç setleri</li>
                                <li>Motor odalari</li>
                                <li>Kayit odalari</li>
                                <li>Kablo ve tesisat galerileri</li>
                                <li>Elektrik dagitim odalari/merkezleri</li>
                                <li>Bilgisayar odalari</li>
                                <li>Yanici sivi depolama alanlari</li>
                                <li>Boyama kabinleri</li>
                                <li>Arsivler</li>
                                <li>Basim Evleri</li>
                                <li>Endüstriyel Firinlar</li>
                                <li>Genis Ticari Yemek Pisirme Alanlari</li>
                                <li>Ve daha birçok end. uygulamalar</li>

                            </ul>
                            <p>Karbondioksit (CO2) Gazli Söndürme Sisteminin Kullanilamayacagi Malzemeler
                                Selüloz nitrat gibi oksijen ihtiva eden kimyasallar, sodium, potasyum, magnezyum, titanyum ve zirkonyum gibi reaktif metaller, metal hidratlar.
                            </p>
                        </figure> */}
                                        <figure>
                                            <p>
                                                DSPA.tr merkezi Hollanda’da bulunan yenilikçi ve lider bir aerosol üreticisi DSPA.nl 'nin Türkiye temsilcisidir. Aerosol söndürücüleri yangını bastırma ve söndürme amaçlı kullanılmaktadır. Ürünlerimiz dünya çapında birçok hükümet tarafından itfaiyelerde ilk müdahale ve bir çok endüstriyel alanda yaygın olarak kullanılmaktadır.

                                                DSPA kalite, güvenilirlik, hizmet ve yenilikçi ürün geliştirme amacı ile müşterilerine katma değer sağlamak için çalışmaktadır. İştirakciler ve stratejik ortaklarından oluşan bir ağ kullanarak, ürünlerimizi dünyanın her yerine ulaştırmaktayız. DSPA hedeflerinden biri, dünya çapını kapsayan bir dağıtıcı ağı oluşturmaktır. 2015 yılı itibari ile 65 ülkede satış ağını oluşturmuştur.
                                            </p>
                                            {/* <figcaption>Kullanildigi Yerler:</figcaption> */}
                                            {/* <p>Gazlı söndürme sistemlerinde kullanılan en eski ve en yaygın gazdır. Lokal uygulamalarda kullanılan tek gazdır ve özellikle kimyasalların bulunduğu mahallerde en etkin çözüm olarak kullanılmaktadır. Soğutma etkisinden dolayı narin elektrikli ve elektronik cihazların bulunduğu mahallerde, ayrıca boğma etkisinden dolayı insanlı mahallarde kullanılması sakıncalıdır. CO2 gazı, söndürme yapılan ortamdaki bağıl oksijen yoğunlığunu, yangının sürmesi için gereken oksijen yoğunluğu seviyesinin altına düşürerek yangınları söndürmektedir.</p>
                            <p>Karbondioksit; renksiz, kokusuz, elektrik iletkenligi olmayan, yangin söndürmek için uygun bir inert gazdir.</p>
                            <p>Karbondioksit hava içerisindeki oksijeni ve/veya gaz fazindaki bir yakitin konsantrasyonunu yanmanin duracagi noktaya kadar düsürerek, yanmayi sogutarak söndürür.</p> */}
                                            <figcaption>DSPA Aerosol Söndürücü sistemlerin avantajları;   </figcaption>
                                            DSPA Aerosol Söndürücü sistemlerin avantajları;

                                            Kolayca yangın algılama ve söndürme sistemlerine bağlanabilir.
                                            Yangının yayılmasını ve dolayısıyla dolaylı maliyetlerin sınırlandırılmasını sağlayarak mümkün olduğunca yangını kaynağına yakın bir koruma sağlar, bir bölme ya da nesnenin içine monte edilebilir.
                                            En az 15 yıllık bir servis ömrü vardır ve akredite edilmiştir.
                                            Basınç içermez, basınca neden olmaz, dolayısı ile kolay taşınabilir ve monte edilebilir.
                                            Aktivasyon tam otomatik ve manuel (bağımsız) olarak yapılabilir.
                                            Elektrik enerjisi veya güç kaynağı kullanmadan çalışabilir.
                                            Portatiftir ve yerden tasarruf sağlar.
                                            Rina Sertifikasyonuna sahiptir. 98%. Ortam sıcaklığı kadar% 98 nem ile -50 ° C ile
                                            + 75 ° C arasında değişen ortamlarda kullanılabilir.
                                            Devam eden (üretim) işlemlerinde sistemsel herhangi bir kesinti olmadan, kolay ve hızlı monte edilebilir.
                                            Diğer geleneksel gazlı ve tozlu sistemlere göre bakım gerektirmez.
                                            İnsanlara ve hayvanlara zararı yoktur.
                                            Çevre ve ozon dostu bir üründür. Ozon tüketme potansiyeli (sıfır) yoktur.
                                            Oksijen seviyelerini tüketmez.
                                            Temini ve montajı kolaydır. Taşınabilir ve yer değiştirilebilir.
                                            Zaman ve paradan tasarruf sağlar.
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 4,
                                    name: 'KÖPÜKLÜ SÖNDÜRME SİSTEMLERİ',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/kopuklu.jpeg'],
                                    content: '',
                                    link: '/product/7/4/kopuklu_sondurme_sistemleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        {/* <figure>
                            <figcaption>Özellikler</figcaption>
                            <ul>
                                <li>Ozona zarar vermez.</li>
                                <li>Fiziksel olarak söndürme yapar.</li>
                                <li>Lokal söndürme amaçli kullanilir</li>
                                <li>Insan bulunan mekanlarda kullanilmaz</li>
                                <li>Yeniden doldurma maliyeti düsük, yangin yaygin olarak bulunabilir</li>
                                <li>Bosalma süresi 60-120 saniyedir. ( lokal sistemler için 30 saniye)</li>
                                <li>Düsük basinçli sistemler: Düsük basinçli karbondioksit sistemleri, yüksek miktarda söndürücüye ihtiyaç duyulan mekanlarda toplam hacim koruma uygulamalarinda kullanilir</li>
                                <li>Yüksek basinçli sistemler: Yüksek basinçli karbon dioksit sistemleri, hem toplam hacim koruma, hem de lokal koruma uygulamalarinda kullanilmaktadir.</li>

                            </ul>
                        </figure>
                        <figure>
                            <figcaption>Kullanıldığı Yerler</figcaption>
                            <ul>
                                <li>Güç aktarim odalari</li>
                                <li>Jeneratör güç setleri</li>
                                <li>Motor odalari</li>
                                <li>Kayit odalari</li>
                                <li>Kablo ve tesisat galerileri</li>
                                <li>Elektrik dagitim odalari/merkezleri</li>
                                <li>Bilgisayar odalari</li>
                                <li>Yanici sivi depolama alanlari</li>
                                <li>Boyama kabinleri</li>
                                <li>Arsivler</li>
                                <li>Basim Evleri</li>
                                <li>Endüstriyel Firinlar</li>
                                <li>Genis Ticari Yemek Pisirme Alanlari</li>
                                <li>Ve daha birçok end. uygulamalar</li>

                            </ul>
                            <p>Karbondioksit (CO2) Gazli Söndürme Sisteminin Kullanilamayacagi Malzemeler
                                Selüloz nitrat gibi oksijen ihtiva eden kimyasallar, sodium, potasyum, magnezyum, titanyum ve zirkonyum gibi reaktif metaller, metal hidratlar.
                            </p>
                        </figure> */}
                                        <figure>
                                            <p>
                                                Sentetik bazlı, protein bazlı, film oluşturucu ve alkole dayanıklı gibi çeşitleri bulunan “köpük” bazlı söndürme sistemleri; daha çok yakıt ve yanıcı sıvı yangınlarının söndürülmesinde kullanılmaktadır.

                                                Manuel yahut otomatik olarak aktive edilebilen sistem çeşitleri vardır. Köpük ajanı, genellikle sprinkler sistemlerinde, yangın dolaplarında, köpük monitörlerinde, köpük jeneratör sistemlerinde kullanılmaktadır.

                                                Tümü onaylı olan ve tarafımızdan tedarik edilen, montajı ve periyodik bakımları gerçekleştirilen köpük sistemi malzemelerinin tasarımında ise, VDS – Almanya onaylı hidrolik hesap programları kullanılmakta ve müşteriye tüm doneler teslim edilmektedir.
                                            </p>
                                            {/* <figcaption>Kullanildigi Yerler:</figcaption> */}
                                            {/* <p>Gazlı söndürme sistemlerinde kullanılan en eski ve en yaygın gazdır. Lokal uygulamalarda kullanılan tek gazdır ve özellikle kimyasalların bulunduğu mahallerde en etkin çözüm olarak kullanılmaktadır. Soğutma etkisinden dolayı narin elektrikli ve elektronik cihazların bulunduğu mahallerde, ayrıca boğma etkisinden dolayı insanlı mahallarde kullanılması sakıncalıdır. CO2 gazı, söndürme yapılan ortamdaki bağıl oksijen yoğunlığunu, yangının sürmesi için gereken oksijen yoğunluğu seviyesinin altına düşürerek yangınları söndürmektedir.</p>
                            <p>Karbondioksit; renksiz, kokusuz, elektrik iletkenligi olmayan, yangin söndürmek için uygun bir inert gazdir.</p>
                            <p>Karbondioksit hava içerisindeki oksijeni ve/veya gaz fazindaki bir yakitin konsantrasyonunu yanmanin duracagi noktaya kadar düsürerek, yanmayi sogutarak söndürür.</p> */}
                                            <figcaption>Başlıca Kullanım Alanları:   </figcaption>
                                            <ul>
                                                <li>İlaç, gıda, lastik, baskı, akaryakıt üretim ve depolama tesisleri</li>
                                                <li>Yanıcı – parlayıcı kimyasal madde üretim tesisleri ve depoları</li>
                                                <li>Uçak ve helikopter hangarları</li>
                                                <li>Akaryakıt dolum istasyonları</li>
                                                <li>Deniz sektörü kargo tankları ve kimyasal madde tankları</li>
                                                <li>Tersanelerde iskele ve marinalar</li>
                                                <li>Rafineriler</li>
                                                <li>Savunma sanayi açık-kapalı depolar ve dolum istasyonları</li>
                                                <li>Orman yangınları</li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                            ]
    }
},
{
    category: {
        id: 12,
            name: 'ACİL AYDINLATMA VE YÖNLENDİRME ARMATÜRLERİ',
                imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/image-269.png'],
                    content: 'Sıva altı & sıva üstü acil aydınlatma armatürleri , tek yönlü veya çift yönlü acil yönlendirme(EXİT ) armatürleri.',
                        link: '/category/acil_aydinlatma_ve_yonlendirme_armaturleri/12/subcategories',
                            subcategories: [
                                {
                                    id: 0,
                                    name: 'SG002 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg002-cift-yonlu-acil-yonlendirme-armaturu__3774-1.jpg'],
                                    content: '',
                                    link: '/product/12/0/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Exit Çıkış Sağ-Sol Ok</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 1,
                                    name: 'SG003 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg139-tek-yonlu-acil-aydinlatma-armaturu__3817-1.jpg'],
                                    content: '',
                                    link: '/product/12/1/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Exit Çıkış Sağ-Sol Ok</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 2,
                                    name: 'SG004 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg004-cift-yonlu-acil-yonlendirme-armaturu__3772-1.jpg'],
                                    content: '',
                                    link: '/product/12/2/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Sağ-Sol Ok</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 3,
                                    name: 'SG005 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg005-cift-yonlu-acil-yonlendirme-armaturu__3771-1.jpg'],
                                    content: '',
                                    link: '/product/12/3/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Exit Çıkış Aşağı Ok</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 5,
                                    name: 'SG007 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg007-cift-yonlu-acil-yonlendirme-armaturu__3767-1.jpg'],
                                    content: '',
                                    link: '/product/12/5/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Exit Çıkış Aşağı Ok</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 6,
                                    name: 'SG010 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg010-cift-yonlu-acil-yonlendirme-armaturu__3766-1.jpg'],
                                    content: '',
                                    link: '/product/12/6/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Bay WC</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 7,
                                    name: 'SG011 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg011-cift-yonlu-acil-yonlendirme-armaturu__3765-1.jpg'],
                                    content: '',
                                    link: '/product/12/7/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Bayan WC</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 8,
                                    name: 'SG012 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/fotomat-sg012-isikli-wc-yonlendirme-tabelasi-levhasi__1484630227065435.webp'],
                                    content: '',
                                    link: '/product/12/8/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Bayan Bay WC</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 28,
                                    name: 'SG125 Tek Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg125-tek-yonlu-acil-yonlendirme-armaturu__3770-1.jpg'],
                                    content: '',
                                    link: '/product/12/28/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Yeşil led ampüllü şarjlı</li>
                                                <li>Tek yönlü</li>
                                                <li>Sürekli yanma, enerji kesintisinde 3 saat çalışma özelliği</li>
                                                <li>Sol yön</li>
                                                <li>Voltaj:220V AC</li>
                                                <li>Parlaklık: 56-130 cd/m2</li>
                                                <li>Watt:3W</li>
                                                <li>IP:20</li>
                                                <li>Koli adeti:10</li>
                                                <li>Sertifika:CE</li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 29,
                                    name: 'SG126 Tek Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg126-tek-yonlu-acil-aydinlatma-armaturu__3815-1.jpg'],
                                    content: '',
                                    link: '/product/12/29/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Yeşil led ampüllü şarjlı</li>
                                                <li>Tek yönlü</li>
                                                <li>Sürekli yanma, enerji kesintisinde 3 saat çalışma özelliği</li>
                                                <li>Sağ yön</li>
                                                <li>Voltaj:220V AC</li>
                                                <li>Parlaklık: 56-130 cd/m2</li>
                                                <li>Watt:3W</li>
                                                <li>IP:20</li>
                                                <li>Koli adeti:10</li>
                                                <li>Sertifika:CE</li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 30,
                                    name: 'SG127 Tek Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg127-tek-yonlu-acil-aydinlatma-armaturu__4934-1.jpg'],
                                    content: '',
                                    link: '/product/12/30/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Yeşil led ampüllü şarjlı</li>
                                                <li>Tek yönlü</li>
                                                <li>Sürekli yanma, enerji kesintisinde 3 saat çalışma özelliği</li>
                                                <li>Aşağı yön </li>
                                                <li>Voltaj:220V AC</li>
                                                <li>Parlaklık: 56-130 cd/m2</li>
                                                <li>Watt:3W</li>
                                                <li>IP:20</li>
                                                <li>Koli adeti:10</li>
                                                <li>Sertifika:CE</li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 31,
                                    name: 'SG128 Tek Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg128-tek-yonlu-acil-aydinlatma-armaturu__3816-1.jpg'],
                                    content: '',
                                    link: '/product/12/31/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Yeşil led ampüllü şarjlı</li>
                                                <li>Tek yönlü</li>
                                                <li>Sürekli yanma, enerji kesintisinde 3 saat çalışma özelliği</li>
                                                <li>Yukarı yön</li>
                                                <li>Voltaj:220V AC</li>
                                                <li>Parlaklık: 56-130 cd/m2</li>
                                                <li>Watt:3W</li>
                                                <li>IP:20</li>
                                                <li>Koli adeti:10</li>
                                                <li>Sertifika:CE</li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 32,
                                    name: 'SG139 Tek Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg139-tek-yonlu-acil-aydinlatma-armaturu__3817-1 (1).jpg'],
                                    content: '',
                                    link: '/product/12/32/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Yeşil led ampüllü şarjlı</li>
                                                <li>Tek yönlü</li>
                                                <li>Sürekli yanma, enerji kesintisinde 3 saat çalışma özelliği</li>
                                                <li>Exit çıkış yön</li>
                                                <li>Voltaj:220V AC</li>
                                                <li>Parlaklık: 56-130 cd/m2</li>
                                                <li>Watt:3W</li>
                                                <li>IP:20</li>
                                                <li>Koli adeti:10</li>
                                                <li>Sertifika:CE</li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 9,
                                    name: 'SG155 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg155-cift-yonlu-acil-yonlendirme-armaturu__3763-1.jpg'],
                                    content: '',
                                    link: '/product/12/9/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Merdivenli Sağ-Sol Ok</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 10,
                                    name: 'SG156 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg156-cift-yonlu-acil-yonlendirme-armaturu__3762-1.jpg'],
                                    content: '',
                                    link: '/product/12/10/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Merdivenli Aşağı Ok</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 11,
                                    name: 'SG157 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg157-cift-yonlu-acil-yonlendirme-armaturu__3761-1.jpg'],
                                    content: '',
                                    link: '/product/12/11/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Merdivenli Yukarı Ok</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 12,
                                    name: 'SG013 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg013-cift-yonlu-acil-yonlendirme-armaturu__3760-1.jpg'],
                                    content: '',
                                    link: '/product/12/12/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Sağ-Sol Ok</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 13,
                                    name: 'SG014 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg014-cift-yonlu-acil-yonlendirme-armaturu__3759-1.jpg'],
                                    content: '',
                                    link: '/product/12/13/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Aşağı Ok</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 14,
                                    name: 'SG015 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg015-cift-yonlu-acil-yonlendirme-armaturu__3758-1.jpg'],
                                    content: '',
                                    link: '/product/12/14/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Yukarı Ok</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 15,
                                    name: 'SG016 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg016-cift-yonlu-acil-yonlendirme-armaturu__3757-1.jpg'],
                                    content: '',
                                    link: '/product/12/15/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Exit</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 16,
                                    name: 'SG017 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg017-cift-yonlu-acil-yonlendirme-armaturu__3756-1.jpg'],
                                    content: '',
                                    link: '/product/12/16/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Çıkış</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 17,
                                    name: 'SG018 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg018-cift-yonlu-acil-yonlendirme-armaturu__3809-1.jpg'],
                                    content: '',
                                    link: '/product/12/17/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Acil Çıkış </li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 18,
                                    name: 'SG019 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg019-cift-yonlu-acil-yonlendirme-armaturu__3810-1.jpg'],
                                    content: '',
                                    link: '/product/12/18/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Merdivenli Yukarı Ok</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 19,
                                    name: 'SG020 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg020-cift-yonlu-acil-yonlendirme-armaturu__3811-1.jpg'],
                                    content: '',
                                    link: '/product/12/19/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Merdivenli Aşağı Ok</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 20,
                                    name: 'SG021 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg021-cift-yonlu-acil-yonlendirme-armaturu__3812-1.jpg'],
                                    content: '',
                                    link: '/product/12/20/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Merdivenli Sağ-Sol Ok</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 21,
                                    name: 'SG022 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg022-cift-yonlu-acil-yonlendirme-armaturu__3813-1.jpg'],
                                    content: '',
                                    link: '/product/12/21/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Sağ-Sol Ok</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 22,
                                    name: 'SG023 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg023-cift-yonlu-acil-yonlendirme-armaturu__4953-1 (1).jpg'],
                                    content: '',
                                    link: '/product/12/22/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Acil Çıkış Sağ-Sol Ok</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 23,
                                    name: 'SG024 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg024-cift-yonlu-acil-yonlendirme-armaturu__4954-1.jpg'],
                                    content: '',
                                    link: '/product/12/23/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Acil Çıkış Aşağı Ok</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 24,
                                    name: 'SG025 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg025-cift-yonlu-acil-yonlendirme-armaturu__4955-1.jpg'],
                                    content: '',
                                    link: '/product/12/24/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Acil Çıkış Yukarı Ok</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 25,
                                    name: 'SG160 Çift Yönlü Acil Yönlendirme Armatürü',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg160-cift-yonlu-acil-yonlendirme-armaturu__3814-1.jpg'],
                                    content: '',
                                    link: '/product/12/25/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
                                                <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
                                                <li>Asma halkaları ile tavana monte edilir. </li>
                                                <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
                                                <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
                                                <li>Çift yönlüdür.</li>
                                                <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
                                            <ul>
                                                <li>6 x LED - Yeşil LED ampül şarjlı</li>
                                                <li>Çift Yönlü</li>
                                                <li>Yangın Çıkış</li>
                                                <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
                                                <li>Güç Tüketimi : 3W </li>
                                                <li>Besleme Gerilimi : 220V AC</li>
                                                <li>IP20</li>
                                                <li>Alüminyum Kasa, Cam Yüzey</li>
                                                <li>Güç/Şarj/Hata Göstergeli</li>
                                                <li>Test Butonu</li>
                                                <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
                                                <li>Parlaklık:56-130 cd/m2</li>
                                                <li>Sertifikalar: CE </li>
                                                <li>Koli Adeti:20 </li>
                                            </ul>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 26,
                                    name: 'Versalite Acil Aydınlatma Armatürü',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/image-271.png',
                                        process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/image-268.png'
                                    ],
                                    content: '',
                                    link: '/product/12/26/acil_aydinlatma_ve_yonlendirme_armaturleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <p>Versalite acil aydınlatma armatürü, sabit ve seyyar olarak kullanılabilir. Uzun koridorlarda,
                                                merdiven boşluklarında, otopark ve fabrikalar gibi geniş kullanım alanlarında uygun
                                                aydınlatma sağlar. Spot aydınlatmalarda armatür sadece elektrik kesintisinde devreye
                                                girer. Cihaz üzerinde şebeke geriliminin varlığını gösteren, akünün şarj durumunu
                                                gösteren LED bulunur. Tüm modellerde bakım gerektirmeyen kuru tip akü kullanılır.
                                                Şebeke besleme gerilimi standart 220 volt AC, +/- %8 dr. Bataryalı modellerde ilk
                                                kullanımda şarj süresi en az 24 saattir.
                                            </p>
                                        </figure>
                                        <figure>
                                            <figcaption>Armatürler</figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Yanma Durumu</th>
                                                        <th>Kullanım Şekli</th>
                                                        <th>Lamba</th>
                                                        <th>Akü</th>
                                                        <th>Süre</th>
                                                        <th>Boyutlar (En-Boy-Yükseklik)</th>
                                                        <th>Ağırlık</th>
                                                        <th>Ip Sınıfı</th>
                                                        <th>İzolasyon Sınıfı</th>
                                                        <th>Çalışma Sıcaklığı</th>
                                                        <th>Güç Tüketimi</th>
                                                        <th>Çalışma Voltajı</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>VSL-201-18</td>
                                                        <td>Kesintide Yanan</td>
                                                        <td>Duvara Montaj</td>
                                                        <td>2x18 LED</td>
                                                        <td>12V/1,2AH</td>
                                                        <td>Kesintide 60</td>
                                                        <td>95x352x300mm</td>
                                                        <td>3,8 kg</td>
                                                        <td>40</td>
                                                        <td>I</td>
                                                        <td>-16/+45C</td>
                                                        <td>3,5w</td>
                                                        <td>220-240</td>
                                                    </tr>
                                                    <tr>
                                                        <td>VSL-203-18</td>
                                                        <td>Kesintide Yanan</td>
                                                        <td>Duvara Montaj</td>
                                                        <td>2x18 LED</td>
                                                        <td>12V/2,3AH</td>
                                                        <td>Kesintide 180</td>
                                                        <td>95x352x300mm</td>
                                                        <td>4,2 kg</td>
                                                        <td>40</td>
                                                        <td>I</td>
                                                        <td>-16/+45C</td>
                                                        <td>3,5w</td>
                                                        <td>220-240</td>
                                                    </tr>
                                                    <tr>
                                                        <td>VSL-201-36</td>
                                                        <td>Kesintide Yanan</td>
                                                        <td>Duvara Montaj</td>
                                                        <td>2x36 LED</td>
                                                        <td>12V/1,2AH</td>
                                                        <td>Kesintide 60</td>
                                                        <td>95x352x300mm</td>
                                                        <td>4,2 kg</td>
                                                        <td>40</td>
                                                        <td>I</td>
                                                        <td>-16/+45C</td>
                                                        <td>3,5w</td>
                                                        <td>220-240</td>
                                                    </tr>
                                                    <tr>
                                                        <td>VSL-203-36</td>
                                                        <td>Kesintide Yanan</td>
                                                        <td>Duvara Montaj</td>
                                                        <td>2x36 LED</td>
                                                        <td>12V/4,5AH</td>
                                                        <td>Kesintide 180</td>
                                                        <td>95x352x300mm</td>
                                                        <td>4,75 kg</td>
                                                        <td>40</td>
                                                        <td>I</td>
                                                        <td>-16/+45C</td>
                                                        <td>3,5w</td>
                                                        <td>220-240</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Yanma Durumu</th>
                                                        <th>Kullanım Şekli</th>
                                                        <th>Lamba</th>
                                                        <th>Işıklı Yüzey</th>
                                                        <th>Süre</th>
                                                        <th>Boyutlar (En-Boy-Yükseklik)</th>
                                                        <th>Ağırlık</th>
                                                        <th>Ip Sınıfı</th>
                                                        <th>İzolasyon Sınıfı</th>
                                                        <th>Çalışma Sıcaklığı</th>
                                                        <th>Güç Tüketimi</th>
                                                        <th>Çalışma Voltajı</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>VS-2201</td>
                                                        <td>Kesintide Yanan</td>
                                                        <td>Duvara Montaj</td>
                                                        <td>2x20 wat</td>
                                                        <td>Hareketli</td>
                                                        <td>Kesintide 60</td>
                                                        <td>110x370x412mm</td>
                                                        <td>7,45 kg</td>
                                                        <td>40</td>
                                                        <td>I</td>
                                                        <td>-16/+45C</td>
                                                        <td>10w</td>
                                                        <td>220-240</td>
                                                    </tr>
                                                    <tr>
                                                        <td>VS-2202</td>
                                                        <td>Kesintide Yanan</td>
                                                        <td>Duvara Montaj</td>
                                                        <td>2x20 wat</td>
                                                        <td>Hareketli</td>
                                                        <td>Kesintide 120</td>
                                                        <td>110x370x412mm</td>
                                                        <td>7,45 kg</td>
                                                        <td>40</td>
                                                        <td>I</td>
                                                        <td>-16/+45C</td>
                                                        <td>10w</td>
                                                        <td>220-240</td>
                                                    </tr>
                                                    <tr>
                                                        <td>VS-2203</td>
                                                        <td>Kesintide Yanan</td>
                                                        <td>Duvara Montaj</td>
                                                        <td>2x20 wat</td>
                                                        <td>Hareketli</td>
                                                        <td>Kesintide 180</td>
                                                        <td>110x370x412mm</td>
                                                        <td>9 kg</td>
                                                        <td>40</td>
                                                        <td>I</td>
                                                        <td>-16/+45C</td>
                                                        <td>10w</td>
                                                        <td>220-240</td>
                                                    </tr>
                                                    <tr>
                                                        <td>VS-2351</td>
                                                        <td>Kesintide Yanan</td>
                                                        <td>Duvara Montaj</td>
                                                        <td>2x35 wat</td>
                                                        <td>Hareketli</td>
                                                        <td>Kesintide 60</td>
                                                        <td>110x370x412mm</td>
                                                        <td>7,45 kg</td>
                                                        <td>40</td>
                                                        <td>I</td>
                                                        <td>-16/+45C</td>
                                                        <td>10w</td>
                                                        <td>220-240</td>
                                                    </tr>
                                                    <tr>
                                                        <td>VS-2352</td>
                                                        <td>Kesintide Yanan</td>
                                                        <td>Duvara Montaj</td>
                                                        <td>2x35 wat</td>
                                                        <td>Hareketli</td>
                                                        <td>Kesintide 120</td>
                                                        <td>110x370x412mm</td>
                                                        <td>9 kg</td>
                                                        <td>40</td>
                                                        <td>I</td>
                                                        <td>-16/+45C</td>
                                                        <td>10w</td>
                                                        <td>220-240</td>
                                                    </tr>
                                                    <tr>
                                                        <td>VS-2353</td>
                                                        <td>Kesintide Yanan</td>
                                                        <td>Duvara Montaj</td>
                                                        <td>2x35 wat</td>
                                                        <td>Hareketli</td>
                                                        <td>Kesintide 180</td>
                                                        <td>180x370x412mm</td>
                                                        <td>14 kg</td>
                                                        <td>40</td>
                                                        <td>I</td>
                                                        <td>-16/+45C</td>
                                                        <td>50w</td>
                                                        <td>220-240</td>
                                                    </tr>
                                                    <tr>
                                                        <td>VS-2501</td>
                                                        <td>Kesintide Yanan</td>
                                                        <td>Duvara Montaj</td>
                                                        <td>2x50 wat</td>
                                                        <td>Hareketli</td>
                                                        <td>Kesintide 60</td>
                                                        <td>110x370x412mm</td>
                                                        <td>9 kg</td>
                                                        <td>40</td>
                                                        <td>I</td>
                                                        <td>-16/+45C</td>
                                                        <td>10w</td>
                                                        <td>220-240</td>
                                                    </tr>
                                                    <tr>
                                                        <td>VS-2501.5</td>
                                                        <td>Kesintide Yanan</td>
                                                        <td>Duvara Montaj</td>
                                                        <td>2x50 wat</td>
                                                        <td>Hareketli</td>
                                                        <td>Kesintide 90</td>
                                                        <td>110x370x412mm</td>
                                                        <td>11,8 kg</td>
                                                        <td>40</td>
                                                        <td>I</td>
                                                        <td>-16/+45C</td>
                                                        <td>10w</td>
                                                        <td>220-240</td>
                                                    </tr>
                                                    <tr>
                                                        <td>VS-2503</td>
                                                        <td>Kesintide Yanan</td>
                                                        <td>Duvara Montaj</td>
                                                        <td>2x50 wat</td>
                                                        <td>Hareketli</td>
                                                        <td>Kesintide 180</td>
                                                        <td>180x370x412mm</td>
                                                        <td>15 kg</td>
                                                        <td>40</td>
                                                        <td>I</td>
                                                        <td>-16/+45C</td>
                                                        <td>50w</td>
                                                        <td>220-240</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                            ]
    }
}
    ,
{
    category: {
        id: 0,
            name: 'YANGIN DOLAPLARI',
                imgsrc: process.env.PUBLIC_URL + '/images/category/yanginDolabi.jpg',
                    content: 'EN 671-1 ve EN 671-2 Standardına uygun ve belgeli , CE ve İSO belgeli, çeşitli modellerde bina içi ve bina dışı kullanıma uygun, yarı sert veya yassı hortumlu yangın dolapları.',
                        link: '/category/yangindolaplari/0/subcategories',
                            subcategories: [
                                {
                                    id: 0,
                                    name: 'DEKORATİF CAM KAPAKLI YANGIN DOLAPLARI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/dekoratif_cam_kapakli_yangin_dolaplari/image-041.png',
                                        process.env.PUBLIC_URL + '/images/products/dekoratif_cam_kapakli_yangin_dolaplari/image-042.png',
                                        process.env.PUBLIC_URL + '/images/products/dekoratif_cam_kapakli_yangin_dolaplari/image-043.png',
                                    ],
                                    content: '',
                                    link: '/product/0/0/dekoratif_cam_kapakli_yangin_dolaplari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>

                                        <figure>
                                            <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>10mm orfice</li>
                                                <li>Ayarlanabilir (jet, spray, shut-off)</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
                                                <li>12 ATM çalışma basıncı, </li>
                                                <li>36 ATM patlama basıncı. </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Vana</figcaption>
                                            <ul>
                                                <li>2" köşe vanalı</li>
                                                <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Makara</figcaption>
                                            <ul>
                                                <li>Özel profilli çelik sactan mamül</li>
                                                <li>Elektrostatik toz boyalı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Kabinet</figcaption>
                                            <ul>
                                                <li>1,20mm DKP sacdan mamül</li>
                                                <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
                                                <li>İstenilen RAL kodunda elektrostatik fırın boyalı</li>
                                                <li>Alttan veya üstten / sağdan veya soldan tesisat girişine uygun</li>
                                                <li>Tamamı 4mm temper cam kapaklı</li>
                                                <li>Sıva altı - yarı gömme - sıva üstü seçenekleri</li>
                                                <li>Özel mıknatıslı kilit sistemli, demonte kapaklı</li>
                                                <li>TS 862 EN 3-7 e uygun ,TSE ve CE belgeli 6 kg kuru
                                                    kimyevi tozlu yangın söndürme cihazı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>DEKORATİF CAM KAPAKLI YANGIN DOLAPLARI</figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Hortum</th>
                                                        <th>Ölçüler</th>
                                                        <th>Kapak</th>
                                                        <th>Model</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>DT_001_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>750x900x220 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_002_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>750x900x220 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_003_YD</td>
                                                        <td>30 mt.</td>
                                                        <td>750x900x220 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_004_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>750x900x220 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_005_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>750x900x220 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_006_YD</td>
                                                        <td>30 mt.</td>
                                                        <td>750x900x220 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 1,
                                    name: 'TÜP BÖLMELİ YANGIN DOLAPLARI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/tup_bolmeli_yangin_dolaplari/image-057.png',
                                        process.env.PUBLIC_URL + '/images/products/tup_bolmeli_yangin_dolaplari/image-058.png',
                                        process.env.PUBLIC_URL + '/images/products/tup_bolmeli_yangin_dolaplari/image-051.png',
                                        process.env.PUBLIC_URL + '/images/products/tup_bolmeli_yangin_dolaplari/image-052.png',
                                    ],
                                    content: '',
                                    link: '/product/0/1/tup_bolmeli_yangin_dolaplari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>10mm orfice</li>
                                                <li>Ayarlanabilir (jet, spray, shut-off)</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
                                                <li>12 ATM çalışma basıncı, </li>
                                                <li>36 ATM patlama basıncı. </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Vana</figcaption>
                                            <ul>
                                                <li>2" köşe vanalı</li>
                                                <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Makara</figcaption>
                                            <ul>
                                                <li>Özel profilli çelik sactan mamül</li>
                                                <li>Elektrostatik toz boyalı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Kabinet</figcaption>
                                            <ul>
                                                <li>1,20mm DKP sacdan mamül</li>
                                                <li>İstenilen RAL kodunda elektrostatik fırın boyalı</li>
                                                <li>Üstten / yandan tesisat girişine uygun</li>
                                                <li>Makara ve tüp bölmesi 4 mm Temperli cam kapaklı</li>
                                                <li>Sıva altı - yarı gömme - sıva üstü seçenekleri</li>
                                                <li>Özel kilit sistemli, Demonte kapaklı</li>
                                                <li>TS 862 EN 3-7 e uygun ,TSE ve CE belgeli 6 kg kuru
                                                    kimyevi tozlu yangın söndürme cihazı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>SIVA ALTI TÜP BÖLMELİ YANGIN DOLAPLARI</figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Hortum</th>
                                                        <th>Ölçüler</th>
                                                        <th>Kapak</th>
                                                        <th>Model</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>DT_007_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>980x710x220 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_008_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>980x710x220 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_009_YD</td>
                                                        <td>30 mt.</td>
                                                        <td>980x710x220 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 2,
                                    name: 'SIVA ALTI SAC KAPAKLI TÜP BÖLMELİ YANGIN DOLAPLARI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/siva_alti_tup_bolmeli_yangin_dolaplari/image-061.png',
                                        process.env.PUBLIC_URL + '/images/products/siva_alti_tup_bolmeli_yangin_dolaplari/image-060.png',
                                        process.env.PUBLIC_URL + '/images/products/siva_alti_tup_bolmeli_yangin_dolaplari/image-059.png',
                                    ],
                                    content: '',
                                    link: '/product/0/2/sıva_alti_sac_kapakli_tup_bolmeli_yangin_dolaplari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>10mm orfice</li>
                                                <li>Ayarlanabilir (jet, spray, shut-off)</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
                                                <li>12 ATM çalışma basıncı, </li>
                                                <li>36 ATM patlama basıncı. </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Vana</figcaption>
                                            <ul>
                                                <li>2" köşe vanalı</li>
                                                <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Makara</figcaption>
                                            <ul>
                                                <li>Özel profilli çelik sactan mamül</li>
                                                <li>Elektrostatik toz boyalı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Kabinet</figcaption>
                                            <ul>
                                                <li>1,20mm DKP sacdan mamül</li>
                                                <li>İstenilen RAL kodunda elektrostatik fırın boyalı</li>
                                                <li>Üstten / yandan tesisat girişine uygun</li>
                                                <li>Makara tarafı sac/tüp bölmesi tarafı isteğe göre 4 mm temperli cam kapaklı</li>
                                                <li>Sıva altı - yarı gömme - sıva üstü seçenekleri</li>
                                                <li>Özel kilit sistemli, Demonte kapaklı</li>
                                                <li>TS 862 EN 3-7 e uygun ,TSE ve CE belgeli 6 kg kuru
                                                    kimyevi tozlu yangın söndürme cihazı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>SIVA ALTI SAC KAPAKLI TÜP BÖLMELİ YANGIN DOLAPLARI</figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Hortum</th>
                                                        <th>Ölçüler</th>
                                                        <th>Kapak</th>
                                                        <th>Model</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>DT_010_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>980x710x220 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_011_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>980x710x220 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_012_YD</td>
                                                        <td>30 mt.</td>
                                                        <td>980x710x220 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 3,
                                    name: 'SIVA ÜSTÜ TÜP BÖLMELİ YANGIN DOLAPLARI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/siva_ustu_tup_bolmeli/image-068.png',
                                        process.env.PUBLIC_URL + '/images/products/siva_ustu_tup_bolmeli/image-067.png',
                                        process.env.PUBLIC_URL + '/images/products/siva_ustu_tup_bolmeli/image-069.png',
                                        process.env.PUBLIC_URL + '/images/products/siva_ustu_tup_bolmeli/image-070.png',
                                    ],
                                    content: '',
                                    link: '/product/0/3/sıva_usut_tup_bolmeli_yangin_dolaplari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>10mm orfice</li>
                                                <li>Ayarlanabilir (jet, spray, shut-off)</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
                                                <li>12 ATM çalışma basıncı, </li>
                                                <li>36 ATM patlama basıncı. </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Vana</figcaption>
                                            <ul>
                                                <li>2" köşe vanalı</li>
                                                <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Makara</figcaption>
                                            <ul>
                                                <li>Özel profilli çelik sactan mamül</li>
                                                <li>Elektrostatik toz boyalı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Kabinet</figcaption>
                                            <ul>
                                                <li>1,20mm DKP sacdan mamül</li>
                                                <li>İstenilen RAL kodunda elektrostatik fırın boyalı</li>
                                                <li>Üstten / yandan tesisat girişine uygun</li>
                                                <li>Komple sac veya cam kapaklı</li>
                                                <li>Sıva altı - yarı gömme - sıva üstü seçenekleri</li>
                                                <li>Özel kilit sistemli, Demonte kapaklı</li>
                                                <li>TS 862 EN 3-7 e uygun ,TSE ve CE belgeli 6 kg kuru
                                                    kimyevi tozlu yangın söndürme cihazı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>SIVA ÜSTÜ TÜP BÖLMELİ YANGIN DOLAPLARI</figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Hortum</th>
                                                        <th>Ölçüler</th>
                                                        <th>Kapak</th>
                                                        <th>Model</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>DT_013_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>1020x750x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_014_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>1020x750x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_015_YD</td>
                                                        <td>30 mt.</td>
                                                        <td>1020x750x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_016_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>1020x750x240 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_017_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>1020x750x240 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_018_YD</td>
                                                        <td>35 mt.</td>
                                                        <td>1020x750x220 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 4,
                                    name: 'TÜP ALTTA YATAY CAMLI TEK KAPAKLI YANGIN DOLAPLARI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/tup_altta_yatay_camli_tek_kapakli_yangin_dolabi/image-078.png',
                                        process.env.PUBLIC_URL + '/images/products/tup_altta_yatay_camli_tek_kapakli_yangin_dolabi/image-077.png',
                                        process.env.PUBLIC_URL + '/images/products/tup_altta_yatay_camli_tek_kapakli_yangin_dolabi/image-086.png',
                                    ],
                                    content: '',
                                    link: '/product/0/4/tup_altta_yatay_camli_tek_kapakli_yangin_dolabi',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>10mm orfice</li>
                                                <li>Ayarlanabilir (jet, spray, shut-off)</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
                                                <li>12 ATM çalışma basıncı, </li>
                                                <li>36 ATM patlama basıncı. </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Vana</figcaption>
                                            <ul>
                                                <li>2" köşe vanalı</li>
                                                <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Makara</figcaption>
                                            <ul>
                                                <li>Özel profilli çelik sactan mamül</li>
                                                <li>Elektrostatik toz boyalı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Kabinet</figcaption>
                                            <ul>
                                                <li>1,20mm DKP sacdan mamül</li>
                                                <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
                                                <li>İstenilen RAL kodunda elektrostatik fırın boyalı</li>
                                                <li>Alttan veya üstten / sağdan veya soldan tesisat girişine uygun</li>
                                                <li>Komple 4mm temperli cam kapaklı</li>
                                                <li>Sıva altı - yarı gömme - sıva üstü seçenekleri</li>
                                                <li>Özel kilit sistemli, Demonte kapaklı</li>
                                                <li>TS 862 EN 3-7 e uygun ,TSE ve CE belgeli 6 kg kuru
                                                    kimyevi tozlu yangın söndürme cihazı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>CAMLI TEK KAPAKLI TÜPLÜ YANGIN DOLAPLARI</figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Hortum</th>
                                                        <th>Ölçüler</th>
                                                        <th>Kapak</th>
                                                        <th>Model</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>DT_019_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>750x900x240 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_020_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>750x900x240 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_021_YD</td>
                                                        <td>30 mt.</td>
                                                        <td>750x900x240 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_022_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>710x900x220 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_023_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>710x900x220 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_024_YD</td>
                                                        <td>35 mt.</td>
                                                        <td>710x900x220 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 5,
                                    name: 'TÜP ALTTA YATAY SAC TEK KAPAKLI YANGIN DOLAPLARI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/tup_altta_yatay_tek_kapakli_yangin_dolabi/image-095.png',
                                        process.env.PUBLIC_URL + '/images/products/tup_altta_yatay_tek_kapakli_yangin_dolabi/image-088.png',
                                        process.env.PUBLIC_URL + '/images/products/tup_altta_yatay_tek_kapakli_yangin_dolabi/image-096.png',
                                    ],
                                    content: '',
                                    link: '/product/0/5/tup_altta_yatay_sac_tek_kapakli_yangin_dolabi',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>10mm orfice</li>
                                                <li>Ayarlanabilir (jet, spray, shut-off)</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
                                                <li>12 ATM çalışma basıncı, </li>
                                                <li>36 ATM patlama basıncı. </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Vana</figcaption>
                                            <ul>
                                                <li>2" köşe vanalı</li>
                                                <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Makara</figcaption>
                                            <ul>
                                                <li>Özel profilli çelik sactan mamül</li>
                                                <li>Elektrostatik toz boyalı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Kabinet</figcaption>
                                            <ul>
                                                <li>1,20mm DKP sacdan mamül</li>
                                                <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
                                                <li>İstenilen RAL kodunda elektrostatik fırın boyalı</li>
                                                <li>Alttan veya üstten / sağdan veya soldan tesisat girişine uygun</li>
                                                <li>Komple sac kapaklı</li>
                                                <li>Sıva altı - yarı gömme - sıva üstü seçenekleri</li>
                                                <li>Özel kilit sistemli, Demonte kapaklı</li>
                                                <li>TS 862 EN 3-7 e uygun ,TSE ve CE belgeli 6 kg kuru
                                                    kimyevi tozlu yangın söndürme cihazı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>KOMPLE SAC TEK KAPAKLI YANGIN DOLAPLARI</figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Hortum</th>
                                                        <th>Ölçüler</th>
                                                        <th>Kapak</th>
                                                        <th>Model</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>DT_025_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>750x900x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_026_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>750x900x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_027_YD</td>
                                                        <td>30 mt.</td>
                                                        <td>750x900x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_028_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>710x900x220 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_029_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>710x900x220 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_030_YD</td>
                                                        <td>35 mt.</td>
                                                        <td>710x900x220 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 6,
                                    name: 'TÜP YANDA DİKEY SIVA ALTI TEK KAPAKLI YANGIN DOLAPLARI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/tüp_yanda_dikey_tek_kapakli_yangin_dolaplari/image-098.png',
                                        process.env.PUBLIC_URL + '/images/products/tüp_yanda_dikey_tek_kapakli_yangin_dolaplari/image-097.png',
                                        process.env.PUBLIC_URL + '/images/products/tüp_yanda_dikey_tek_kapakli_yangin_dolaplari/image-099.png',
                                        process.env.PUBLIC_URL + '/images/products/tüp_yanda_dikey_tek_kapakli_yangin_dolaplari/image-100.png',
                                    ],
                                    content: '',
                                    link: '/product/0/6/tüp_yanda_dikey_siva_alti_tek_kapakli_yangin_dolaplari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>10mm orfice</li>
                                                <li>Ayarlanabilir (jet, spray, shut-off)</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
                                                <li>12 ATM çalışma basıncı, </li>
                                                <li>36 ATM patlama basıncı. </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Vana</figcaption>
                                            <ul>
                                                <li>2" köşe vanalı</li>
                                                <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Makara</figcaption>
                                            <ul>
                                                <li>Özel profilli çelik sactan mamül</li>
                                                <li>Elektrostatik toz boyalı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Kabinet</figcaption>
                                            <ul>
                                                <li>1,20mm DKP sacdan mamül</li>
                                                <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
                                                <li>İstenilen RAL kodunda elektrostatik fırın boyalı</li>
                                                <li>Alttan veya üstten / sağdan veya soldan tesisat girişine uygun</li>
                                                <li>Komple sac veya cam kapaklı</li>
                                                <li>Sıva altı - yarı gömme - sıva üstü seçenekleri</li>
                                                <li>Özel kilit sistemli, Demonte kapaklı</li>
                                                <li>TS 862 EN 3-7 e uygun ,TSE ve CE belgeli 6 kg kuru
                                                    kimyevi tozlu yangın söndürme cihazı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>SIVA ALTI TÜP YANDA DİKEY TEK KAPAKLI YANGIN DOLAPLARI</figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Hortum</th>
                                                        <th>Ölçüler</th>
                                                        <th>Kapak</th>
                                                        <th>Model</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>DT_031_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>900x710x220 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_032_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>900x710x220 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_033_YD</td>
                                                        <td>30 mt.</td>
                                                        <td>900x710x220 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_034_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>900x710x220 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_035_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>900x710x220 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_036_YD</td>
                                                        <td>35 mt.</td>
                                                        <td>900x710x220 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 7,
                                    name: 'TÜP YANDA DİKEY SIVA ÜSTÜ TEK KAPAKLI YANGIN DOLAPLARI',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/tüp_yanda_dikey_tek_kapakli_yangin_dolaplari/image-110.png',
                                    process.env.PUBLIC_URL + '/images/products/tüp_yanda_dikey_tek_kapakli_yangin_dolaplari/image-107.png',
                                    ],
                                    content: '',
                                    link: '/product/0/7/tüp_yanda_dikey_siva_ustu_tek_kapakli_yangin_dolaplari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>10mm orfice</li>
                                                <li>Ayarlanabilir (jet, spray, shut-off)</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
                                                <li>12 ATM çalışma basıncı, </li>
                                                <li>36 ATM patlama basıncı. </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Vana</figcaption>
                                            <ul>
                                                <li>2" köşe vanalı</li>
                                                <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Makara</figcaption>
                                            <ul>
                                                <li>Özel profilli çelik sactan mamül</li>
                                                <li>Elektrostatik toz boyalı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Kabinet</figcaption>
                                            <ul>
                                                <li>1,20mm DKP sacdan mamül</li>
                                                <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
                                                <li>İstenilen RAL kodunda elektrostatik fırın boyalı</li>
                                                <li>Alttan veya üstten / sağdan veya soldan tesisat girişine uygun</li>
                                                <li>Komple sac veya cam kapaklı</li>
                                                <li>Sıva altı - yarı gömme - sıva üstü seçenekleri</li>
                                                <li>Özel kilit sistemli, Demonte kapaklı</li>
                                                <li>TS 862 EN 3-7 e uygun ,TSE ve CE belgeli 6 kg kuru
                                                    kimyevi tozlu yangın söndürme cihazı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>SIVA ÜSTÜ TÜP YANDA DİKEY TEK KAPAKLI YANGIN DOLAPLARI</figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Hortum</th>
                                                        <th>Ölçüler</th>
                                                        <th>Kapak</th>
                                                        <th>Model</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>DT_037_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>900x750x240 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_038_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>900x750x240 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_039_YD</td>
                                                        <td>30 mt.</td>
                                                        <td>900x750x240 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_040_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>900x750x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_041_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>900x750x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_042_YD</td>
                                                        <td>35 mt.</td>
                                                        <td>900x750x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 8,
                                    name: 'CAM KAPAKLI STANDART YANGIN DOLAPLARI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/cam_kapakli_standart_yangin_dolaplari/image-118.png',
                                        process.env.PUBLIC_URL + '/images/products/cam_kapakli_standart_yangin_dolaplari/image-119.png',
                                        process.env.PUBLIC_URL + '/images/products/cam_kapakli_standart_yangin_dolaplari/image-120.png',
                                        process.env.PUBLIC_URL + '/images/products/cam_kapakli_standart_yangin_dolaplari/image-117.png',
                                        process.env.PUBLIC_URL + '/images/products/cam_kapakli_standart_yangin_dolaplari/image-121.png',
                                    ],
                                    content: '',
                                    link: '/product/0/8/cam_kapakli_standart_yangin_dolaplari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>10mm orfice</li>
                                                <li>Ayarlanabilir (jet, spray, shut-off)</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
                                                <li>12 ATM çalışma basıncı, </li>
                                                <li>36 ATM patlama basıncı. </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Vana</figcaption>
                                            <ul>
                                                <li>2" köşe vanalı</li>
                                                <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Makara</figcaption>
                                            <ul>
                                                <li>Özel profilli çelik sactan mamül</li>
                                                <li>Elektrostatik toz boyalı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Kabinet</figcaption>
                                            <ul>
                                                <li>1,20mm DKP sacdan mamül</li>
                                                <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
                                                <li>İstenilen RAL kodunda elektrostatik fırın boyalı</li>
                                                <li>Alttan veya üstten / sağdan veya soldan tesisat girişine uygun</li>
                                                <li>Komple cam kapaklı</li>
                                                <li>Sıva altı - yarı gömme - sıva üstü seçenekleri</li>
                                                <li>Özel kilit sistemli, Demonte kapaklı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>CAM KAPAKLI STANDART YANGIN DOLAPLARI</figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Hortum</th>
                                                        <th>Ölçüler</th>
                                                        <th>Kapak</th>
                                                        <th>Model</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>DT_043_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>710x710x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_044_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>710x710x240 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_045_YD</td>
                                                        <td>30 mt.</td>
                                                        <td>710x710x240 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_046_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>700x700x200 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_047_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>750x750x240 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_048_YD</td>
                                                        <td>35 mt.</td>
                                                        <td>750x750x240 mm</td>
                                                        <td>Camlı</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 9,
                                    name: 'SAC KAPAKLI STANDART YANGIN DOLAPLARI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/sac_kapakli_standart_yangin_dolaplari/image-128.png',
                                        process.env.PUBLIC_URL + '/images/products/sac_kapakli_standart_yangin_dolaplari/image-129.png',
                                        process.env.PUBLIC_URL + '/images/products/sac_kapakli_standart_yangin_dolaplari/image-136.png',
                                    ],
                                    content: '',
                                    link: '/product/0/9/sac_kapakli_standart_yangin_dolaplari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>10mm orfice</li>
                                                <li>Ayarlanabilir (jet, spray, shut-off)</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
                                                <li>12 ATM çalışma basıncı, </li>
                                                <li>36 ATM patlama basıncı. </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Vana</figcaption>
                                            <ul>
                                                <li>2" köşe vanalı</li>
                                                <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Makara</figcaption>
                                            <ul>
                                                <li>Özel profilli çelik sactan mamül</li>
                                                <li>Elektrostatik toz boyalı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Kabinet</figcaption>
                                            <ul>
                                                <li>1,20mm DKP sacdan mamül</li>
                                                <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
                                                <li>İstenilen RAL kodunda elektrostatik fırın boyalı</li>
                                                <li>Alttan veya üstten / sağdan veya soldan tesisat girişine uygun</li>
                                                <li>Komple sac kapaklı</li>
                                                <li>Sıva altı - yarı gömme - sıva üstü seçenekleri</li>
                                                <li>Özel kilit sistemli, Demonte kapaklı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>SAC KAPAKLI STANDART YANGIN DOLAPLARI</figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Hortum</th>
                                                        <th>Ölçüler</th>
                                                        <th>Kapak</th>
                                                        <th>Model</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>DT_049_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>710x710x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_050_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>710x710x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_051_YD</td>
                                                        <td>30 mt.</td>
                                                        <td>710x710x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_052_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>700x700x200 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_053_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>750x750x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_054_YD</td>
                                                        <td>35 mt.</td>
                                                        <td>750x750x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 10,
                                    name: 'SULU SİSTEM SAHRA TİPİ YANGIN DOLABI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/sulu_sistem_sahra_tipi_yangin_dolabi/image-139.png',
                                        process.env.PUBLIC_URL + '/images/products/sulu_sistem_sahra_tipi_yangin_dolabi/image-138.png',
                                    ],

                                    content: '',
                                    link: '/product/0/10/sulu_sistem_sahra_tipi_yangin_dolabi',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>10mm orfice</li>
                                                <li>Ayarlanabilir (jet, spray, shut-off)</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
                                                <li>12 ATM çalışma basıncı, </li>
                                                <li>36 ATM patlama basıncı. </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Vana</figcaption>
                                            <ul>
                                                <li>2" köşe vanalı</li>
                                                <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Makara</figcaption>
                                            <ul>
                                                <li>Özel profilli çelik sactan mamül</li>
                                                <li>Elektrostatik toz boyalı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Kabinet</figcaption>
                                            <ul>
                                                <li>1,5mm GALVANİZ sacdan mamül</li>
                                                <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
                                                <li>RAL 3002 renginde elektrostatik toz fırın boyalı</li>
                                                <li>Sağdan veya soldan tesisat girişine uygun</li>
                                                <li>Komple sac kapaklı</li>
                                                <li>Araz koşullarına karşı su geçirmez detaylı, çatılı ve ayaklı</li>
                                                <li>Özel kilit sistemli, Demonte kapaklı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>SULU SİSTEM SAHRA TİPİ YANGIN DOLAPLARI</figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Hortum</th>
                                                        <th>Ölçüler</th>
                                                        <th>Kapak</th>
                                                        <th>Model</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>DT_055_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>750x750x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Arazi Tipi</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_056_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>750x750x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Arazi Tipi</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_057_YD</td>
                                                        <td>30 mt.</td>
                                                        <td>750x750x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Arazi Tipi</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 11,
                                    name: 'BEZLİ SİSTEM SAHRA TİPİ YANGIN DOLABI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/bezli_sistem_sahra_tipi_yangin_dolabi/image-141.png',
                                        process.env.PUBLIC_URL + '/images/products/bezli_sistem_sahra_tipi_yangin_dolabi/image-140.png',
                                    ],
                                    content: '',
                                    link: '/product/0/11/bezli_sistem_sahra_tipi_yangin_dolabi',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Lans : <span>2" (veya 2 1/2)"</span></figcaption>
                                            <ul>
                                                <li>10mm orfice</li>
                                                <li>Ayarlanabilir düz veya kumandalı(jet, spray, shut-off)</li>
                                                <li>TSE 3145 Standartına uygun ve belgeli</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Hortum : <span>2" (DN50) veya 2 1/2" ( DN 65)</span></figcaption>
                                            <ul>
                                                <li>TSE EN9222 Standartına uygun ve belgeli</li>
                                                <li>17 ATM çalışma basıncı, </li>
                                                <li>50 ATM patlama basıncı. </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Rekor : <span>2" veya 2 1/2"</span></figcaption>
                                            <ul>
                                                <li>TS 12258 Standartına uygun ve belgeli</li>
                                            </ul>
                                        </figure>
                                        {/* <figure>
                            <figcaption>Vana</figcaption>
                            <ul>
                                <li>2" köşe vanalı</li>
                                <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
                            </ul>
                        </figure> */}
                                        <figure>
                                            <figcaption>Makara</figcaption>
                                            <ul>
                                                <li>Özel profilli çelik sactan mamül</li>
                                                <li>Elektrostatik toz boyalı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Kabinet</figcaption>
                                            <ul>
                                                <li>1,5mm GALVANİZ sacdan mamül</li>
                                                <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
                                                <li>RAL 3002 renginde elektrostatik toz fırın boyalı</li>
                                                <li>Sağdan veya soldan tesisat girişine uygun</li>
                                                <li>Komple sac kapaklı</li>
                                                <li>Araz koşullarına karşı su geçirmez detaylı, çatılı ve ayaklı</li>
                                                <li>Özel kilit sistemli, Demonte kapaklı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>BEZLİ SİSTEM SAHRA TİPİ YANGIN DOLAPLARI</figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Hortum</th>
                                                        <th>Ölçüler</th>
                                                        <th>Kapak</th>
                                                        <th>Model</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>DT_058_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>750x750x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Arazi Tipi</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_059_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>750x750x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Arazi Tipi</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_060_YD</td>
                                                        <td>30 mt.</td>
                                                        <td>750x750x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Arazi Tipi</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 12,
                                    name: 'ÇİFT MAKARALI BEZLİ SİSTEM SAHRA TİPİ YANGIN DOLAPLARI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/cift_makarali_bezli_sistem_sahra_tipi_yangin_dolaplari/image-162.png',
                                        process.env.PUBLIC_URL + '/images/products/cift_makarali_bezli_sistem_sahra_tipi_yangin_dolaplari/image-156.png',
                                        process.env.PUBLIC_URL + '/images/products/cift_makarali_bezli_sistem_sahra_tipi_yangin_dolaplari/image-157.png',
                                    ],
                                    content: '',
                                    link: '/product/0/12/cift_makarali_bezli_sistem_sahra_tipi_yangin_dolaplari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Lans : <span>2" (veya 2 1/2)"</span></figcaption>
                                            <ul>
                                                <li>10mm orfice</li>
                                                <li>Ayarlanabilir düz veya kumandalı(jet, spray, shut-off)</li>
                                                <li>TSE 3145 Standartına uygun ve belgeli</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Hortum : <span>2" (DN50) veya 2 1/2" ( DN 65)</span></figcaption>
                                            <ul>
                                                <li>TSE EN9222 Standartına uygun ve belgeli</li>
                                                <li>17 ATM çalışma basıncı, </li>
                                                <li>50 ATM patlama basıncı. </li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Rekor : <span>2" veya 2 1/2"</span></figcaption>
                                            <ul>
                                                <li>TS 12258 Standartına uygun ve belgeli</li>
                                            </ul>
                                        </figure>
                                        {/* <figure>
                            <figcaption>Vana</figcaption>
                            <ul>
                                <li>2" köşe vanalı</li>
                                <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
                            </ul>
                        </figure> */}
                                        <figure>
                                            <figcaption>Makara</figcaption>
                                            <ul>
                                                <li>Özel profilli çelik sactan mamül</li>
                                                <li>Elektrostatik toz boyalı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Kabinet</figcaption>
                                            <ul>
                                                <li>1,5mm GALVANİZ sacdan mamül</li>
                                                <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
                                                <li>RAL 3002 renginde elektrostatik toz fırın boyalı</li>
                                                <li>Sağdan veya soldan tesisat girişine uygun</li>
                                                <li>Komple sac kapaklı</li>
                                                <li>Araz koşullarına karşı su geçirmez detaylı, çatılı ve ayaklı</li>
                                                <li>Özel kilit sistemli, Demonte kapaklı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>BEZLİ SİSTEM SAHRA TİPİ YANGIN DOLAPLARI</figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Hortum</th>
                                                        <th>Ölçüler</th>
                                                        <th>Kapak</th>
                                                        <th>Model</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>DT_061_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>70x115x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Arazi Tipi</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_062_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>70x115x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Arazi Tipi</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_063_YD</td>
                                                        <td>30 mt.</td>
                                                        <td>70x115x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Arazi Tipi</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 13,
                                    name: 'KÖPÜKLÜ YANGIN DOLABI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/köpüklü_yangin_dolabi/image-155.png',
                                        process.env.PUBLIC_URL + '/images/products/köpüklü_yangin_dolabi/image-154.png',
                                    ],
                                    content: '',
                                    link: '/product/0/13/köpüklü_yangin_dolabi',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Nozul : <span>1" (Ø 25mm)"</span></figcaption>
                                            <ul>
                                                <li>Özel köpük yapma lansı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
                                                <li>12 ATM çalışma basıncı</li>
                                                <li>36 ATM patlama basıncı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Vana</figcaption>
                                            <ul>
                                                <li>1" küresel vanalı</li>
                                            </ul>
                                        </figure>
                                        {/* <figure>
                            <figcaption>Vana</figcaption>
                            <ul>
                                <li>2" köşe vanalı</li>
                                <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
                            </ul>
                        </figure> */}
                                        <figure>
                                            <figcaption>Makara</figcaption>
                                            <ul>
                                                <li>Özel profilli çelik sactan mamül</li>
                                                <li>Elektrostatik toz boyalı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Menenjör</figcaption>
                                            <ul>
                                                <li>1''özel ayarlanabilir köpük oranlayıcı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Tank</figcaption>
                                            <ul>
                                                <li>40 lt kapasiteli özel fiber malzemeden üretilmiştir.</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Kabinet</figcaption>
                                            <ul>
                                                <li>1,5mm GALVANİZ sacdan mamül</li>
                                                <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
                                                <li>RAL 3002 renginde elektrostatik toz fırın boyalı</li>
                                                <li>Sağdan veya soldan tesisat girişine uygun</li>
                                                <li>Komple sac kapaklı</li>
                                                <li>Araz koşullarına karşı su geçirmez detaylı, çatılı ve ayaklı</li>
                                                <li>Özel kilit sistemli, Demonte kapaklı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>KÖPÜKLÜ YANGIN DOLABI</figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Hortum</th>
                                                        <th>Ölçüler</th>
                                                        <th>Kapak</th>
                                                        <th>Model</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>DT_064_YD</td>
                                                        <td>20 mt.</td>
                                                        <td>750x1150x300 mm</td>
                                                        <td>Sac</td>
                                                        <td>Köpüklü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_065_YD</td>
                                                        <td>25 mt.</td>
                                                        <td>750x1150x300 mm</td>
                                                        <td>Sac</td>
                                                        <td>Köpüklü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_066_YD</td>
                                                        <td>30 mt.</td>
                                                        <td>750x1150x300 mm</td>
                                                        <td>Sac</td>
                                                        <td>Köpüklü</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 14,
                                    name: 'SULU SİSTEM HORTUM ARABALARI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/sulu_sistem_hortum_arabalari/image-172.png',
                                        process.env.PUBLIC_URL + '/images/products/sulu_sistem_hortum_arabalari/image-173.png',
                                    ],
                                    content: '',
                                    link: '/product/0/14/sulu_sistem_hortum_arabalari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Nozul : <span>1" (Ø 25mm)"</span></figcaption>
                                            <ul>
                                                <li>10mm orfice</li>
                                                <li>Ayarlanabilir (jet, spray, shut-off)</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
                                                <li>12 ATM çalışma basıncı</li>
                                                <li>36 ATM patlama basıncı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Vana</figcaption>
                                            <ul>
                                                <li>2" köşe vanalı, 2"-1" adaptörlü veya 1" küresel vanalı</li>
                                            </ul>
                                        </figure>
                                        {/* <figure>
                            <figcaption>Vana</figcaption>
                            <ul>
                                <li>2" köşe vanalı</li>
                                <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
                            </ul>
                        </figure> */}
                                        <figure>
                                            <figcaption>Makara</figcaption>
                                            <ul>
                                                <li>1.5 mm Galvaniz sactan pres baskı ile imal edilmiştir.</li>
                                                <li> 0.70 Mikron elektrostatik toz boyalı RAL 3002 kırmızı </li>
                                                <li>Geri sarma işlemini basitleştiren krank sistemi </li>
                                                <li>Makara göbeği paslanmaz malzemeden üretilmiştir. </li>
                                                <li>İsteğe bağlı olarak 40-50-60 mt üretilmektedir.</li>
                                            </ul>
                                        </figure>


                                    </div>
                                },
                                {
                                    id: 15,
                                    name: 'ŞAFT İÇİ SULU SİSTEM YANGIN MAKARASI',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/saft_içi_sulu_sistem_yangin_makarasi/image-169.png'],
                                    content: '',
                                    link: '/product/0/15/saft_içi_sulu_sistem_yangin_makarasi',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Nozul : <span>1" (Ø 25mm)"</span></figcaption>
                                            <ul>
                                                <li>10mm orfice</li>
                                                <li>Ayarlanabilir (jet, spray, shut-off)</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
                                            <ul>
                                                <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
                                                <li>12 ATM çalışma basıncı</li>
                                                <li>36 ATM patlama basıncı</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Vana</figcaption>
                                            <ul>
                                                <li>2"veya1" küresel vana</li>
                                            </ul>
                                        </figure>
                                        {/* <figure>
                            <figcaption>Vana</figcaption>
                            <ul>
                                <li>2" köşe vanalı</li>
                                <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
                            </ul>
                        </figure> */}
                                        <figure>
                                            <figcaption>Makara</figcaption>
                                            <ul>
                                                <li>Özel profilli çelik sactan mamül</li>
                                                <li>Elektrostatik toz boyalı,</li>
                                                <li>İsteğe bağlı 20-25-30 mt üretim</li>
                                            </ul>
                                        </figure>


                                    </div>
                                },
                                {
                                    id: 16,
                                    name: 'KORUYUCU MALZEME DOLAPLARI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/koruyucu_malzeme_dolabi/image-178.png',
                                        process.env.PUBLIC_URL + '/images/products/koruyucu_malzeme_dolabi/image-175.png',
                                        process.env.PUBLIC_URL + '/images/products/koruyucu_malzeme_dolabi/image-176.png',
                                        process.env.PUBLIC_URL + '/images/products/koruyucu_malzeme_dolabi/image-177.png',
                                    ],
                                    content: '',
                                    link: '/product/0/16/köpüklü_yangin_dolabi',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Kabinet</figcaption>
                                            <ul>
                                                <li>1,5mm GALVANİZ sacdan mamül</li>
                                                <li>Kapak tadilatsız sağ veya sol uygulamalı</li>
                                                <li>RAL 3002 renginde elektrostatik toz fırın boyalı</li>
                                                <li>Komple sac kapaklı</li>
                                                <li>Özel kilit sistemli, Demonte kapaklı</li>
                                                <li>Ayarlanabilir raf özellikli</li>
                                                <li>Ayaklı</li>
                                                <li>İstenilen ölçülerde üretilebilir</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>KORUYUCU EKİPMAN MALZEME DOLAPLARI </figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Ölçüler</th>
                                                        <th>Kapak</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>DT_067_YD</td>
                                                        <td>700x1500x500 mm</td>
                                                        <td>Sac</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_068_YD</td>
                                                        <td>700x1500x500 mm</td>
                                                        <td>Camlı</td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 17,
                                    name: 'YANGIN SÖNDÜRME CİHAZI DOLAPLARI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/yangin_sondurme_cihazi_dolaplari/image-174.png',
                                        process.env.PUBLIC_URL + '/images/products/yangin_sondurme_cihazi_dolaplari/image-185.png',
                                        process.env.PUBLIC_URL + '/images/products/yangin_sondurme_cihazi_dolaplari/image-186.png',
                                        process.env.PUBLIC_URL + '/images/products/yangin_sondurme_cihazi_dolaplari/image-187.png',
                                    ],
                                    content: '',
                                    link: '/product/0/17/yangin_sondurme_cihazi_dolaplari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>

                                        <figure>
                                            <figcaption>YANGIN SÖNDÜRME CİHAZI DOLAPLARI </figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Ölçüler</th>
                                                        <th>Kapak</th>
                                                        <th>Model</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>DT_069_YD</td>
                                                        <td>210x710x210 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_070_YD</td>
                                                        <td>210x710x210 mm</td>
                                                        <td>Cam</td>
                                                        <td>Sıva Altı</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_071_YD</td>
                                                        <td>300x730x240 mm</td>
                                                        <td>Sac</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_072_YD</td>
                                                        <td>300x730x240 mm</td>
                                                        <td>Cam</td>
                                                        <td>Sıva Üstü</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                            ]
    }
},
{
    category: {
        id: 3,
            name: 'YANGIN KAPILARI',
                imgsrc: [process.env.PUBLIC_URL + '/images/products/tek_kanat_yangin_kapilari/image-199.png'],
                    content: 'EN 16034  Standardına uygun , İSO belgeli, çeşitli model ve özelliklerde yangın kapıları.',
                        link: '/category/yanginhortumlari/3/subcategories',
                            subcategories: [
                                {
                                    id: 0,
                                    name: 'TEK KANAT YANGIN KAPILARI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/tek_kanat_yangin_kapilari/2.jpg',
                                        process.env.PUBLIC_URL + '/images/products/tek_kanat_yangin_kapilari/image-199.png',
                                        process.env.PUBLIC_URL + '/images/products/tek_kanat_yangin_kapilari/image-197.png',
                                        process.env.PUBLIC_URL + '/images/products/tek_kanat_yangin_kapilari/image-200.png',
                                    ],
                                    content: '',
                                    link: '/product/3/0/tek_kanat_yangin_kapilari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>TEKNİK ÖZELLİKLER </figcaption>
                                            <ul>
                                                <li>Yangın kapılarımız TSE EN 16034 standardına sahip olup aşağıdaki özelliklerde üretilmektedir.</li>
                                                <li>KASA : Projeye göre  1,2 -1,5 mm kalınlıklarında DKP veya Galvaniz sacdan ,ayarlı tam kasa veya yarım kasa olarak üretilmektedir.</li>
                                                <li>KANAT : Projeye göre  1 -1,2 mm kalınlıklarında DKP veya Galvaniz sacdan ,tek kanat  veya çift kanat olarak üretilmektedir.</li>
                                                <li>FİTİL: 170 °C genişleyerek boşlukları kapatan intumesan fitil ve ses contası kullanılmaktadır.</li>
                                                <li>BOYA : Proje bazlı istenilen renkte elektrostatik toz boya ile boyanmaktadır.</li>
                                                <li>MENTEŞE : 1 adet yaylı , 1 adet yaysız menteşe kullanıldığından kapılar kendiliğinden kapanmaktadır.</li>
                                                <li>AKSESUAR: Projeye göre yüzeysel ve gömme sistem panik bar ,kilitli veya kilitsiz dış kol ,hidrolik kapı kapatıcı, kapı sıralama mekanizması ,manyetik kapı tutucu ,kapı altı otomatik giyotin kullanılmaktadır.</li>
                                            </ul>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 1,
                                    name: 'ÇİFT KANAT YANGIN KAPILARI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/cift_kanat_yangin_kapilari/image-201.png',
                                        process.env.PUBLIC_URL + '/images/products/cift_kanat_yangin_kapilari/image-202.png',
                                        process.env.PUBLIC_URL + '/images/products/cift_kanat_yangin_kapilari/image-203.png',
                                    ],
                                    content: '',
                                    link: '/product/3/1/cift_kanat_yangin_kapilari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>TEKNİK ÖZELLİKLER </figcaption>
                                            <ul>
                                                <li>Yangın kapılarımız TSE EN 16034 standardına sahip olup aşağıdaki özelliklerde üretilmektedir.</li>
                                                <li>KASA : Projeye göre  1,2 -1,5 mm kalınlıklarında DKP veya Galvaniz sacdan ,ayarlı tam kasa veya yarım kasa olarak üretilmektedir.</li>
                                                <li>KANAT : Projeye göre  1 -1,2 mm kalınlıklarında DKP veya Galvaniz sacdan ,tek kanat  veya çift kanat olarak üretilmektedir.</li>
                                                <li>FİTİL: 170 °C genişleyerek boşlukları kapatan intumesan fitil ve ses contası kullanılmaktadır.</li>
                                                <li>BOYA : Proje bazlı istenilen renkte elektrostatik toz boya ile boyanmaktadır.</li>
                                                <li>MENTEŞE : 1 adet yaylı , 1 adet yaysız menteşe kullanıldığından kapılar kendiliğinden kapanmaktadır.</li>
                                                <li>AKSESUAR: Projeye göre yüzeysel ve gömme sistem panik bar ,kilitli veya kilitsiz dış kol ,hidrolik kapı kapatıcı, kapı sıralama mekanizması ,manyetik kapı tutucu ,kapı altı otomatik giyotin kullanılmaktadır.</li>
                                            </ul>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 2,
                                    name: 'CAMLI YANGIN KAPILARI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/camli_yangin_kapilari/image-208.png',
                                        process.env.PUBLIC_URL + '/images/products/camli_yangin_kapilari/image-207.png',
                                        // process.env.PUBLIC_URL + '/images/products/camli_yangin_kapilari/image-206.png',
                                        // process.env.PUBLIC_URL + '/images/products/camli_yangin_kapilari/image-205.png',
                                    ],
                                    content: '',
                                    link: '/product/3/2/camli_yangin_kapilari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>TEKNİK ÖZELLİKLER </figcaption>
                                            <ul>
                                                <li>Yangın kapılarımız TSE EN 16034 standardına sahip olup aşağıdaki özelliklerde üretilmektedir.</li>
                                                <li>KASA : Projeye göre  1,2 -1,5 mm kalınlıklarında DKP veya Galvaniz sacdan ,ayarlı tam kasa veya yarım kasa olarak üretilmektedir.</li>
                                                <li>KANAT : Projeye göre  1 -1,2 mm kalınlıklarında DKP veya Galvaniz sacdan ,tek kanat  veya çift kanat olarak üretilmektedir.</li>
                                                <li>FİTİL: 170 °C genişleyerek boşlukları kapatan intumesan fitil ve ses contası kullanılmaktadır.</li>
                                                <li>BOYA : Proje bazlı istenilen renkte elektrostatik toz boya ile boyanmaktadır.</li>
                                                <li>MENTEŞE : 1 adet yaylı , 1 adet yaysız menteşe kullanıldığından kapılar kendiliğinden kapanmaktadır.</li>
                                                <li>AKSESUAR: Projeye göre yüzeysel ve gömme sistem panik bar ,kilitli veya kilitsiz dış kol ,hidrolik kapı kapatıcı, kapı sıralama mekanizması ,manyetik kapı tutucu ,kapı altı otomatik giyotin kullanılmaktadır.</li>
                                            </ul>
                                        </figure>
                                    </div>
                                },
                            ]
    }
},
{
    category: {
        id: 1,
            name: 'YANGIN HORTUMLARI VE REKORLARI',
                imgsrc: [process.env.PUBLIC_URL + '/images/category/hortum.jpg'],
                    content: 'ISO 9001:2014 kalite yönetim standartlarına uygun TS 9222 belgeli yangın hortumları.',
                        link: '/category/yanginhortumlari/1/subcategories',
                            subcategories: [
                                {
                                    id: 0,
                                    name: 'YARI SERT KAUÇUK YANGIN HORTUMLARI',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_hortumlari_rekorlari/image-193.png'],
                                    content: '',
                                    link: '/product/1/0/yari_sert_kaucuk_yangin_hortumlari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Özellikler</figcaption>
                                            <ul>
                                                <li>TS EN 694 Yarı sert kauçuk malzemeden imal edilmiştir.</li>
                                                <li>Kullanıldıktan sonra kurutmaya gerek yoktur.</li>
                                                <li>Sulu sistem yangın dolapları ve mobil makaralarda kullanıma uygundur.</li>
                                                <li>Üretildiği ölçüler : ¾”ten 1″e kadar istenilen uzunlukta</li>
                                                <li>Çalışma basıncı : 12 Bar</li>
                                                <li>Test basıncı : 18 Bar</li>
                                                <li>Patlama basıncı : 36 Bar</li>
                                            </ul>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 6,
                                    name: 'İÇİ KAUÇUK YASSI YANGIN HORTUMU',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/category/hortum.jpg'],
                                    content: '',
                                    link: '/product/1/6/yari_sert_kaucuk_yangin_hortumlari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>TEKNİK VERİLER </figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>İç Çap (Mm - İnç)</th>
                                                        <th>Patlama Basıncı (Bar - Psi)</th>
                                                        <th>Çalışma Basıncı (Bar - Psi)</th>
                                                        <th>Ağırlık +/-%5 (g/m)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>DT_001_YH</td>
                                                        <td>25 - 1"</td>
                                                        <td>60 - 840</td>
                                                        <td>20 - 280</td>
                                                        <td>130</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_002_YH</td>
                                                        <td>38 - 1 <sup>1/2″</sup></td>
                                                        <td>50 - 700</td>
                                                        <td>17 - 240</td>
                                                        <td>195</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_003_YH</td>
                                                        <td>45 - 1 <sup>3/4″</sup></td>
                                                        <td>50 - 700</td>
                                                        <td>17 - 240</td>
                                                        <td>230</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_004_YH</td>
                                                        <td>52 - 2"</td>
                                                        <td>50 - 700</td>
                                                        <td>17 - 240</td>
                                                        <td>250</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_005_YH</td>
                                                        <td>65 - 2 <sup>1/2″</sup></td>
                                                        <td>50 - 700</td>
                                                        <td>17 - 240</td>
                                                        <td>365</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_006_YH</td>
                                                        <td>70 - 2 <sup>3/4″</sup></td>
                                                        <td>50 - 700</td>
                                                        <td>17 - 240</td>
                                                        <td>395</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_007_YH</td>
                                                        <td>70 - 2 <sup>3/4″</sup></td>
                                                        <td>50 - 700</td>
                                                        <td>17 - 240</td>
                                                        <td>395</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_008_YH</td>
                                                        <td>75 -3"</td>
                                                        <td>37 - 550</td>
                                                        <td>13 - 180</td>
                                                        <td>405</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_009_YH</td>
                                                        <td>102 - 4"</td>
                                                        <td>30 - 425</td>
                                                        <td>10 - 140</td>
                                                        <td>560</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_010_YH</td>
                                                        <td>125 - 5"</td>
                                                        <td>20</td>
                                                        <td>7 - 110</td>
                                                        <td>750</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_011_YH</td>
                                                        <td>150 - 6"</td>
                                                        <td>20 - 285</td>
                                                        <td>7 - 110</td>
                                                        <td>900</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 1,
                                    name: 'HORTUM REKORLARI',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_hortumlari_rekorlari/1.jpg'],
                                    content: '',
                                    link: '/product/1/1/yangin_hortumlari_rekorlari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Özellikler</figcaption>
                                            <ul>
                                                <li>Etial 160</li>
                                                <li>Pres Enjeksiyon Baskı</li>
                                                <li>Açma - Kapama</li>
                                                <li>Moment 3-5 Nm</li>
                                                <li>Basma Kaplin İşletme</li>
                                                <li>Basıncı 12 Bar</li>
                                                <li>Mukavemet 24 Bar</li>
                                                <li>TS 12258</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>TEKNİK VERİLER </figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Tırnak</th>
                                                        <th>Ürün Kodu</th>
                                                        <th>Cinsi</th>
                                                        <th>B1</th>
                                                        <th>B2</th>
                                                        <th>L</th>
                                                        <th>D1</th>
                                                        <th>Ağırlık</th>
                                                        <th>Koli Adet</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>HR-25</td>
                                                        <td>GNF 5000</td>
                                                        <td>1" Hortum Rekoru</td>
                                                        <td>18 mm</td>
                                                        <td>25 mm</td>
                                                        <td>53 mm</td>
                                                        <td>31 mm</td>
                                                        <td>0,095</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>HR-25</td>
                                                        <td>GNF 5010</td>
                                                        <td>3/4" Hortum Rekoru</td>
                                                        <td>14 mm</td>
                                                        <td>20 mm</td>
                                                        <td>53 mm</td>
                                                        <td>31 mm</td>
                                                        <td>0,095</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>HR-38</td>
                                                        <td>GNF 5020</td>
                                                        <td>1 <sup>1/2″</sup> Hortum Rekoru</td>
                                                        <td>28 mm</td>
                                                        <td>38 mm</td>
                                                        <td>90 mm</td>
                                                        <td>51 mm</td>
                                                        <td>0,235</td>
                                                        <td>42</td>
                                                    </tr>
                                                    <tr>
                                                        <td>HR-C52</td>
                                                        <td>GNF 5030</td>
                                                        <td>2″ Hortum Rekoru</td>
                                                        <td>18 mm</td>
                                                        <td>28 mm</td>
                                                        <td>80 mm</td>
                                                        <td>66 mm</td>
                                                        <td>0,375</td>
                                                        <td>36</td>
                                                    </tr>
                                                    <tr>
                                                        <td>HR-C52</td>
                                                        <td>GNF 5040</td>
                                                        <td>2″ Hortum Rekoru</td>
                                                        <td>42 mm</td>
                                                        <td>52 mm</td>
                                                        <td>90 mm</td>
                                                        <td>66 mm</td>
                                                        <td>0,400</td>
                                                        <td>36</td>
                                                    </tr>
                                                    <tr>
                                                        <td>HR-B75</td>
                                                        <td>GNF 5050-65</td>
                                                        <td>2 <sup>1/2″</sup> Hortum Rekoru</td>
                                                        <td>55 mm</td>
                                                        <td>65 mm</td>
                                                        <td>125 mm</td>
                                                        <td>89 mm</td>
                                                        <td>0,765</td>
                                                        <td>30</td>
                                                    </tr>
                                                    <tr>
                                                        <td>HR-B75</td>
                                                        <td>GNF 5050-70</td>
                                                        <td>2 <sup>3/4″</sup> Hortum Rekoru</td>
                                                        <td>60 mm</td>
                                                        <td>70 mm</td>
                                                        <td>125 mm</td>
                                                        <td>89 mm</td>
                                                        <td>0,765</td>
                                                        <td>30</td>
                                                    </tr>
                                                    <tr>
                                                        <td>HR-B75</td>
                                                        <td>GNF 5060</td>
                                                        <td>3″ Hortum Rekoru</td>
                                                        <td>65 mm</td>
                                                        <td>75 mm</td>
                                                        <td>125 mm</td>
                                                        <td>89 mm</td>
                                                        <td>0,790</td>
                                                        <td>30</td>
                                                    </tr>
                                                    <tr>
                                                        <td>HR-A110</td>
                                                        <td>GNF 5070</td>
                                                        <td>4″ Hortum Rekoru</td>
                                                        <td>90 mm</td>
                                                        <td>101 mm</td>
                                                        <td>170 mm</td>
                                                        <td>133 mm</td>
                                                        <td>1,505</td>
                                                        <td>12</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 2,
                                    name: 'HORTUM REKOR ANAHTARLARI',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_hortumlari_rekorlari/unnamed.jpg'],
                                    content: '',
                                    link: '/product/1/2/yangin_hortumlari_rekor_anahtarlari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Özellikler</figcaption>
                                            <ul>
                                                <li>8 mm Sactan Lazer Kesim</li>
                                                <li>Çinko Kaplama</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>TEKNİK VERİLER </figcaption>
                                            <table>
                                                <thead>
                                                    <tr>

                                                        <th>Ürün Kodu</th>
                                                        <th>Cinsi</th>
                                                        <th>W</th>
                                                        <th>Ağırlık</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>GNF 8500</td>
                                                        <td>2″-2 <sup>1/2″</sup> Hidrant Anahtarı</td>
                                                        <td>69 mm</td>
                                                        <td>0,795</td>
                                                    </tr>
                                                    <tr>
                                                        <td>GNF 8510</td>
                                                        <td>2″-2 <sup>1/2″</sup> Rekor Anahtarı</td>
                                                        <td></td>
                                                        <td>0,430</td>
                                                    </tr>
                                                    <tr>
                                                        <td>GNF 8520</td>
                                                        <td>2 <sup>1/2″</sup>-4″ Rekor Anahtarı</td>
                                                        <td></td>
                                                        <td>0,645</td>
                                                    </tr>
                                                    <tr>
                                                        <td>GNF 8501</td>
                                                        <td>Çok Amaçlı Hidrant Anahtarı</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                            ]
    }
},
{
    category: {
        id: 2,
            name: 'LANSLAR',
                imgsrc: [process.env.PUBLIC_URL + '/images/products/lanslar/Perdeleme-Lans-705x564.jpg'],
                    content: 'ISO 9001:2014 kalite yönetim standartlarına uygun TS 3145 belgeli lanslar.',
                        link: '/category/lanslar/2/subcategories',
                            subcategories: [
                                {
                                    id: 0,
                                    name: 'DÜZ LANSLAR',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/lanslar/media582324ca3846a.png'],
                                    content: '',
                                    link: '/product/2/0/duz_lanslar',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>TEKNİK VERİLER </figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Tırnak</th>
                                                        <th>Cinsi</th>
                                                        <th>Ağırlık</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>GNF7015</td>
                                                        <td>İDR-38</td>
                                                        <td>1<sup>1/2"</sup> Pvc Düz Lans</td>
                                                        <td>0,395</td>
                                                    </tr>
                                                    <tr>
                                                        <td>GNF7020</td>
                                                        <td>İDR-52</td>
                                                        <td>2" Pvc Düz Lans</td>
                                                        <td>0,480</td>
                                                    </tr>
                                                    <tr>
                                                        <td>GNF7005</td>
                                                        <td>İDR-38</td>
                                                        <td>1<sup>1/2"</sup> Alm Düz Lans</td>
                                                        <td>0,750</td>
                                                    </tr>
                                                    <tr>
                                                        <td>GNF7000</td>
                                                        <td>İDR-52</td>
                                                        <td>2" Alm Düz Lans</td>
                                                        <td>0,840</td>
                                                    </tr>
                                                    <tr>
                                                        <td>GNF7010</td>
                                                        <td>İDR-65</td>
                                                        <td>2 <sup>1/2"</sup> Palm Düz Lans</td>
                                                        <td>1,250</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 1,
                                    name: 'KUMANDALI LANSLAR',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/lanslar/kumandali-lans-e134.jpeg'],
                                    content: '',
                                    link: '/product/2/1/kumandali_lanslar',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Özellikler</figcaption>
                                            <ul>
                                                <li>Etial 160 Malzeme</li>
                                                <li>TSE 3145</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>TEKNİK VERİLER </figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Tırnak</th>
                                                        <th>Cinsi</th>
                                                        <th>Ağırlık</th>
                                                        <th>Koli Adeti</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>GNF7105</td>
                                                        <td>İDR-38</td>
                                                        <td>1<sup>1/2"</sup> Kumandalı Lans Alm</td>
                                                        <td>1,370</td>
                                                        <td>25</td>
                                                    </tr>
                                                    <tr>
                                                        <td>GNF7100</td>
                                                        <td>İDR-52</td>
                                                        <td>2" Kumandalı Lans Alm</td>
                                                        <td>1,475</td>
                                                        <td>30</td>
                                                    </tr>
                                                    <tr>
                                                        <td>GNF7110</td>
                                                        <td>İDR-65</td>
                                                        <td>2<sup>1/2"</sup> Kumandalı Lans Alm</td>
                                                        <td>1,860</td>
                                                        <td>13</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 2,
                                    name: 'PERDELİ LANSLAR',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/lanslar/Perdeleme-Lans-705x564.jpg'],
                                    content: '',
                                    link: '/product/2/2/perdeli_lanslar',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>Özellikler</figcaption>
                                            <ul>
                                                <li>Etial 160 Malzeme</li>
                                                <li>TSE 3145</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>TEKNİK VERİLER </figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>Tırnak</th>
                                                        <th>Cinsi</th>
                                                        <th>Ağırlık</th>
                                                        <th>Koli Adeti</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>GNF7145</td>
                                                        <td>İDR-38</td>
                                                        <td>1<sup>1/2"</sup> Kumandalı Perdeli Lans Alm</td>
                                                        <td>1,750</td>
                                                        <td>25</td>
                                                    </tr>
                                                    <tr>
                                                        <td>GNF7150</td>
                                                        <td>İDR-52</td>
                                                        <td>2" Kumandalı Perdeli Lans Alm</td>
                                                        <td>1,870</td>
                                                        <td>30</td>
                                                    </tr>
                                                    <tr>
                                                        <td>GNF7155</td>
                                                        <td>İDR-65</td>
                                                        <td>2<sup>1/2"</sup> Kumandalı Perdeli Lans Alm</td>
                                                        <td>2,160</td>
                                                        <td>13</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </figure>
                                    </div>
                                },
                            ]
    }
},

{
    category: {
        id: 15,
            name: 'ACİL ÇIKIŞ AKSESUARLARI',
                imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_cikis_aksesuarlari/image-209.png'],
                    content: '',
                        link: '/category/acil_cikis_aksesuarlari/15/subcategories',
                            subcategories: [
                                {
                                    id: 0,
                                    name: 'DIŞ MÜDAHALE KOLU 55M',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_cikis_aksesuarlari/omnipanikbar.jpg'],
                                    content: '',
                                    link: '/product/15/0/acil_cikis_aksesuarlari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>TEKNİK ÖZELLİKLER </figcaption>
                                            <ul>
                                                <li>550 D ile birlikte kapıya
                                                    dışardan müdahale etmek
                                                    ve kilitlemek için kullanılır.</li>
                                                <li>DIN 4102 norma uygundur.</li>
                                                <li>Tüm 550 sersnde olduğu
                                                    gb, aynı mekanizma hem
                                                    sağa hem sola açılan kapılar
                                                    uygulanabilir. </li>
                                                <li>Çelk saç gövde,
                                                    muhafaz ve kol alüminyum
                                                    alaşımlı, çalışan parçalar
                                                    zamak alaşımlı olarak
                                                    üretilmiştir.</li>
                                            </ul>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 1,
                                    name: 'ELEKTROMANYETİK KAPI TUTUCU',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_cikis_aksesuarlari/image-211.png'],
                                    content: '',
                                    link: '/product/15/1/acil_cikis_aksesuarlari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>TEKNİK ÖZELLİKLER </figcaption>
                                            <ul>
                                                <li>Kapı Kanatlarını açık vaziyette
                                                    tutmak amacıyla kullanılır.</li>
                                                <li> Kanat açıldığında kapının
                                                    üzerindeki metal plaka
                                                    manyetik tutucuya dokunur,
                                                    devre tamamlanır ve
                                                    mıknatıslaşmış olan tutucu
                                                    kapının açık kalmasını sağlar.</li>
                                                <li>Üzerindeki ya da ayrı verilen
                                                    bir düğme yardımı ile kapı
                                                    serbest bırakılır. </li>
                                                <li>
                                                    Elektrik kesintilerinde kapıyı
                                                    serbest bırakır.
                                                </li>
                                                <li>
                                                    24 V doğru akım le çalışır
                                                    ve 1,5 W güç çeker.
                                                </li>
                                            </ul>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 2,
                                    name: 'YAYLI MENTEŞE',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_cikis_aksesuarlari/image-212.png'],
                                    content: '',
                                    link: '/product/15/2/acil_cikis_aksesuarlari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>TEKNİK ÖZELLİKLER </figcaption>
                                            <ul>
                                                <li>Kapının kendi kendine
                                                    kapanmasını sağlar, özellikle
                                                    yangın kapıları için önemlidir.</li>
                                                <li>
                                                    Set, yaylı ve yaysız olmak
                                                    üzere iki adet menteşeden
                                                    oluşur. Sertlik ayarı yapılabilir.
                                                </li>
                                            </ul>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 3,
                                    name: 'GÖMME KİLİTLİ PANİK BAR',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_cikis_aksesuarlari/image-213.png'],
                                    content: '',
                                    link: '/product/15/3/acil_cikis_aksesuarlari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>TEKNİK ÖZELLİKLER </figcaption>
                                            <ul>
                                                <li>Panik barın özelliği her türlü
                                                    durumda panik bara
                                                    basıldığında açılmasıdır.</li>
                                                <li>
                                                    Dış müdahele kolu ile
                                                    uygulanabilir.
                                                </li>
                                                <li>
                                                    Teknik özellkleri 200.000
                                                    basma testinden geçmiştir.
                                                </li>
                                                <li>Mekanizma siyah boyalı
                                                    çelik mekanizma ve plastik
                                                    koruma kapağı.</li>
                                            </ul>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 4,
                                    name: 'PUSH BAR',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_cikis_aksesuarlari/image-214.png'],
                                    content: '',
                                    link: '/product/15/4/acil_cikis_aksesuarlari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>TEKNİK ÖZELLİKLER </figcaption>
                                            <ul>
                                                <li>Yangın çıkış kapıları için
                                                    kullanılır. Dışarıdan barel
                                                    olduğu için kilitlenebilir ve
                                                    kilitlendiği zaman içerden
                                                    basılınca da açılabilir.</li>
                                                <li>
                                                    Dışarıdan kolla açılma
                                                    şansı yoktur. Eğer kolla
                                                    açılmak istenirse Panik Bar
                                                    Kolu alınması gerekmektedir.</li>
                                            </ul>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 5,
                                    name: 'KAPI ALTI GİYOTİN',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_cikis_aksesuarlari/image-215.png'],
                                    content: '',
                                    link: '/product/15/5/acil_cikis_aksesuarlari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>TEKNİK ÖZELLİKLER </figcaption>
                                            <ul>
                                                <li>Kapı kapandığında otomatik
                                                    olarak kapının altındaki
                                                    boşluğu kapatarak duman,
                                                    is ve ses geçişini engeller.</li>
                                                <li>
                                                    Yangın kapıları, acil çıkış
                                                    kapıları, metal kapılar ve
                                                    ahşap kapılara uygulanır.</li>
                                                <li>Gömme olarak ya da kapı
                                                    üzerine uygulanabilir
                                                    Alüminyum profil ve silikon
                                                    fitilden oluşur.</li>
                                            </ul>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 6,
                                    name: 'PANİK BAR SİSTEMLERİ (Tek Nokta Kilitleme)',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_cikis_aksesuarlari/image-216.png'],
                                    content: '',
                                    link: '/product/15/6/acil_cikis_aksesuarlari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>TEKNİK ÖZELLİKLER </figcaption>
                                            <ul>
                                                <li>Mekanizma hem sağa hem sola açılan kapılara
                                                    uygulanabilmektedir. DIN 4102 norma uygundur.</li>
                                                <li>
                                                    Çelik saç gövdesi, muhafaza, kollar ve bar alüminyum
                                                    alaşımlı, çalışan parçalar zamak alaşımlı olarak üretilmiştir.</li>
                                                <li>Dış taraftan müdanele etmek için kullanılan kol uygulamaları
                                                    için adaptörü vardır.</li>
                                            </ul>
                                        </figure>
                                    </div>
                                },
                            ]
    }
},
{
    category: {
        id: 4,
            name: 'YANGINA DAYANIKLI ŞAFT KAPAKLARI',
                imgsrc: [process.env.PUBLIC_URL + '/images/products/yangina_dayanikli_saft_kapaklari/image-217.png'],
                    content: 'EN 16034  Standardına uygun , çeşitli model ve özelliklerde yangına dayanıklı şaft kapakları.',
                        link: '/category/yangina_dayanikli_saft_kapaklari/4/subcategories',
                            subcategories: [
                                {
                                    id: 0,
                                    name: 'YANGINA DAYANIKLI ŞAFT KAPAĞI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/yangina_dayanikli_saft_kapaklari/image-217.png',
                                        process.env.PUBLIC_URL + '/images/products/yangina_dayanikli_saft_kapaklari/image-218.png',
                                    ],
                                    content: 'Yangına 60-90-120 dak. dayanımlı olarak üretilen şaft kapaklarımızın teknik detayları projelere göre belirlenmektedir.',
                                    link: '/product/4/0/yangina_dayanikli_saft_kapaklari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>STANDART ÖZELLİKLER </figcaption>
                                            <ul>
                                                <li>Kasa 1,2mm Galvaniz veya DKP sactır.</li>
                                                <li>Kanat 1mm Galvaniz veya DKP sactır.</li>
                                                <li>İzolasyon dayanım süresine göre taşyünü veya alçıpanla yapılmaktadır.</li>
                                                <li>Kilit sistemi üçgen kilittir.</li>
                                                <li>Boya istenilen RAL kodunda statik fırın boyadır.</li>
                                                <li>Menteşe mil menteşe / yaylı & yaysız menteşe.</li>
                                                <li>Fitil yangın fitil ve soğuk duman fitil mevcuttur.</li>
                                            </ul>
                                        </figure>
                                    </div>
                                },
                                {
                                    id: 1,
                                    name: 'STANDART ŞAFT KAPAĞI',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/yangina_dayanikli_saft_kapaklari/image-219.png',
                                        process.env.PUBLIC_URL + '/images/products/yangina_dayanikli_saft_kapaklari/image-220.png',
                                    ],
                                    content: 'Teknik detayları projeye göre belirlenmektedir.',
                                    link: '/product/4/1/standart_saft_kapaklari',
                                    detail: <div className="detail">
                                        <h2>Teknik Özellikler</h2>
                                        <figure>
                                            <figcaption>STANDART ÖZELLİKLER </figcaption>
                                            <ul>
                                                <li>Kasa 1,2mm Galvaniz veya DKP sactır.</li>
                                                <li>Kanat 1mm Galvaniz veya DKP sactır.</li>
                                                <li>Kilit sistemi üçgen kilittir.</li>
                                                <li>Boya istenilen RAL kodunda statik fırın boyadır.</li>
                                                <li>Menteşe mil menteşedir.</li>
                                            </ul>
                                        </figure>
                                    </div>
                                },
                            ]
    }
},




{
    category: {
        id: 9,
            name: 'YANGIN VANA GRUPLARI',
                imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_vana_gruplari/image-257.png'],
                    content: 'UL & FM  onaylı ,İzlenebilir yangın vanaları,test&drenaj vanaları,akış anahtarları, çek vanalar,yükselen milli vanalar.',
                        link: '/category/yangin_vana_gruplari/9/subcategories',
                            subcategories: [
                                {
                                    id: 0,
                                    name: 'İZLENEBİLİR ANAHTARLI KELEBEK VANA',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_vana_gruplari/image-257.png'],
                                    content: '',
                                    link: '/product/9/0/yangin_vana_gruplari',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            <figcaption>Genel özellkler</figcaption>
                                            <ul>
                                                <li>Bağlantı Çapı: 1” – 8”</li>
                                                <li>Bağlantı Şekli: Dişli, Wafer, Yivli</li>
                                                <li>Çalışma Basıncı: 300 PSI (21,4 bar)</li>
                                                <li>Test Basıncı: 600 PSI (42,8 bar)</li>
                                                <li>·Çalışma Sıcaklığı: 120°C</li>
                                            </ul>
                                        </figure>


                                    </div>
                                },
                                {
                                    id: 1,
                                    name: 'TEST VE DRENAJ VANASI',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_vana_gruplari/image-258.png'],
                                    content: '',
                                    link: '/product/9/1/yangin_vana_gruplari',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            <figcaption>Genel özellkler</figcaption>
                                            <ul>
                                                <li>Bağlantı Çapı: 1”, 1¼”, 2”</li>
                                                <li>Bağlantı Şekli: Dişli</li>
                                                <li>Çalışma Basıncı: 300 PSI (21,4 bar)</li>
                                                <li>K Orfis: 40, 60, 80, 115, 160, 200, 242, 320, 360</li>
                                            </ul>
                                        </figure>


                                    </div>
                                },
                                {
                                    id: 2,
                                    name: 'AKIŞ ANAHTARI',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_vana_gruplari/image-259.png'],
                                    content: '',
                                    link: '/product/9/2/yangin_vana_gruplari',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            <figcaption>Genel özellkler</figcaption>
                                            <ul>
                                                <li>Bağlantı Çapı: 1”- 2”, 2”- 8”</li>
                                                <li>Bağlantı Şekl: Dşl , U-Bolt</li>
                                                <li>Çalışma Basıncı: 300 PSI (21,4 bar), 450 PSI (31 bar)</li>
                                                <li>Alarm İçn Gereken Deb Aralığı: 4-10 gpm</li>
                                                <li>Maksmum Dalgalanma: 18 fps</li>
                                                <li>Çalışma Sıcaklığı: 0°C- 66°C</li>
                                                <li>Kablo Grş: İk adet açılablr ½” kablo kanalı</li>
                                                <li>Kontak Değerler: İk set spdt 10.0 a@125/250 vac 2.0 a@ 30 vdc 10m a mn @24vdc</li>
                                            </ul>
                                        </figure>


                                    </div>
                                },
                                {
                                    id: 3,
                                    name: 'YÜKSELEN MİLLİ OS&Y SÜRGÜLÜ VANA',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_vana_gruplari/image-253.png'],
                                    content: '',
                                    link: '/product/9/3/yangin_vana_gruplari',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            <figcaption>Genel özellkler</figcaption>
                                            <ul>
                                                <li>Bağlantı Çapı: 1”- 2”, 2½”-12”</li>
                                                <li>Bağlantı Şekl: Dşl , Flanşlı</li>
                                                <li>Çalışma Basıncı: 175 PSI (12 bar), 250 PSI (17,2 bar)</li>
                                                <li>Çalışma Sıcaklığı: -10°C- 120°C</li>
                                            </ul>
                                        </figure>


                                    </div>
                                },
                                {
                                    id: 4,
                                    name: 'ÇEK VANA',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_vana_gruplari/image-255.png'],
                                    content: '',
                                    link: '/product/9/4/yangin_vana_gruplari',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            <figcaption>Genel özellkler</figcaption>
                                            <ul>
                                                <li>Bağlantı Çapı: 1”-2”(Dşl), 2”- 8”(Yvl), 4”-12”(Wafer)</li>
                                                <li>Bağlantı Şekl: Dşl, Yvl , Wafer</li>
                                                <li>Çalışma Basıncı: 175 PSI (12 bar), 300 PSI (20,7 bar)</li>
                                                <li>Çalışma Sıcaklığı: 120°C</li>
                                            </ul>
                                        </figure>


                                    </div>
                                },
                                {
                                    id: 5,
                                    name: 'BASINÇ RAHATLATMA VANASI (RELİEF VANA)',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_vana_gruplari/image-260.png'],
                                    content: '',
                                    link: '/product/9/5/yangin_vana_gruplari',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            <figcaption>Genel özellkler</figcaption>
                                            <ul>
                                                <li>Bağlantı Çapı: ½”-¾”(Dşl), 2”- 10”(Flanşlı)</li>
                                                <li>Bağlantı Şekl: Dişli, Flanşlı</li>
                                                <li>Basınç Sınıfı: 175 PSI max, 400 PSI max.</li>
                                                <li>Çalışma Sıcaklığı: 82°C</li>
                                            </ul>
                                        </figure>


                                    </div>
                                },
                                {
                                    id: 6,
                                    name: 'BASINÇ DÜŞÜRÜCÜ VANA',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_vana_gruplari/image-254.png'],
                                    content: '',
                                    link: '/product/9/6/yangin_vana_gruplari',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            <figcaption>Genel özellkler</figcaption>
                                            <ul>
                                                <li>Bağlantı Çapı: ½”-¾”(Dşl), 2”- 10”(Flanşlı)</li>
                                                <li>Bağlantı Şekl: Dişli, Flanşlı</li>
                                                <li>Basınç Sınıfı: 175 PSI max, 400 PSI max.</li>
                                                <li>Çalışma Sıcaklığı: 82°C</li>
                                            </ul>
                                        </figure>


                                    </div>
                                },
                                {
                                    id: 7,
                                    name: 'DEBİMETRE',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_vana_gruplari/image-261.png'],
                                    content: '',
                                    link: '/product/9/7/yangin_vana_gruplari',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            <figcaption>Genel özellkler</figcaption>
                                            <ul>
                                                <li>·Bağlantı Çapı: 1¼”-2” (Dşl), 2½” – 12” (Alın kaynaklı ve Yivli)</li>
                                                <li>Bağlantı Şekli: Dişli, Alın Kaynaklı, Yivli</li>
                                                <li>Çalışma Basıncı: 500 Psi</li>
                                                <li>Çalışma Sıcaklığı: 82°C</li>
                                            </ul>
                                        </figure>


                                    </div>
                                },
                            ]
    }
},
{
    category: {
        id: 10,
            name: 'YANGIN SPRİNKLERİ',
                imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_sprinkleri/image-262.png'],
                    content: 'UL & FM onaylı tavan tipi(pendent ),duvar tipi(side Wall ),upright tipi  yangın sprinkleri.',
                        link: '/category/yangin_sprinkleri/10/subcategories',
                            subcategories: [
                                {
                                    id: 0,
                                    name: 'UPRİGHT SPRİNK',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_sprinkleri/3.jpg'],
                                    content: '',
                                    link: '/product/10/0/yangin_sprinkleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            <p>Suyun deflektörden yukarı doğru yönlendirildiği şekilde bağlantısı yapılan sprinklerdir.</p>
                                            <figcaption>Genel Özellikler</figcaption>
                                            <ul>
                                                <li>Renk: Krom, Pirinç</li>
                                                <li>Çalışma Sıcaklığı: 57 °C, 68 °C, 79 °C,93 °C,141 °C.</li>
                                                <li>Bağlantı Yapılabilen Ölçüler: ½''- ¾''-1''</li>
                                                <li>Çalışma Basıncı: 175 ps (12,1 bar)</li>
                                            </ul>
                                        </figure>


                                    </div>
                                },
                                {
                                    id: 1,
                                    name: 'SİDEWALL SPRİNKLER (Duvar Tipi)',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_sprinkleri/image-263.png'],
                                    content: '',
                                    link: '/product/10/1/yangin_sprinkleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            <p>Özel deflektöre sahip, yarı parabolik bir şekilde duvardan dışarı doğru boşaltma yapan sprinklerdir. </p>
                                            <figcaption>Genel Özellikler</figcaption>
                                            <ul>
                                                <li>Renk: Beyaz, Krom, Pirinç</li>
                                                <li>Çalışma Sıcaklığı: 57 °C, 68 °C, 79 °C,93 °C,141 °C.</li>
                                                <li>Bağlantı Yapılabilen Ölçüler: ½''- ¾''-1''</li>
                                                <li>Çalışma Basıncı: 175 ps (12,1 bar)</li>
                                            </ul>
                                        </figure>


                                    </div>
                                },
                                {
                                    id: 2,
                                    name: 'PENDENT SPRİNKLER (Sarkık Tipi)',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/yangin_sprinkleri/image-262.png',
                                        process.env.PUBLIC_URL + '/images/products/yangin_sprinkleri/image-264.png',
                                    ],
                                    content: '',
                                    link: '/product/10/2/yangin_sprinkleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            <p>Özel deflektöre sahip, yarı parabolik bir şekilde duvardan dışarı doğru boşaltma yapan sprinklerdir. </p>
                                            <figcaption>Genel Özellikler</figcaption>
                                            <ul>
                                                <li>Renk: Beyaz, Krom, Pirinç</li>
                                                <li>Çalışma Sıcaklığı: 57 °C, 68 °C, 79 °C,93 °C,141 °C.</li>
                                                <li>Bağlantı Yapılabilen Ölçüler: ½''- ¾''-1''</li>
                                                <li>Çalışma Basıncı: 175 ps (12,1 bar)</li>
                                            </ul>
                                        </figure>


                                    </div>
                                },
                                {
                                    id: 3,
                                    name: 'GİZLİ TİP PENDENT',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/yangin_sprinkleri/image-266.png',
                                        process.env.PUBLIC_URL + '/images/products/yangin_sprinkleri/image-267.png',
                                    ],
                                    content: '',
                                    link: '/product/10/3/yangin_sprinkleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            <p>Özel deflektöre sahip, yarı parabolik bir şekilde duvardan dışarı doğru boşaltma yapan sprinklerdir. </p>
                                            <figcaption>Genel Özellikler</figcaption>
                                            <ul>
                                                <li>K-Faktörü: 40.3 (2.8), 57 (4.2), 80 (5.6), 115 (8.0), 160(11.2)</li>
                                                <li>Bulb: 3mm Cam / Ergir Lehim.</li>
                                                <li>Bağlantı Çapı: ½”NPT – ¾”NPT</li>
                                                <li>Sıcaklık: 68 °C, 74 °C, 93°C, 96°C</li>
                                                <li>Yüzey: Pirinç</li>
                                                <li>Kapak Reng: Pirinç, Krom, Beyaz, Siyah</li>
                                                <li>Basınç Sınıfı: 175~250ps </li>
                                                <li>Tehlike Sınıfı: Hafif ve Orta Tehlike</li>
                                                <li>Montaj Şekli: Yere paralel</li>
                                            </ul>
                                        </figure>


                                    </div>
                                },
                            ]
    }
}
    ,
{
    category: {
        id: 11,
            name: 'HİDRANT VE İTFAİYE BAĞLANTI AĞIZLARI ',
                imgsrc: [process.env.PUBLIC_URL + '/images/products/hidrant_ve_itfaiye_baglanti_agizlari/image-284.png'],
                    content: 'TS 2821/1 standardına uygun yangın hidrantları , UL & FM onaylı itfaiye bağlantı ağızları.',
                        link: '/category/hidrant_ve_itfaiye_baglanti_agizlari/11/subcategories',
                            subcategories: [
                                {
                                    id: 0,
                                    name: 'HİDRANT',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/hidrant_ve_itfaiye_baglanti_agizlari/image-281.png',
                                        process.env.PUBLIC_URL + '/images/products/hidrant_ve_itfaiye_baglanti_agizlari/image-284.png',
                                    ],
                                    content: '',
                                    link: '/product/11/0/hidrant_ve_itfaiye_baglanti_agizlari',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>

                                        <figure>
                                            <p>Yer Üstü ve Yer Altı olarak üretilmektedir. </p>

                                        </figure>
                                        <figure>
                                            <figcaption>YER ÜSTÜ YANDIN HİDRANTI BOYUT TABLOSU</figcaption>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ürün Kodu</th>
                                                        <th>DN (Anma Çapı)</th>
                                                        <th>Boyu</th>
                                                        <th>H1 (mm)</th>
                                                        <th>H2 (mm)</th>
                                                        <th>H3 (mm)</th>
                                                        <th>H4 (mm)</th>
                                                        <th>H (mm)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>DT_001_H</td>
                                                        <td>Q 80</td>
                                                        <td>Kısa Boy</td>
                                                        <td>200</td>
                                                        <td>880</td>
                                                        <td>-</td>
                                                        <td>370</td>
                                                        <td>1450</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_002_H</td>
                                                        <td>Q 80</td>
                                                        <td>Uzun Boy</td>
                                                        <td>200</td>
                                                        <td>880</td>
                                                        <td>300</td>
                                                        <td>370</td>
                                                        <td>1750</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_003_H</td>
                                                        <td>Q 100</td>
                                                        <td>Kısa Boy</td>
                                                        <td>200</td>
                                                        <td>880</td>
                                                        <td>-</td>
                                                        <td>370</td>
                                                        <td>1450</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DT_004_H</td>
                                                        <td>Q 100</td>
                                                        <td>Uzun Boy</td>
                                                        <td>200</td>
                                                        <td>880</td>
                                                        <td>300</td>
                                                        <td>370</td>
                                                        <td>1750</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </figure>

                                    </div>
                                },
                                {
                                    id: 1,
                                    name: 'İTFAİYE BAĞLANTI AĞZI (UL&FM ONAYLI)',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/hidrant_ve_itfaiye_baglanti_agizlari/image-278.png',
                                        process.env.PUBLIC_URL + '/images/products/hidrant_ve_itfaiye_baglanti_agizlari/image-280.png',
                                        process.env.PUBLIC_URL + '/images/products/hidrant_ve_itfaiye_baglanti_agizlari/image-279.png',
                                    ],
                                    content: '',
                                    link: '/product/11/1/hidrant_ve_itfaiye_baglanti_agizlari',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <ul>
                                                <li>Bağlantı Şekli: Dişli</li>
                                                <li>Bağlantı Yapılabilen Ölçüler: 4''*2 ½''*2 ½''</li>
                                                <li>Ekipmanlar: Storz Kapak, Damlatma Vanası, Duvar Plakası</li>
                                            </ul>
                                        </figure>


                                    </div>
                                },
                                {
                                    id: 2,
                                    name: 'İTFAİYE BAĞLANTI AĞZI (YERLİ ÜRETİM)',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/hidrant_ve_itfaiye_baglanti_agizlari/image-283.png',

                                    ],
                                    content: '',
                                    link: '/product/11/2/hidrant_ve_itfaiye_baglanti_agizlari',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <ul>
                                                <li>Bağlantı Şekli: Dişli</li>
                                                {/* <li>Bağlantı Yapılabilen Ölçüler: 4''*2 ½''*2 ½'' || 2 ½''*2 ½''* 2 1/2'' || 2 ½''*2 ½''* 2''</li> */}

                                                <li>Bağlantı Yapılabilen Ölçüler
                                                    <ul>
                                                        <od>4''*2 ½''*2 ½''</od>
                                                        <br />
                                                        <od>2 ½''*2 ½''* 2 ½''</od>
                                                        <br />
                                                        <od>2 ½''*2 ½''* 2''</od>
                                                    </ul>
                                                </li>



                                                <li>Ekipmanlar: Storz Kapak</li>
                                            </ul>
                                        </figure>
                                        {/* <figure>
                            <figcaption>Bağlantı Yapılabilen Ölçüler</figcaption>
                            <ul>
                               <li>4''*2 ½''*2 ½''</li>
                               <li>2 ½''*2 ½''* 2 1/2''</li>
                               <li>2 ½''*2 ½''* 2''</li>
                             
                            </ul>
                        </figure> */}

                                    </div>
                                },
                                {
                                    id: 3,
                                    name: 'İTFAİYE SU ALMA AĞZI (TSE BELGELİ)',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/hidrant_ve_itfaiye_baglanti_agizlari/image-277.png',

                                    ],
                                    content: '',
                                    link: '/product/11/3/hidrant_ve_itfaiye_baglanti_agizlari',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <ul>
                                                <li>Bağlantı Şekli: Dişli</li>
                                                <li>Bağlantı Yapılabilen Ölçüler: [2 ½''*2 ½'' 2''] & [2 1/2'' 2'' * 2 '']</li>
                                                <li>Ekipmanlar: Storz Kapak</li>
                                            </ul>
                                        </figure>


                                    </div>
                                },
                                {
                                    id: 4,
                                    name: 'İTFAİYE SU ALMA AĞZI(UL & FM ONAYLI)',
                                    imgsrc: [
                                        process.env.PUBLIC_URL + '/images/products/hidrant_ve_itfaiye_baglanti_agizlari/image-276.png',

                                    ],
                                    content: '',
                                    link: '/product/11/4/hidrant_ve_itfaiye_baglanti_agizlari',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <ul>
                                                <li>Bağlantı Şekli: Dişli</li>
                                                <li>Bağlantı Yapılabilen Ölçüler: 2 ½''*2 ½''</li>
                                                <li>Ekipmanlar: Storz Kapak</li>
                                            </ul>
                                        </figure>


                                    </div>
                                },
                            ]
    }
}
    ,

{
    category: {
        id: 13,
            name: 'YANGIN KORUYUCU EKİPMANLARI',
                imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_koruyucu_ekipmanlari/Nomex-İtfaiyeci-Elbisesi-YANMAZ-mavi-renk.png'],
                    content: 'Yangına dayanıklı eldiven,yangın battaniyeleri,itfaiyeci ekipmanları,solunum setleri.',
                        link: '/category/yangin_koruyucu_ekipmanlari/13/subcategories',
                            subcategories: [
                                {
                                    id: 0,
                                    name: 'Maske ve Filtreler',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_koruyucu_ekipmanlari/image-291.png'],
                                    content: '',
                                    link: false,
                                },
                                {
                                    id: 1,
                                    name: 'Vidalı Bağlantılı Karbon Monoksit Filtresi',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_koruyucu_ekipmanlari/image-292.png'],
                                    content: '',
                                    link: false,
                                },
                                {
                                    id: 2,
                                    name: 'Soğutucu Yelek',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_koruyucu_ekipmanlari/image-293.png'],
                                    content: '',
                                    link: false,
                                },
                                {
                                    id: 3,
                                    name: 'Yangına Dayanaklı Deri İtfaiyeci Eldiveni',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_koruyucu_ekipmanlari/image-295.png'],
                                    content: '',
                                    link: false,
                                },
                                {
                                    id: 4,
                                    name: 'İtfayeci Çizmesi',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_koruyucu_ekipmanlari/image-288.png'],
                                    content: '',
                                    link: false,
                                },
                                {
                                    id: 5,
                                    name: 'İtfaiyeci İç Başlığı(Hood)',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_koruyucu_ekipmanlari/image-289.png'],
                                    content: '',
                                    link: false,
                                },
                                {
                                    id: 6,
                                    name: 'İtfayeci Kaskları',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_koruyucu_ekipmanlari/image-287.png'],
                                    content: '',
                                    link: false,
                                },
                                {
                                    id: 7,
                                    name: 'Yangın Battaniyesi',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_koruyucu_ekipmanlari/image-285.png'],
                                    content: '',
                                    link: false,
                                },
                                {
                                    id: 8,
                                    name: 'Kimyasallara Karşı Koruyucu Giysiler',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_koruyucu_ekipmanlari/image-290.png'],
                                    content: '',
                                    link: false,
                                },
                                {
                                    id: 9,
                                    name: 'İtfaiyeci Kıyafeti',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_koruyucu_ekipmanlari/Nomex-İtfaiyeci-Elbisesi-YANMAZ-mavi-renk.png'],
                                    content: '',
                                    link: false,
                                },
                            ]
    }
}
    ,
{
    category: {
        id: 14,
            name: 'YANGIN POMPALARI ',
                imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_pompalari/image-296.png'],
                    content: 'NFPA 20 standardına ve Türk standartlarına uygun yangın pompaları.',
                        link: '/category/yanginhortumlari/14/subcategories',
                            subcategories: [
                                {
                                    id: 0,
                                    name: 'YANGIN POMPALARI',
                                    imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_pompalari/image-296.png'],
                                    content: '',
                                    link: '/product/14/0/otomatik_davlumbaz_sondurme_sistemleri',
                                    detail: <div className="detail">
                                        <h2>Teknik Detaylar</h2>
                                        <figure>
                                            <figcaption>Yangın Pompası Çeşitleri</figcaption>
                                            <ul>
                                                <li>Uçtan Emişli Yangın Pompaları</li>
                                                <li>Ayrılabilir Gövdeli Santrifüj Yangın Pompaları</li>
                                                <li>Hat Tipi Yangın Pompaları</li>
                                                <li>Dikey milli yangın pompaları</li>
                                            </ul>
                                        </figure>
                                        <figure>
                                            <figcaption>Yangın Pompalarının Çalışma Prensibi</figcaption>
                                            <p>Yangın pompası, yangın sırasında gereken basıncı ve debiyi karşılayacak şekilde seçilmelidir; bu, yangın pompası seçiminde
                                                kritik noktalardan biridir.Yangın pompa sistemleri ana ve yedek pompadan oluşur. Bu anlamda ana yangın pompalarını yedekli
                                                olarak projelendirmek önemli bir detaydır çünkü olası risklerde hep bir B planı gereklidir.Yangın söndürme sistemlerinde
                                                kullanılan yangın pompaları, bir kontrol panosu tarafından kumanda edilir. Her bir yangın pompası için ayrı bir kontrol panosu
                                                bu panolar ilgili standartlara ve yönetmeliklere uygun olarak konumlandırılır.</p>
                                            <p>Yangın söndürme sistemlerinde elektrik motor tahrikli ya da dizel yangın pompaları kullanılabilir.Yangın söndürme tessatındaki
                                                sistem basıncı, yangın pompasına at basınç şalterinden ayarlanan değerin altına düşerse yangın pompası otomatk olarak
                                                devreye girer. Basınç şalterinden sinyal bilgisi gelir ve bu bilgi kontrol panosuna iletilir. Yangın pompası gelen basınç bilgisine
                                                göre devreye girer ve gerektiğinde kontrol panosu üzerinde yer alan durdurma anahtarı ile devre dışı bırakılır.
                                            </p>
                                        </figure>

                                    </div>
                                },
                            ]
    }
}
]

export default data;