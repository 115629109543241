import styled from "styled-components";
import Logo from '../../assets/logo.svg'
const S = {
    Wrapper: styled.footer`
    z-index: 9;
    background-color: #fff9e4;
    /* padding:40px; */
    margin:40px;
    border-radius:20px;
    .copyright{
        // opacity:0.7;
       
        padding: 20px 0px;
        color:black;
        font-size:24px;
        margin:0px 40px;
        text-align:center;
        span{
            text-align:center;
        }
        a{
            text-decoration: none;
            color:white;
            transition: 500ms ease;
            &:visited{
                color:white;
            }
            &:hover{
                color:black;
                transition: 500ms ease;
            }
        }
    }
    p{
        color:white;
        text-align:center;
        width:70%;
    }
    `
}

export default S