import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
function Belediye(props) {
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  return (
    <AccordionDetails>
      <Typography>
        <ul>
          {data.map((x, i) => {
            return <li>{x}</li>
          })}
        </ul>
      </Typography>
    </AccordionDetails>
  );
}
const data = [
  "SOUTHWİND AİRLİNES (CORTEX HAVACILIK)",
  "4U ANT HAVACILIK",
  "AQUASUN TURİZM A.Ş.",
  "AKAY TURİZM",


]
export default Belediye;