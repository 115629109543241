import React from 'react';
import { Link } from 'react-router-dom';
import S from './style'
import Logo from '../../assets/logo.svg'
import Insta from '../../assets/instagram.png'
import Whatsapp from '../../assets/whatsapp.png'
import TranslateStatic from '../../locales/TranslateStatic'
function Footer(props) {
    return (
        <S.Wrapper>
            <S.Memnun>
                <div className='main'>
                    <Link>Memnuniyet Anketi</Link>

                </div>
            </S.Memnun>
            <S.Ust>
                <div>
                    <S.Logo src={Logo} alt="dogantan logo" />
                    <p>Firmamız Antalya'da kurulmuş bir yangın söndürme ve yangın algılama ve ihbar sistemi firması olup, 
                        yangın söndürme cihazları satış ve bakım, yangın algılama sistemi satış ve bakım, yangın dolapları satış, 
                        yangın kapısı satış ve uygulama hizmetleri sunmaktadır. </p>
                    <div className='socialMedia'>
                        <a href='https://www.instagram.com/dogantanyangin/' target='_blank'>
                            <img src={Insta} />

                        </a>
                        <a href="whatsapp://send?phone=+905316425793">

                            <img src={Whatsapp} />
                        </a>
                    </div>
                </div>
                <div>
                    <h2><TranslateStatic string="contactH2" /></h2>
                    <ul>
                        <li><b><TranslateStatic string="contactAddress" /></b> : 3189/4. Sk. Sanayi No:15, 07090 Kepez / <b>ANTALYA</b></li>
                        <li><b><TranslateStatic string="contactMail" /></b> : info@dogantanyangin.com </li>
                        <li><b><TranslateStatic string="contactPhone" /></b> : 0 531 642 57 93 - 0 532 523 07 16</li>
                    </ul>
                    <br></br>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12756.75049701164!2d30.6458726!3d36.9336835!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c38fa3bd97ee31%3A0x2c570f6c93b188d6!2sDo%C4%9Fan%20Tan%20Yang%C4%B1n%20ve%20G%C3%BCvenlik%20Sistemleri!5e0!3m2!1str!2str!4v1695849163927!5m2!1str!2str"
                        allowfullscreen="" loading="lazy">

                    </iframe>
                </div>
            </S.Ust>
            <div className="copyright">
                <span><TranslateStatic string="contactCopyright" /></span>
                <span><TranslateStatic string="contactWebAuthor" /> : <a href="https://ekipx.com/" target="_blank">ekipx.com</a></span>
            </div>
        </S.Wrapper>
    );
}

export default Footer;