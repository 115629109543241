import styled from "styled-components";

const S = {
    Wrapper: styled.section`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    grid-template-areas:"headerleft headerright"
    "content content";
    @media (max-width:648px){
        grid-template-columns: 1fr;
    }
    `,
    Top: styled.div`
    grid-area: headerleft;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:0px 40px;
    align-items: left;
    // background-color: #d41031;
    @media (max-width:648px){
        padding:20px;
    }
    `,
    Header: styled.h1`
    color:black;
    font-size: 20px;
    // background-color: #d41031;
    margin:0;
    /* padding:40px 40px 10px 40px; */
    @media (max-width:648px){
        font-size: 16px;
    }
    `,
    Span: styled.div`
    color:black;
    font-size: 14px;
    // background-color: #d41031;
    margin:0;
    text-transform: capitalize;
    a{
        color:black;
        text-decoration: none;
    }
    /* padding: 10px 40px 40px 40px; */
    `,
    Section:styled.section`
    margin: 40px;
    grid-area: content;
    `,
    Sertifika: styled.div`
    grid-area: headerright;
    // background-color: #d41031;
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding:0px 40px;
    align-items: center;
    @media (max-width:648px){
        padding:20px;
    }
    svg,img{
        margin:5px;
        width:50px;
        height: 70px;
        filter: invert(100%);
        @media (max-width:648px){
            width:30px;
        height: 30px;
    }
    }
    `,
}

export default S