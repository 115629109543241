
import { Link } from 'react-router-dom';
import S from './style'
// import Image1 from '../../assets/sertification/tse.svg'
import Certification from '../../components/certification';
import { ReactComponent as Tse } from '../../assets/sertification/tsechoose.svg'
import { ReactComponent as Ce } from '../../assets/sertification/ce.svg'
import Iso from '../../assets/sertification/isopng.png'
import TranslateStatic from '../../locales/TranslateStatic';
export function PageTwo() {
  return (
    <S.Wrapper>
      <S.Top>
        <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header>
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <Link><TranslateStatic string="aboutUsHeader" /></Link>
        </S.Span>
      </S.Top>
      <S.Sertifika>
        <Certification />
      </S.Sertifika>
      <S.Section>
        <p>
          <TranslateStatic string="aboutUsP1" />
        </p>
        <h3><TranslateStatic string="aboutUsh3-1" /></h3>
        <p>
          <TranslateStatic string="aboutUsP2" />
        </p>
        <h3><TranslateStatic string="aboutUsh3-2" /></h3>
        <p>
          <TranslateStatic string="aboutUsP3" />
        </p>
        <h3><TranslateStatic string="aboutUsh4-1" /></h3>
        <p>
          <TranslateStatic string="aboutUsP4-1" />
        </p>
        <p>
          <TranslateStatic string="aboutUsP4-2" />
        </p>
      </S.Section>
    </S.Wrapper >
  );
}
