import styled from "styled-components";

const S = {
    Wrapper:styled.div`
    display:flex;
    flex-direction:row;
    padding:200px;
    justify-content:space-evenly;
    `,
    Kare:styled.div`
    position: relative;
    width:400px;
    height:400px;
    background:#d41031;
    border-radius:20px;
    `,
    Abss:styled.div`
    position:absolute;
    z-index:1;
    left:-120px;
    top:-120px;
    `,
    Object:styled.img`
    // transform: rotate(20deg);
   
    width:600px;
    filter: drop-shadow(-80px 40px 60px rgba(0, 0, 0, 0.53));
    // transition: width 2s, height 2s, transform 1s;


    // &:hover{
    //     transition:transform 0.5;
    //     transform:rotate(10deg);
    // }
    `,
    Sag:styled.div`
        flex-basis:50%;
        display:flex;
        flex-direction:column;
        justify-content:space-evenly;
        h1{
            font-size:3em;
            color:rgb(212, 16, 49);
        }
    `,
  
}

export default S;