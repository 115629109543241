import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
function Belediye(props) {
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  return (
    <AccordionDetails>
      <Typography>
        <ul>
          {data.map((x, i) => {
            return <li>{x}</li>
          })}
        </ul>
      </Typography>
    </AccordionDetails>
  );
}
const data = [
  "Mehmet Akif Ersoy Anaokulu",
  "Antalya Koleji Eğitim Tesisleri A.Ş.",
  "Başarı Koleji / Manavgat",
  "A.Ü. Fen Bilimleri Fak.",
  "A.Ü. Beden Eğitimi M.Y.O.",
  "A.Ü. Sosyal Bilimler Fak.",
  "Yavuz Selim Lisesi",
  "Güzelbağ İlköğretim Okulu",
  "ATSO Güzel Sanatlar Lisesi",
  "Çağlayan Lisesi",
  "Kemer Lisesi",
  "İmam Hatip Lisesi",
  "Hacıveliler İlköğretim Okulu",
  "Atatürk İlköğretim Okulu",
  "İnönü İlköğretim Okulu",
  "Başöğretmen Atatürk İlköğretim Okulu",
  "H. Tatoğlu İlköğretim Okulu",
  "Kaan Turan İlköğretim Okulu",
  "Dr. Galip Kahraman İlköğretim Okulu",
  "Fikret Haluk Saraçoğlu İlköğretim Okulu",
  "Çalkaya Güzelyurt İlköğretim Okulu",
  "Perihan Esat İlköğretim Okulu",
  "Yıldırım Beyazıt İlköğretim Okulu",
  "Vali Hüsnü Tuğlu İlköğretim Okulu",
  "Kocademir İlköğretim Okulu",
  "Arslanlar İlköğretim Okulu",
  "Dumlupınar İlköğretim Okulu",
  "Güneş Dershaneleri / Şirinyalı ve Bahçelievler Şb.",
  "Kitle Dershanesi",
  "Şarampol Dershanesi",
  "Papatyam Çocuk Yuvası",
  "Zafer Anaokulu",
  "Akdeniz Yükseliş Koleji / Serik (Serik Öz.Eğ.Tes. A.Ş.)",
  "MEB İl Eğitim Araçları (Akşam Sanat Okulu)",
  "A.Ü. Turizm İşletmecilik Fak.",
  "A.Ü. İktisadi Bilimler Fak.",
  "A.Ü. Ziraat Fakültesi",
  "Karatay Lisesi",
  "Atatürk Endüstri Meslek Lisesi",
  "Yusuf Ziya Öner Fen Lisesi",
  "İhsan Koz İlköğretim Okulu",
  "Ramazan Savaş İlköğretim Okulu",
  "Gazi Mustafa Kemal İlköğretim Okulu",
  "Cumhuriyet İlköğretim Okulu",
  "Güzeloba İlköğretim Okulu",
  "Ergenekon İlköğretim Okulu",
  "Merkez İlköğretim Okulu",
  "Yenigöl İlköğretim Okulu",
  "Halit Narin İlköğretim Okulu",
  "Kâzım Şenöz İlköğretim Okulu",
  "Arslanlarbucak İlköğretim Okulu",
  "Ayanoğlu İlköğretim Okulu",
  "Altındağ İlköğretim Okulu",
  "Emekevler İlköğretim Okulu",
  "Güzelyalı İlköğretim Okulu",
  "Barbaros İlköğretim Okulu",
  "Hüseyin Ak İlköğretim Okulu",
  "Şehit Kahraman Çelikbaş İlköğretim",
  "Açı Dershanesi",
  "Maç Dershanesi",
  "Baraj İlköğretim Okulu",
  "Yeşil Balon Çocuk Yuvası",


]
export default Belediye;