import styled from "styled-components";
import Logo from '../../assets/logo.svg'
import FireImage from '../../assets/sliderImage/new/2.png'
const S = {
    Wrapper: styled.footer`
    display: flex;
    flex-direction: column;
    z-index: 9;
    background-color: #d41031;
    /* padding:40px; */
    .copyright{
        opacity:0.7;
        display: flex;
        padding: 20px 0px;
        flex-direction: row;
        justify-content: space-between;
        // background-color: #ff4b66;
        border-top:1px solid white;
        color:white;
        font-size:12px;
        margin:0px 40px;
        a{
            text-decoration: none;
            color:white;
            transition: 500ms ease;
            &:visited{
                color:white;
            }
            &:hover{
                color:black;
                transition: 500ms ease;
            }
        }
    }
    p{
        color:white;
        width:70%;
    }
    `,
    Ust: styled.div`
    /* background-image: linear-gradient(white,white),
    url(${Logo}); */
    /* background-blend-mode: saturation; */
    padding: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* flex-direction: row; */
    /* justify-content: space-between; */
    grid-gap: 40px;
    margin-bottom: 20px;
    height: auto;
    position: relative;
    font-size:14px;
    @media (max-width:648px){
        grid-template-columns: 1fr;
            }
    &::after{
        content: "";
        background-size:cover;
        background-image: url(${Logo});
        position:absolute;
        top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;  
      /* filter: opacity(0.5); */
      filter: contrast(200%) brightness(150%);
      opacity: 0.1;
    }
    &::before{
        // filter: grayscale(0.5);
        filter: contrast(200%) brightness(150%);
    }
    h2{
        color:white;
    }
    iframe{
        border: none;
        width: 100%;
        height: 150px;
        border-radius:10px;
    }
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        color:white;
        li{
            padding-top: 10px;
        }
    }
    .socialMedia{
        display:flex;
        flex-direction:row;
        width:50px;
        justify-content:space-between;
        a{
            // margin:10px; 
            img{
                width:20px;
            }
        }
        
    }
    `,
    Img: styled.img`
    width:100%;
    object-fit:cover;
    position: absolute;
    z-index:-10;
    `,
    Logo: styled.img`
    width:350px;
    grid-area: logo;
    padding:20px 10px;
    filter: brightness(100);
    `,
    Memnun: styled.div`
    background-color:#f1f1f1;
    padding:20px 20px;
    display:flex;
    flex-direction:colmun;
    justify-content:center;
    .main{
        background-image: url(${FireImage});
        background-size: cover;
        background-position-y: 73%;
        background-repeat: no-repeat;
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
        width:800px;
        padding:20px 25px;
        border-radius:40px;
        box-shadow:  20px 20px 60px #bebebe,
             -20px -20px 60px #ffffff;
             transition:500ms;
        &:hover{
            box-shadow:  20px 40px 80px #bebebe,
            -20px -20px 60px #ffffff;
            transition:500ms;
            }
        a{
            background:black;
            border-radius:25px;
            padding:20px 35px;
            text-decoration:none;
            color:white;
            transition:500ms;
            &:hover{
                background-color:white;
                color:black;
                transition:500ms;
            }
        }
    }
    `
}

export default S