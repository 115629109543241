import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
function Belediye(props) {
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  return (
    <AccordionDetails>
      <Typography>
        <ul>
          {data.map((x, i) => {
            return <li>{x}</li>
          })}
        </ul>
      </Typography>
    </AccordionDetails>
  );
}
const data = [
  "A.Ü. DİŞ HEKİMLİĞİ FAKÜLTESİ",
  "A.Ü. FEN BİLİMLERİ FAKÜLTESİ",
  "A.Ü. SOSYAL BİLİMLER FAKÜLTESİ",
  "A.Ü. MESLEK YÜKSEK OKULU FAKÜLTESİ",
  "A.Ü. TURİZM İŞLETMECİLİK FAKÜLTESİ",
  "A.Ü. İKTİSADİ BİLİMLER FAKÜLTESİ",
  "A.Ü. ZİRAAT FAKÜLTESİ",
  "A.Ü. EDEBİYAT FAKÜLTESİ",
  "ATSO GÜZEL SANATLAR LİSESİ",
  "AKDENİZ YÜKSELİŞ KOLEJİ /SERİK",
  "AÇI OKULLARI VE DERSHANESİ",
  "HAMZA TAŞ İMAM HATİP ORTAOKULU",
  "NADİRE KONUK VE ALİ OĞUZ KONUK İLKOKULU",



]
export default Belediye;