import styled from "styled-components";

const S = {
    Wrapper: styled.header`
    position: ${props => props.fontColor ? "absolute" : "relative"};
    // position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display:grid;
    box-sizing:border-box;
    grid-template-columns: 200px 2fr 100px;
    grid-template-rows: auto;
    grid-template-areas:"headertop headertop headertop"
    "logo headerbottomcenter headerbottomright";
    flex-direction:row;
    justify-content: space-between;
    // background-color:#f1f1f1;
    padding: 5px 40px;
    // -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.31); 
    // box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.31);
    z-index: 10;
    margin:20px 0px;
    // border-bottom:1px solid #D41031;
    border-radius:10px;
    @media (max-width:648px){
        display: flex;
        justify-content: space-between;
  align-items: center;
    }
    `,
    Logo: styled.img`
    width:300px;
    grid-area: logo;
    padding:20px 10px;
    `,
    MobileMenu: styled.div`
    display: none;
    @media (max-width:648px){
        display: block;
    }
    `,
    Nav: styled.ul`
    margin:0;
    margin-left:20px;
    padding: 0;
    vertical-align: middle;
    grid-area: headerbottomcenter;
    /* padding: 10px 0px; */
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-content: center;
    align-items: center;
    
    li{
        list-style: none;
        padding: 0 10px;

        color:${props => props.fontColor ? "white" : "black"};
        font-size:14px;
        a{
            text-decoration: none;
            font-weight: 800;
            color:${props => props.fontColor ? "white" : "black"};
            &:visited{
                color:${props => props.fontColor ? "white" : "black"};
            }
            &:hover{
                color:#D41031;
            }
        }
        &:nth-child(3){
           &::after{
               /* content: "v"; */
               /* content: "▼"; */
        }
        }
        
    }
    #dropdown {
            position: relative;
            display: inline-block;
            text-decoration: none;
            font-weight: 800;
            color:${props => props.fontColor ? "white" : "black"};
           
            &:hover{
                .dropdown-content {
                    z-index:1000;
                    /* display: block; */
                    display: flex;
                    flex-direction: column;
                    a{
                        margin: 10px 0px;
                    }
                }
            }
        }
        .dropdown-content {
            display: none;
            position: absolute;
            background-color: #f9f9f933;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            padding: 12px 16px;
            z-index: 1;
            border-radius:10px;
            
        }
    @media (max-width:648px){
        display: flex;
        width: 100%;
        height: 350px;
        position: absolute;
        top: 91px;
        left: -100%;
        opacity: 0;
        transition: all 0.5s ease;
        flex-direction: column;
        list-style-type: none;
        grid-gap: 0px;
        margin:0px;
        z-index: 20;
        ${props => {
            if (props.click) {
                return `
                background: white;
                left: 0;
                opacity: 1;
                transition: all 0.5s ease;
                z-index: 20;
                align-content: center;
                padding-left: 0px;
                `
            }
        }}
        /* .active {
            background: #9899d1;
            left: 0;
            opacity: 1;
            transition: all 0.5s ease;
            z-index: 1;
            align-content: center;
            padding-left: 0px;
        } */
        }
    `,
    Search: styled.div`
     grid-area: headerbottomright;
     display: flex;
    justify-content:flex-end;
    flex-direction: row;
    align-content: center;
    align-items: center;
    position: relative;
    li{
        list-style:none;
        a{
            text-decoration:none;
            border-radius: 5px;
            border:none;
            padding:10px;
            background-color: #d41031;
            color:white;
            font-size:14px;
        }
    }
    input{
        // border: 1px solid #D41031;
        border-radius: 5px;
        border:none;
        padding:10px;
        background-color: #d41031;
        width:120px;
        color:white;
        &::placeholder {
            font-size:24px;
            color: white;
            opacity: 1; /* Firefox */
          }
    }
    .lang{
        margin: 0 5px;
        ul{

            display: flex;
            flex-direction: row;
            list-style: none;
            li{
                margin: 0 5px;
            }
        }
    }
    @media (max-width:648px){
        display: none;
    }
    `,
    SearchDropdown: styled.ul`
        display: ${props => props.display?'flex':'none'};
        position: absolute;
        padding: 0;
        width:250px;
        height: 350px;
        z-index: 1000;
        background-color: white;
        overflow-y: auto;
        top:60px;
        flex-direction: column;
        list-style: none;
        li{
            margin:2px 0;
            padding: 10px;
            a{
                text-decoration: none;
                color:black;
            }
        }
    `,
    Opener: styled.div`
    display:${props => props.display};
    position:absolute;
    border-radius:4px;
    /* margin-top:150px; */
    top:70px;
    background-color:white;
    padding:10px;
    max-height:400px;
    overflow-y:auto;
    &::-webkit-scrollbar {
            width: .5em;
            }
    
            &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            }
            &::-webkit-scrollbar-thumb:hover{
            transition: 300ms;
            background-color: black;
            }
    
            &::-webkit-scrollbar-thumb {
            transition: 300ms;
            background-color: #009900;
            outline: 1px solid #009900;
            }
    
    `
}

export default S