import React from 'react';
import Otels from './otels'
import Belediye from './belediye'
import Hastane from './hastane'
import Insaat from './insaat'
import Fabrika from './fabrika'
import Resmi from './resmi'
import Banka from './banka'
import Tarim from './tarim'
import Havayollari from './havayollari'
import Akaryakit from './akaryakit'
import Okul from './okul'
import Diger from './diger'
import Yanginguvenlik from './yanginguvenlik'
const Tabs = {
    Otels,
    Belediye,
    Hastane,
    Insaat,
    Fabrika,
    Resmi,
    Banka,
    Tarim,
    Havayollari,
    Akaryakit,
    Okul,
    Diger,
    Yanginguvenlik
}
export default Tabs;