import styled from "styled-components";
import Logo from '../../assets/logo.svg'
const S = {
    Wrapper: styled.footer`
    z-index: 9;
    // background-color: #002a32;
    background-image: linear-gradient(to right, #d41031, #e04152, #e86271, #ed7f8e, #f09baa);
    /* padding:40px; */
    // margin:40px;
    // border-radius:20px;
    .copyright{
        // opacity:0.7;
       
        padding: 20px 0px;
        color:white;
        font-size:12px;
        margin:0px 40px;
        text-align:center;
        span{
            text-align:center;
        }
        .call{
            background:white;
            padding:10px;
            margin-left:20px;
            border-radius:10px;
            &:hover{
                background:#93faa5;
            }
        }
        a{
            text-decoration: none;
            color:white;
            transition: 500ms ease;
            &:visited{
                color:white;
            }
            &:hover{
                color:black;
                transition: 500ms ease;
            }
        }
    }
    p{
        color:white;
        text-align:center;
        width:70%;
    }
    `
}

export default S