import styled from "styled-components";

const S = {
    SlideWrap: styled.div`
    background-color:#f1f1f1;
    // margin:100px;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
    align-content: flex-start;
    align-items:flex-start;
    @media (max-width:648px){
        margin:20px;
        }
    .baslik{
        background:none;
        width: 100%;
        box-shadow: none;
        display:flex;
        flex-direction:column;

    }
    a{
        font-size:12px;
        padding: 10px 20px;
        font-weight:bold;
        // background-color: #002A32;
        // text-decoration: none;
        color:#002A32;
        margin: 50px 0px;
        width: 250px;
        align-self: center;
        text-align:center;
        border-radius: 10px;
        transition: 500ms;
        &:hover{
            letter-spacing: 2px;
            width:260px;
            transition: 500ms;
            opacity: .8;
        }
    }

    div{
        width: 15%;
        box-sizing: border-box;
        overflow:hidden;
        background:white;
        border-radius:10px;
        height:150px;
        display:flex;
        flex-direction column;
        align-items:center;
        justify-content:center;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        img{
            width:150px;
            height: 50px;
            object-fit: contain;
            // filter: grayscale(1);
            @media (max-width:648px){
        width: 70px;
            }
        }
        .gray{
            filter: brightness(0);
        }
    }
    p{
        font-size:12px;
        font-weight: 500;
        margin:0px 40px 50px;
        width:40%;
        text-align:center;
        color:#002A32;
    }
    h2{
        margin:80px 40px 10px;
        text-align:center;
            z-index:2;
            color:#002A32;
            // margin:40px;
            /* text-shadow: -2px 0px 7px rgba(0,0,0,0.91); */
        }
       
        h2{
            font-size: 32px;
            @media (max-width:648px){
                font-size:30px;
            }
        }
    `,
    Img: styled.img`
    /* width:100%; */
    width: 100px;
    object-fit:cover;
    position: absolute;
    z-index:-10;
    filter: grayscale(1);
    @media (max-width:648px){
        width: auto;
            }
    `,
}

export default S