import React from 'react';
import S from './style'
import Logo from '../../assets/logo.svg'
import TranslateStatic from '../../locales/TranslateStatic'
function Callmini(props) {
    return (
        <S.Wrapper>
           
            <div className="copyright">
                <span><TranslateStatic string="callinfo" /><a className='call' href="tel:05316425793">📞</a></span>
                {/* <span><TranslateStatic string="contactWebAuthor" /> : <a href="https://ekipx.com/" target="_blank">ekipx.com</a></span> */}
            </div>
        </S.Wrapper>
    );
}

export default Callmini;