import React, { Component } from "react";
import Image1 from '../../assets/sliderImage/new/1.png'
import Image2 from '../../assets/sliderImage/new/2.png'
import Image3 from '../../assets/sliderImage/new/3.png'
import Image4 from '../../assets/sliderImage/new/4.jpg'
import Image5 from '../../assets/sliderImage/new/5.jpg'
import Image6 from '../../assets/sliderImage/new/6.jpg'
import Image7 from '../../assets/sliderImage/new/7.jpg'
import Image8 from '../../assets/sliderImage/new/8.jpg'
import Image9 from '../../assets/sliderImage/new/9.jpg'
import Image10 from '../../assets/sliderImage/new/10.jpg'
import Image11 from '../../assets/sliderImage/new/11.jpg'
import Image12 from '../../assets/sliderImage/new/12.jpg'
import Image13 from '../../assets/sliderImage/new/13.jpg'
import S from './style'
class Carousel extends React.Component {
  constructor(props) {
    super(props);

    this.prevItem = this.prevItem.bind(this);
    this.nextItem = this.nextItem.bind(this);

    this.state = {
      counter: 0,
      timeout: setTimeout(this.nextItem, this.props.timeoutTime)
    };
  }

  prevItem() {
    var prevItem = this.state.counter - 1 < 0 ? this.props.items.length - 1 : this.state.counter - 1;

    clearTimeout(this.state.timeout);
    this.setState({
      counter: prevItem,
      timeout: setTimeout(this.nextItem, this.props.timeoutTime)
    });
  }

  nextItem() {
    var nextItem = this.state.counter + 1 < this.props.items.length ? this.state.counter + 1 : 0;

    clearTimeout(this.state.timeout);
    this.setState({
      counter: nextItem,
      timeout: setTimeout(this.nextItem, this.props.timeoutTime)
    });
  }

  render() {
    const items = this.renderItems(this.props.items);

    return (
      <S.carousel>
        <S.carouselPrev onClick={this.prevItem}></S.carouselPrev>
        { items }
        <S.carouselNext onClick={this.nextItem}></S.carouselNext>
      </S.carousel>
    );
  }

  renderItems(items) {
    return items.map((item, index) => this.renderItem(item, (this.state.counter === index)));
  }

  renderItem(item, current) {
    return (
      <CarouselItem {...item} current={current}></CarouselItem>
    );
  }
}

class CarouselItem extends React.Component {
  render() {    
    return (
      <S.carouselItem className={(this.props.current ? 'current' : '')}>
        <img src={this.props.image} role="presentation" />
        <S.carouselHeading className={(this.props.current ? '' : 'show')}>
          <h2>{this.props.title}</h2>
          {/* <h3>{this.props.caption}</h3> */}
        </S.carouselHeading>
      </S.carouselItem>
    );
  }
}

const carouselData = {
      timeoutTime: 5000,
      items: [
        // {
        //   key: 1,
        //   image: Image1,
        //   title: 'Slide #1',
        //   caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        // },
        // {
        //   key: 2,
        //   image: Image2,
        //   title: 'Slide #2',
        //   caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        // },
        // {
        //   key: 3,
        //   image: Image3,
        //   title: 'Slide #3',
        //   caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        // },
        {
          key: 4,
          image: Image4,
          title: 'Yangın Güvenliği İçin Kaliteli ve Dayanıklı Ekipmanlar',
          caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
          key: 5,
          image: Image5,
          title: 'Yangın Teklikesine Karşı Kapsamlı Koruma Sunuyoruz',
          caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
          key: 6,
          image: Image6,
          title: 'Yangın Güvenliği İçin Bilinçli ve Hazır Olun',
          caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
          key: 7,
          image: Image7,
          title: 'Yangın Risklerini Belirlemek ve Çözüm Sunmak İçin Yanınızdayız',
          caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
          key: 8,
          image: Image8,
          title: 'Yangın Güvenliğinizi Bizimle Güvence Altına Alın',
          caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
          key: 9,
          image: Image9,
          title: 'Profesyonel Yangın Alarm Cihazları ve Bakım Hizmetleri',
          caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
          key: 10,
          image: Image10,
          title: 'Yangın Güvenliği İçin Ekipman Kontrolleri ve Bakımı',
          caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
          key: 11,
          image: Image11,
          title: 'Yangınla Mücadelede Her Zaman Hazırız',
          caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
          key: 12,
          image: Image12,
          title: 'Profesyonel Yangın Söndürme Cihazı Denetimi ve Bakım Hizmetleri',
          caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
          key: 13,
          image: Image13,
          title: '',
          caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        }
      ]
    };

function slid (){

  return <Carousel {...carouselData} />
}

export default slid