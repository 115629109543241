import React from 'react';
import { Link } from 'react-router-dom'
import S from './style'
import Image1 from '../../assets/products/fireextinguisher.jpeg'
import Image2 from '../../assets/products/firecabinet.jpg'
import Image5 from '../../assets/products/firecabinetV2.jpg'
import Image3 from '../../assets/products/firealarm.jpg'
import Image4 from '../../assets/products/yangin-kapisi.jpg'
import Image6 from '../../assets/products/fireex1.jpg'
import Image7 from '../../assets/products/firealarm1.jpg'
import Image8 from '../../assets/products/firedoor1.png'
import TranslateStatic from '../../locales/TranslateStatic'
function Product(props) {
    return (
        <>
            <S.Baslik><TranslateStatic string="ourFeaturedProducts" /></S.Baslik>
            <S.Wrapper>
                <Link to="/category/kuru_kimyevi_tozlu_yangin_söndürme_cihazlari/5/subcategories" className="box">
                    <img src={Image6} alt="" />
                    <h2><TranslateStatic string="ourFeaturedProductsItem1H2" /></h2>
                    <p><TranslateStatic string="ourFeaturedProductsItem1P" /></p>
                </Link>
                <Link to="/category/adresli_yangin_alarm_sistemleri/6/subcategories" className="box">
                    <img src={Image7} alt="" />
                    <h2><TranslateStatic string="ourFeaturedProductsItem3H2" /></h2>
                    <p><TranslateStatic string="ourFeaturedProductsItem3P" /></p>
                </Link>
                <Link to="/category/yangindolaplari/0/subcategories" className="box">
                    <img src={Image5} alt="" />
                    <h2><TranslateStatic string="ourFeaturedProductsItem2H2" /></h2>
                    <p><TranslateStatic string="ourFeaturedProductsItem2P" /></p>
                </Link>
                <Link to="/category/yanginhortumlari/3/subcategories" className="box">
                    <img src={Image8} alt="" />
                    <h2><TranslateStatic string="ourFeaturedProductsItem4H2" /></h2>
                    <p><TranslateStatic string="ourFeaturedProductsItem4P" /></p>
                </Link>
            </S.Wrapper>
        </>
    );
}

export default Product;