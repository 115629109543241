import React from 'react';
import S from './style'
import Logo from '../../assets/logo.svg'
import TranslateStatic from '../../locales/TranslateStatic'
function Miniinfo(props) {
    return (
        <S.Wrapper>
           
            <div className="copyright">
                <span><TranslateStatic string="miniinfo" /></span>
                {/* <span><TranslateStatic string="contactWebAuthor" /> : <a href="https://ekipx.com/" target="_blank">ekipx.com</a></span> */}
            </div>
        </S.Wrapper>
    );
}

export default Miniinfo;