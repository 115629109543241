import { ReactComponent as Tse } from '../../assets/sertification/tsechoose.svg'
import { ReactComponent as Ce } from '../../assets/sertification/ce.svg'
import { ReactComponent as Iso } from '../../assets/sertification/iso.svg'
// import Iso from '../../assets/sertification/isopng.png'
export default () => (
    <>
        <Tse fill='white' color="white" />
        <Ce fill='white' color="white" />
        <Iso fill='white' color="white" />
        {/* <img src={Iso} alt="" /> */}
    </>
)