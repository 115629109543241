import styled from "styled-components";

const S = {
    Wrapper: styled.section`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    grid-template-areas:"headerleft headerright"
    "content content";
    @media (max-width:648px){
        grid-template-columns: 1fr;
    }
    `,
    Top: styled.div`
    grid-area: headerleft;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:0px 40px;
    align-items: left;
    // background-color: #d41031;
    @media (max-width:648px){
        padding:20px;
    }
    `,
    Header: styled.h1`
    color:black;
    font-size: 20px;
    // background-color: #d41031;
    margin:0;
    /* padding:40px 40px 10px 40px; */
    @media (max-width:648px){
        font-size: 16px;
    }
    `,
    Span: styled.div`
    color:black;
    font-size: 14px;
    // background-color: #d41031;
    margin:0;
    text-transform: capitalize;
    a{
        color:black;
        text-decoration: none;
    }
    /* padding: 10px 40px 40px 40px; */
    `,
    Sertifika: styled.div`
    grid-area: headerright;
    // background-color: #d41031;
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding:0px 40px;
    align-items: center;
    @media (max-width:648px){
        padding:20px;
    }
    svg,img{
        margin:5px;
        width:50px;
        height: 70px;
        filter: invert(100%);
        @media (max-width:648px){
            width:30px;
        height: 30px;
    }
    }
    `,
    Section: styled.section`
    margin: 40px;
    grid-area: content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 40px;
    align-content: center;
    align-items: center;
    @media (max-width:648px){
        grid-template-columns: 1fr;
        margin:20px;
    }
    .item-wrapper{
        width: 100%;
        display: flex;
        box-sizing:border-box;
        flex-direction: row;
        justify-content: stretch;
        align-content: stretch;
        align-items: stretch;
        height: 400px;
        padding: 40px;
        background-color: #f1f1f1; 
        /* -webkit-box-shadow: 9px 15px 22px 5px rgba(0,0,0,0.16); 
        box-shadow: 9px 15px 22px 5px rgba(0,0,0,0.16); */
        // border: 2px solid #e5e5e5;
        border-radius: 15px;
        transition: 200ms;
        &:hover{
            transition: 200ms;
            -webkit-box-shadow: 9px 15px 22px 5px rgba(212,16,49,0.16); 
            box-shadow: 9px 15px 22px 5px rgba(212,16,49),0.16);
            border: 0px solid #e5e5e5;
        }
        .item-image{
            width: 50%;
            padding-right: 40px;
            box-sizing:border-box;
            img{
                width: 100%;
                object-fit: contain;
                height: 100%;
            }
            @media (max-width:648px){
              height: 100px;
            }
        }
        article{
            flex: 1;
            display: flex;
            flex-direction: column;
            align-content:space-between;
            justify-content: space-between;
            align-items: flex-end;
            h2{
                text-align:left;
                margin: 0;
            }
            a{
                padding: 20px;
                /* color:white; */
                color:#d41031;
                /* background-color: #d41031; */
                border-bottom-right-radius: 15px;
                font-weight: 500;
                font-size: 20px;
                border:1px solid #d41031;
                /* background-color: #d41031; */
                background-color: white;
                transition: 200ms;
                
                text-decoration:none;
                &:visited{
                    color:#d41031;
                }
                svg{
                    vertical-align: middle;
                    transition: 200ms;
                }
                &:hover{
                    transition: 200ms;
                    background-color: #d41031;
                    color:white;
                    svg{
                        transition: 200ms;
                        margin-left:10px;
                    }
                }
                @media (max-width:648px){
                    margin:20px;
                    
                }
            }
        }
        @media (max-width:648px){
            padding:20px;
            /* height: auto; */
            flex-direction: column;
        }
    }
    `,
}

export default S