import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
function Belediye(props) {
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  return (
    <AccordionDetails>
      <Typography>
        <ul>
          {data.map((x, i) => {
            return <li>{x}</li>
          })}
        </ul>
      </Typography>
    </AccordionDetails>
  );
}
const data = [
  "Cangarlar Otomotiv Tic. A.Ş.",
  "Bilaller Otomotiv Otomotiv / Sarısu",
  "Balabanlı Makine ve Otomotiv Ltd. Şti.",
  "Suna-İnan Kıraç Eğitim Parkı",
  "Erciyes Elektrik Ltd. Şti.",
  "As Çimento / Bucak - BURDUR",
  "Ado Hazır Beton Ltd. Şti.",
  "Çimsa Hazır Beton A.Ş.",
  "Divarcılar Hazır Beton",
  "Arıkanlar A.Ş. / Toptancı Hal",
  "Hedef Ecza Deposu",
  "Koppert Arıcılık Ltd. Şti.",
  "İdil – İdil İşletmecilik A.Ş. (Antalya Otogarı)",
  "TEGV (Eğitim Gönüllüleri Vakfı)",
  "Efes Pilsen Bölge Müdürlüğü (Anda Dağıtım)",
  "Arısan Ltd. Şti.",
  "Mepaş Alışveriş Merkezleri",
  "Yavuzlar Gıda İthalat ve İhracat",
  "Çelik Kuruyemiş",
  "Prenses Kuruyemiş",
  "Simge Mobilya",
  "Ka Dizayn Otel Donanımları",
  "Polo Mobilya Ltd. Şti.",
  "Hobby Mağazası (Delta Giyim A.Ş.)",
  "STEFANEL (Laura İş Merkezi İçi)",
  "Bisse Gömlekleri (Bisse Ltd. Şti.)",
  "Sultanahmet Köftecisi",
  "Tan İtriyat - Selekler Şubesi",
  "Tan İtriyat - Güllük Şubesi",
  "Tan İtriyat - Organize Sanayi Şubesi",
  "Hal Laboratuarı Binası İnşaatı",
  "Aker Yemekçilik Ltd. Şti.",
  "Ünal Yemekçilik",
  "Antalya Yemekçilik Ltd. Şti.",
  "Özkanlar Gıda Ltd. Şti.",
  "Otokoç Otomotiv San. Tic. A.Ş. (Ford Servisi)",
  "ACL Bilaller Otomotiv Otomotiv",
  "Ataç Bilgisayar",
  "Ado Madencilik",
  "Adoport Ltd. Şti. / Serbest Bölge",
  "Kırmızıtaş Hazır Beton Ltd. Şti.",
  "Başaran Kireç A.Ş.",
  "Bşaran Beton A.Ş.",
  "Yaşar Birleşik Pazarlama (Pınar Et)",
  "Dilek Ecza Deposu",
  "Erol Döviz ve Altın A.Ş.",
  "Dünya Süper Dağıtım ve Gaztecilik a.Ş.",
  "TEB Sigorta A.Ş.",
  "TSM Deniz Ürünleri A.Ş.",
  "Toramanlar Ltd. Şti.",
  "Antalya Ekmek",
  "Soylu Ekmek",
  "Tim Gıda A.Ş. (Karsan)",
  "Seans Özel Eğitim Hizmetleri (Dans Tur. Ltd. Şti.)",
  "Çeliksan A.Ş. (Rock Center)",
  "İhlas Gazetecilik A.Ş./ Türkiye Gazeteleri Matbaaları",
  "Omersan Kimyevi Maddeler Ltd. Şti.",
  "GNC Doğal Beslenme Ürünleri (Akdeniz Besin Ltd.)",
  "Pizza & Pizza (Nan Gıda Ltd. Şti.)",
  "More & More (Yamanlar Hazır Giyim Ltd. Şti.)",
  "Efor Mağazası ( Erdem Giyim Ltd. Şti.)",
  "Tan İtriyat - Işıklar Şubesi",
  "Tan İtriyat - Eski Sanayi Şubesi",
  "Promix Yapı Malzemeleri Ltd. Şti. (Organize Sanayi)",
  "Club Mixx / Kaleiçi",
  "Adam Plastik Ltd. Şti.",
  "Gökcem Yemekçilik Ltd. Şti.",
  "Öğün Yemekçilik (Organize Sanayi)",
  "Tek Tat Yemekçilik Ltd. Şti.",
  "Katmer Gıda Ltd. Şti.",



]
export default Belediye;