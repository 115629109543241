import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
const data = [
    "AMBASSADOR OTEL",
    "BEST WESTERN KHAN OTEL",
    "DUJA BODRUM",
    "DUJA BE PREMİUM",
    "DUJA DİDİM",
    "DAİMA RESORT HOTEL ",
    "THE MARMARA OTEL",
    "SİMENA OTEL ",
    "ROYAL ATLANTİS",
]
function Otels(props) {
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    }));
    return (
        <AccordionDetails>
            <Typography>
                <ul>
                    {data.map((x, i) => {
                        return <li>{x}</li>
                    })}
                </ul>
            </Typography>
        </AccordionDetails>
    );
}

export default Otels;