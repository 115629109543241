import styled from "styled-components";

const S = {
    Wrapper: styled.section`
    display:flex;
    width:100%;
    flex-direction:column;
    /* justify-content: space-between; */
    background-color:white;
    z-index: 9;
    box-sizing:border-box;
    `,
    SlideShow: styled.section`
    height: 100vh;
    width:100%;
    box-sizing:border-box;
    z-index: 9;
    // margin-bottom: 40px;
    `
}

export default S