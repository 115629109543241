
import { Link } from 'react-router-dom';
import S from './style'
import Certification from '../../components/certification';
import { DoubleArrow, ArrowCircleRight  } from '@mui/icons-material'
import YANGINDOLABI from '../../assets/category/yanginDolabi.png'
import HORTUM from '../../assets/category/hortum.jpg'
import LANS from '../../assets/category/lans.jpg'
import YANGINKAPI from '../../assets/category/yanginkapi.jpg'
import SAFT from '../../assets/category/saft.jpg'
import SONDURME from '../../assets/category/sondurme.png'
import IHBAR from '../../assets/category/ihbar.jpg'
import GAZLI from '../../assets/category/gazli.png'
import DAVLUMBAZ from '../../assets/category/davlumbaz.png'
import YANGINVANA from '../../assets/category/yanginvana.png'
import YANGINSPRINK from '../../assets/category/yanginsprink.png'
import HIDRANT from '../../assets/category/hidrant.png'
import AYDIN from '../../assets/category/aydin.png'
import KASK from '../../assets/category/kask.png'
import POMPA from '../../assets/category/pompa.png'
import data from '../../services/data';
import dataen from '../../services/dataen';
import { LocaleContext } from '../../locale-context';
import TranslateStatic from '../../locales/TranslateStatic'
export function Category() {
  return (
    <S.Wrapper>
      <S.Top>
        <S.Header>
          <TranslateStatic string="categoryHeader" />
        </S.Header>
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> {'>'}  <TranslateStatic string="categoryHeader" />
        </S.Span>
      </S.Top>
      <S.Sertifika>
        <Certification />
      </S.Sertifika>
      <S.Section>
        <LocaleContext.Consumer>
          {(value) => {
            if (value === "en") {
              return dataen.map((x, i) => {
                return <div className="item-wrapper">
                  <div className="item-image">
                    <img
                      src={x.category.imgsrc}
                      // src={location.origin + x.category.imgsrc}
                      alt="" />
                  </div>
                  <article>
                    <div>
                      <h2>{x.category.name}</h2>
                      <p>{x.category.content}</p>
                    </div>
                    <div>
                      <Link to={x.category.link}><TranslateStatic string="categoryDetailButton" /> <ArrowCircleRight /></Link>
                    </div>
                  </article>
                </div>
              })

            } else if (value === "tr") {
              return data.map((x, i) => {
                return <div className="item-wrapper">
                  <div className="item-image">
                    <img
                      src={x.category.imgsrc}
                      // src={location.origin + x.category.imgsrc}
                      alt="" />
                  </div>
                  <article>
                    <div>
                      <h2>{x.category.name}</h2>
                      <p>{x.category.content}</p>
                    </div>
                    <div>
                      <Link to={x.category.link}><TranslateStatic string="categoryDetailButton" /> <ArrowCircleRight /></Link>
                    </div>
                  </article>
                </div>
              })
            }
          }}
        </LocaleContext.Consumer>
        {/* {data.map((x, i) => {
          return <div className="item-wrapper">
            <div className="item-image">
              <img
                src={x.category.imgsrc}
                // src={location.origin + x.category.imgsrc}
                alt="" />
            </div>
            <article>
              <div>
                <h2>{x.category.name}</h2>
                <p>{x.category.content}</p>
              </div>
              <div>
                <Link to={x.category.link}><TranslateStatic string="categoryDetailButton" /> <DoubleArrow /></Link>
              </div>
            </article>
          </div>
        })} */}
      </S.Section>
    </S.Wrapper>
  );
}
