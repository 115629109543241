
import { Link } from 'react-router-dom';
import S from './style'
// import Image1 from '../../assets/sertification/tse.svg'
import Certification from '../../components/certification';
import { ReactComponent as Tse } from '../../assets/sertification/tsechoose.svg'
import { ReactComponent as Ce } from '../../assets/sertification/ce.svg'
import Iso from '../../assets/sertification/isopng.png'
import TranslateStatic from '../../locales/TranslateStatic';
import Accordion from './accordion'
export function Reference() {
  return (
    <S.Wrapper>
      <S.Top>
        <S.Header>
          <TranslateStatic string="referancesHeader" />
        </S.Header>
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <TranslateStatic string="referancesHeader" />
        </S.Span>
      </S.Top>
      <S.Sertifika>
       <Certification/>
      </S.Sertifika>
      <S.Section>
       <Accordion/>
      </S.Section>
    </S.Wrapper>
  );
}
