import React, { useState, useEffect } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import { HomePage } from './pages/Home';
import { PageTwo } from './pages/Page2';
import { Product } from './pages/Product';
import { Services } from './pages/Services';

import { Iletisim } from './pages/ContactUs';

import { Reference } from './pages/References';
import { Category } from './pages/Category';
import { SubCategory } from './pages/SubCategory';
import { Cookie } from './pages/Cookie';
import { Cer } from './pages/Certification';
import { Catalog } from './pages/catalog';
import { Teklif } from './pages/teklif';

import Header from './components/header'
import Footer from './components/footer';
import landChecker from './utils/langChecker';
export default () => {


   return < Routes >
        <Route path="/" element={<Layout />}>
            <Route path="/" index element={<HomePage/>} />
            <Route path="/aboutus" element={<PageTwo/>} />
            <Route path="/services" element={<Services/>} />
            <Route path="/product/:catid/:subcatid/:link" element={<Product/>} />
            <Route path="/references" element={<Reference/>} />
            <Route path="/contactus" element={<Iletisim/>} />
            <Route path="/offer" element={<Teklif/>} />
            <Route path="/catalog" element={<Catalog/>} />
            <Route path="/category" element={<Category/>} />
            <Route path="/cookie" element={<Cookie/>} />/
            <Route path="/category/:name/:id/:index" element={<SubCategory/>} />
        </Route>
    </Routes >

};

function Layout() {
    const [preferredLocale, setPreferredLocale] = useState("tr");
    function changeLanguage(event) {
        setPreferredLocale(event.target.value)
    };
    useEffect(() => {
        setPreferredLocale(landChecker);
    }, []);
    return (<>
        <Header lang={preferredLocale} changeLanguage={changeLanguage} />
        <Outlet />
        <Footer />
    </>
    );
}