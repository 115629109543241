import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
function Hastane(props) {
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  return (
    <AccordionDetails>
      <Typography>
        <ul>
          {data.map((x, i) => {
            return <li>{x}</li>
          })}
        </ul>
      </Typography>
    </AccordionDetails>
  );
}
const data = [
  "SERAŞ İNŞAAT",
  "PINARLAR İNŞAAT",
  "ÖZ PINARLAR İNŞAAT",
]
export default Hastane;